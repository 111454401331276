<mat-sidenav-container class="example-container">
    <mat-sidenav #sidenav mode="side" position="end" style="width: 200px;" class="bg-light" opened>
        <mat-nav-list>
            <div *ngIf="permissions?.level>1"  class="m-3">
                <b>Opciones de proyecto</b>
                <mat-divider></mat-divider>
                <a 
                    mat-list-item
                    routerLinkActive="active"
                    (click)="openDialogAvance('proyecto')"
                ><mat-icon class="mr-2">trending_up</mat-icon>Avance de proyecto</a>
                <a 
                    mat-list-item
                    routerLinkActive="active"
                    (click)="openDialogCod()"
                ><mat-icon class="mr-2">code</mat-icon>Códigos de proyecto</a>
                <a 
                    mat-list-item
                    routerLinkActive="active"
                    (click)="openDialogDescarga()"
                ><mat-icon class="mr-2">download</mat-icon>Descargar proyecto</a>
                <a 
                    mat-list-item
                    routerLinkActive="active"
                    (click)="updateProyecto()"
                ><mat-icon class="mr-2">edit</mat-icon>Editar proyecto</a>
                <a 
                    mat-list-item
                    routerLinkActive="active"
                    (click)="deleteProyecto()"
                ><mat-icon class="mr-2">delete</mat-icon>Eliminar proyecto</a>

                <a 
                    mat-list-item
                    routerLinkActive="active"
                    (click)="disableProyecto()"
                ><mat-icon class="mr-2">folder_off</mat-icon>Deshabilitar proyecto</a>

                <a 
                    mat-list-item
                    routerLinkActive="active"
                    (click)="openDialogImport()"
                ><mat-icon class="mr-2">storage</mat-icon>Importar datos</a>
                <a 
                    mat-list-item
                    routerLinkActive="active"
                    (click)="openDialogAvanzadas()"
                ><mat-icon class="mr-2">build</mat-icon>Opciones avanzadas</a>
            </div>
            <div *ngIf="!iscod" class="m-3">
                <mat-divider></mat-divider>
                <p>
                    Selecciona una pantalla para ver las opciones disponibles.
                </p>
                <mat-divider></mat-divider>
            </div>
            <div *ngIf="iscod" class="m-3">
                <b>Opciones de pantalla</b>
                <mat-divider></mat-divider>
                <a 
                    *ngIf="ispantalla"
                    mat-list-item
                    routerLinkActive="active"
                    (click)="openDialogAvance('pantalla')"
                ><mat-icon class="mr-2">trending_up</mat-icon>Avance de codifición</a>
                <a 
                    *ngIf="ispantalla&&permissions?.level>1"
                    mat-list-item
                    routerLinkActive="active"
                    (click)="openDialogRegistro()"
                ><mat-icon class="mr-2">text_format</mat-icon>Ver registros</a>
                <a 
                    *ngIf="ispantalla"
                    mat-list-item
                    routerLinkActive="active"
                    (click)="openDialogIniciar()"
                ><mat-icon class="mr-2">play_circle_outline</mat-icon>Iniciar codifición</a>
                <a 
                    *ngIf="ispantalla&&permissions?.level>1"
                    mat-list-item
                    routerLinkActive="active"
                    (click)="openDialogRevision()"
                ><mat-icon class="mr-2">check_circle_outline</mat-icon>Iniciar revisión</a>
                <a 
                    *ngIf="permissions?.level>1"
                    mat-list-item
                    routerLinkActive="active"
                    (click)="updateCod()"
                ><mat-icon class="mr-2">edit</mat-icon>Editar codificación</a>
                <a 
                    *ngIf="permissions?.level>1"
                    mat-list-item
                    routerLinkActive="active"
                    (click)="deleteCod()"
                ><mat-icon class="mr-2">delete</mat-icon>Eliminar codificación</a>
                <a 
                    *ngIf="permissions?.level>1"
                    mat-list-item
                    routerLinkActive="active"
                    (click)="openDialogPantalla()"
                ><mat-icon class="mr-2">add_box</mat-icon>Crear pantalla</a>
                <a 
                    *ngIf="ispantalla&&permissions?.level>1"
                    mat-list-item
                    routerLinkActive="active"
                    (click)="openDialogVista()"
                ><mat-icon class="mr-2">preview</mat-icon>Vista previa de pantalla</a>
            </div>
        </mat-nav-list>
	</mat-sidenav>

	<mat-sidenav-content>
        <button 
        mat-icon-button
        matTooltip="Regresar"
        (click)="backProyecto()">
            <mat-icon>arrow_back</mat-icon>
        </button>
        
        <mat-card class="project-card ml-5">
            <mat-card-header>
                <div mat-card-avatar>
                    <mat-icon class="text-info">folder</mat-icon>
                </div>
                <mat-card-title>{{ proyecto }}</mat-card-title>
                <mat-card-subtitle><b>{{ codificacion }}</b></mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <p *ngIf="iscod"><b>Descripción:</b> {{ descripcion }}</p>
            </mat-card-content>
        </mat-card>
        
        <div class="ml-5 mt-2">
            <h1>Pantallas</h1>
            <div class="bg-white m-3">
                <mat-list>
                <div mat-subheader>
                    <button
                        *ngIf="permissions?.level>1" 
                        mat-icon-button 
                        class="text-success" 
                        matTooltip="Crea nueva codificación" 
                        matTooltipPosition="above"
                        (click)="createCod()">
                        <mat-icon>add_circle_outline</mat-icon>
                    </button>
                    Pantalla
                </div>
                <mat-divider></mat-divider>
                <div style="height: 380px; overflow-y: auto;">
                    <mat-selection-list #codif [multiple]="false">
                        <mat-list-option *ngFor="let cod of codificacionList" [value]="cod.id">
                            <mat-icon mat-list-icon class="text-info">code</mat-icon>
                            <div mat-line><a class="stretched-link" (click)="selectCod(cod.id, cod.name, cod.descripcion, cod.pantalla);">{{ cod.name }}</a></div>
                        </mat-list-option>
                    </mat-selection-list>
                </div>
                <mat-divider></mat-divider>
                </mat-list>
            </div>
        </div>
	</mat-sidenav-content>
</mat-sidenav-container>

