import { Injectable } from '@angular/core';
import { Routes } from "@angular/router";

import { AuthGuard } from "../guards/auth.guard";
import { PagesComponent } from "./pages.component";

@Injectable({
  providedIn: 'root'
})
export class Pages {
    static childRoutes(routes: Routes){
        return {
            path: '',
            component: PagesComponent,
            children: routes,
            canActivate: [AuthGuard],
            data: { reuse: true }
        };
    }
}