import { Component, OnInit, Inject } from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';

import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

import { RegistroService } from './registro.service';
import { DetalleExcelService } from '../detalle-proyecto/detalle-excel.service';

import { RegistroEditaComponent } from './registro-edita/registro-edita.component';
import { RegistroFiltroComponent } from './registro-filtro/registro-filtro.component';
import { RegistroLogsComponent } from './registro-logs/registro-logs.component';
import { RegistroNuevoComponent } from './registro-nuevo/registro-nuevo.component';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.scss']
})
export class RegistroComponent implements OnInit {

  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast: any) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  columnsToDisplay = null;
  columnsList = null;
  idColumns = null;
  controlColumns = null;
  variableColumns = null;
  menuColumns = null;

  registroList = [];

  selection = new SelectionModel(true, []);
  dataSource = null;

  labels = 'value';
  defaultTxt = true;

  idProcodList = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog,
    private registroService: RegistroService,
    private detalleExcelService: DetalleExcelService,
  ) { }

  ngOnInit() {
    this.readRegistro();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  /////////////////////////////// Busca proyectos
  readRegistro(){
    let body = {
      codificacion: this.data.id,
      procod_id: this.idProcodList
    };
    this.registroService.readRegistro(body)
      .subscribe( (data) => {
        this.columnsList = data['campos'];
        this.columnsToDisplay = ['select',...data['campos'].slice(1, data['campos'].length), 'star'];
        this.registroList = data['resultado'];
        this.dataSource = new MatTableDataSource(this.registroList);
        this.idColumns = this.columnsToDisplay[1];
        this.controlColumns = this.columnsToDisplay[2];
        this.variableColumns = [...this.columnsToDisplay.slice(2, this.columnsToDisplay.length - 1)];
        this.menuColumns = this.columnsToDisplay[this.columnsToDisplay.length - 1];
      },
      (error) => {
        console.log(error)
      }
    );
  }

  async uploadRegistro(){
    Swal.fire({
      title: 'Selecciona xlsx',
      input: 'file',
      inputAttributes: {
          'accept': 'file/*',
          'aria-label': 'Carga archivo xlsx'
      },
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Ok',
      showLoaderOnConfirm: true,
      preConfirm: (file) => {
          if (file) {
              const reader = new FileReader;
              reader.onload = (e) => {
                const data = reader.result;
                let workBook = XLSX.read(data, { type: 'binary' });
                let sheet1 = workBook.SheetNames[0];
                let sheet = workBook.Sheets[sheet1];
                let jsonData = XLSX.utils.sheet_to_json(sheet);
                let arrayData = [];
                jsonData.forEach(element => {
                  let row = [];
                  Object.keys(element).map(r =>{
                    row.push(element[r]);
                  });
                  arrayData.push(row);
                });
                Swal.mixin({
                  input: 'text',
                  inputPlaceholder: `${this.controlColumns}`,
                }).queue([
                  {
                    title: 'Carga de datos',
                    html: `
                      El archivo contiene ${arrayData.length} registros.<br><br>
                      Agrega un valor a tu variable <b>${this.controlColumns}</b> (Opcional)
                    `,
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonText: 'Cancelar',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Cargar'
                  }
                ]).then((result) => {
                  if (result) {
                    let body = {
                      data: jsonData,
                      codificacion: this.data.id,
                      control: this.controlColumns,
                      control_value: result['value'][0],
                    };
                    this.registroService.createRegistro(body)
                      .subscribe( (data) => {
                        Swal.fire({
                          icon: 'success',
                          text: `Registros cargados: ${data}`
                        })
                        this.readRegistro();
                      },
                      (error) => {
                        Swal.fire(
                          'Error',
                          'Ocurrio un error al cargar los registros, intenta más tarde.',
                          'error'
                        );
                      });
                  }
                })
              };
              reader.readAsBinaryString(file);
          }
      },
      allowOutsideClick: () => !Swal.isLoading()
    });
  }

  deleteRegistro(multiple: boolean, registro?: any){
    let deletes = null;
    let item = [];
    if (multiple) {
      for(let select of this.selection.selected){
        item.push(select.procod_id);
      } 
    } else {
      item.push(registro.procod_id);
    }
    deletes = item.toString();
    Swal.fire({
      title: `¿Estas seguro de eliminar los ${item.length} registros seleccionados?`,
      text: 'Se eliminaran permanentemente los registros',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, eliminar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.registroService.deleteRegistro(deletes, this.data.id)
          .subscribe( (data) => {
            this.Toast.fire({
              icon: 'success',
              title: `Registros eliminados: ${data}`
            });
            this.selection.clear();
            this.readRegistro();
          },
          (error) => {
            Swal.fire(
              'Error',
              'Ocurrio un error al eliminar los registros, intenta más tarde.',
              'error'
            );
          });
      }
    })
  }


  /////////////Lleva a vista de edicion de registros
  openDialogEditRegistro(multiple: boolean, row?: any) {
    let registros = multiple ? this.selection.selected : [row];
    const dialogRef = this.dialog.open(RegistroEditaComponent, {
      width: '100%',
      disableClose: true,
      data: { 
        codificacion: this.data.id, 
        multiple: multiple, 
        control: this.controlColumns,
        registros: registros,
        campos: this.columnsList
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.selection.clear();
      this.readRegistro();
    });
  }

  openDialogFilterRegistro(){
    const dialogRef = this.dialog.open(RegistroFiltroComponent, {
      width: '100%',
      disableClose: true,
      data: { 
        codificacion: this.data.id,
        control: this.controlColumns,
        campos: this.columnsList
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.defaultTxt = false;
        this.selection.clear();
        this.columnsList = result['campos'];
        this.columnsToDisplay = ['select',...result['campos'].slice(1, result['campos'].length), 'star'];
        this.registroList = result['resultado'];
        this.dataSource = new MatTableDataSource(this.registroList);
        this.idColumns = this.columnsToDisplay[1];
        this.controlColumns = this.columnsToDisplay[2];
        this.variableColumns = [...this.columnsToDisplay.slice(2, this.columnsToDisplay.length - 1)];
        this.menuColumns = this.columnsToDisplay[this.columnsToDisplay.length - 1]; 
        result['resultado'].forEach(element => {
          this.idProcodList.push(element.procod_id);
        });
      }
    });
  }

  //////////////Descarga codificacion
  downloadCodificacion(){
    let body = {
      codificacion: this.data.codificacion,
      columns: [this.idColumns, ...this.variableColumns],
      data: this.dataSource.data,
      labels: this.labels
    };
    this.detalleExcelService.downloadCodificacion(body);
  }

  ////////////////Revisa si el valor es un objeto
  isObject(value: any){
    if (typeof value === 'object' && value !== null) {
      return value[this.labels];
    } else {
      return value;
    }
  }

  ///////////////Mortrar etiquetas
  showLabels(value: any){
    if(value){
      this.labels = 'description';
    }else{
      this.labels = 'value';
    }
    this.readRegistro();
  }

  /////////////Lleva a vista de logs
  openDialogLogs(row: any) {
    const dialogRef = this.dialog.open(RegistroLogsComponent, {
      width: '100%',
      disableClose: true,
      data: {
        identificador: this.idColumns,
        codificacion: this.data.id,
        registro: row['procod_id']
      }
    });

  }

  /////////////Lleva a vista de añadir registro
  addRegistro() {
    const dialogRef = this.dialog.open(RegistroNuevoComponent, {
      width: '100%',
      disableClose: true,
      data: {
        codificacion: this.data.id, 
        control: this.controlColumns,
        campos: this.columnsList
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.readRegistro();
    });

  }

  /**
   * @description Carga de registros desde xlsx
   */
  async uploadRegistroJson(){
    Swal.fire({
      title: 'Selecciona xlsx',
      input: 'file',
      inputAttributes: {
          'accept': 'file/*',
          'aria-label': 'Carga archivo xlsx'
      },
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Ok',
      showLoaderOnConfirm: true,
      preConfirm: (file) => {
          if (file) {
              const reader = new FileReader;
              reader.onload = (e) => {
                const data = reader.result;
                let workBook = XLSX.read(data, { type: 'binary' });
                let sheet1 = workBook.SheetNames[0];
                let sheet = workBook.Sheets[sheet1];
                let jsonData = XLSX.utils.sheet_to_json(sheet);
                let arrayData = [];
                jsonData.forEach(element => {
                  let row = [];
                  Object.keys(element).map(r =>{
                    row.push(element[r]);
                  });
                  arrayData.push(row);
                });
                Swal.mixin({
                  input: 'text',
                  inputPlaceholder: `${this.controlColumns}`,
                }).queue([
                  {
                    title: 'Carga de datos',
                    html: `
                      El archivo contiene ${arrayData.length} registros.<br><br>
                      Agrega un valor a tu variable <b>${this.controlColumns}</b> (Opcional)
                    `,
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonText: 'Cancelar',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Cargar'
                  }
                ]).then((result) => {
                  if (result) {
                    let body = {
                      data: jsonData,
                      codificacion: this.data.id,
                      control: this.controlColumns,
                      control_value: result['value'][0],
                    };
                    this.registroService.createRegistro(body)
                      .subscribe( (data) => {
                        Swal.fire({
                          icon: 'success',
                          text: `Registros cargados: ${data}`
                        })
                        this.readRegistro();
                      },
                      (error) => {
                        Swal.fire(
                          'Error',
                          'Ocurrio un error al cargar los registros, intenta más tarde.',
                          'error'
                        );
                      });
                  }
                })
              };
              reader.readAsBinaryString(file);
          }
      },
      allowOutsideClick: () => !Swal.isLoading()
    });
  }

  validarCodigos(){
    Swal.fire({
      title: 'Revisando registros',
      allowOutsideClick: false,
      onOpen: () =>{
        Swal.showLoading();
      }
    });
    let body = {codificacion: this.data.id}
    this.registroService.validaCodigos(body)
      .subscribe( (data: any) => {
        console.log(data)
        if(data.length > 0){
          let texto = '';
          data.forEach(element => {
            texto += `<span>${element['campo']}: ${element['codigo']}</span><br>`
          });
          Swal.fire({
            icon: 'info',
            title: '¡Revisar!',
            text: 'Revisa los códigos en los campos indicados.',
            html: texto
          })
        }else{
          Swal.fire({
            icon: 'success',
            title: '¡Perfecto!',
            text: 'Todos los códigos son validos.'
          })
        }
      },
      (error) => {
        console.log(error)
      }
    );
  }

}
