<h1 *ngIf="dataSource" mat-dialog-title>Registros</h1>
<h1 *ngIf="!dataSource" mat-dialog-title>Cargando ...</h1>
<mat-dialog-content class="mat-typography">
    <mat-sidenav-container>
        <mat-sidenav opened mode="side" position="end" style="width: 200px;">
            <mat-nav-list>
                <div class="m-1">
                    <h4><b>Opciones</b></h4>
                    <mat-divider></mat-divider>
                    <a 
                        mat-list-item
                        routerLinkActive="active"
                        (click)="addRegistro()"
                    ><mat-icon class="mr-2">add</mat-icon>Añadir registro</a>
                    <a 
                        mat-list-item
                        routerLinkActive="active"
                        (click)="uploadRegistro()"
                    ><mat-icon class="mr-2">upload</mat-icon>Cargar registros</a>
                    <a 
                        mat-list-item
                        routerLinkActive="active"
                        (click)="downloadCodificacion()"
                    ><mat-icon class="mr-2">download</mat-icon>Descargar registros</a>
                    <a 
                        mat-list-item
                        routerLinkActive="active"
                        (click)="openDialogFilterRegistro()"
                    ><mat-icon class="mr-2">filter_alt</mat-icon>Filtrar registros</a>
                    <a 
                        mat-list-item
                        routerLinkActive="active"
                        (click)="validarCodigos()"
                    ><mat-icon class="mr-2">check_circle</mat-icon>Validar códigos</a>
                    <a 
                        *ngIf="selection.selected.length>1"
                        mat-list-item
                        routerLinkActive="active"
                        (click)="openDialogEditRegistro(true)"
                    ><mat-icon class="mr-2">edit</mat-icon>Editar registros</a>
                    <a 
                        *ngIf="selection.selected.length>1"
                        mat-list-item
                        routerLinkActive="active"
                        (click)="deleteRegistro(true)"
                    ><mat-icon class="mr-2">delete</mat-icon>Eliminar registros</a>
                    <mat-divider></mat-divider>
                </div>
            </mat-nav-list>
        </mat-sidenav>
        <span *ngIf="dataSource">Registros: <b>{{dataSource.data.length}}</b> <b *ngIf="defaultTxt" class="ml-3"> (Por default se muestran los últimos 100 registros)</b></span>
        <mat-checkbox
            *ngIf="dataSource"
            color="primary"
            class="float-right mr-3"
            (change)="showLabels($event.checked)">
            Mostrar etiquetas
        </mat-checkbox>
        <div *ngIf="dataSource" class="example-container mat-elevation-z8">
            <table mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="select">
                    <th class="columns-default" mat-header-cell *matHeaderCellDef>
                      <mat-checkbox (change)="$event ? masterToggle() : null"
                                    [checked]="selection.hasValue() && isAllSelected()"
                                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                                    [aria-label]="checkboxLabel()"
                                    color="primary">
                      </mat-checkbox>
                    </th>
                    <td class="columns-default" mat-cell *matCellDef="let row">
                      <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="$event ? selection.toggle(row) : null"
                                    [checked]="selection.isSelected(row)"
                                    [aria-label]="checkboxLabel(row)"
                                    color="primary">
                      </mat-checkbox>
                    </td>
                </ng-container>
                <ng-container [matColumnDef]="idColumns" sticky>
                    <th class="columns-default" mat-header-cell *matHeaderCellDef> {{idColumns}} </th>
                    <td class="columns-default" mat-cell *matCellDef="let element"> {{element[idColumns]}} </td>
                </ng-container>
            
                <ng-container [matColumnDef]="column" *ngFor="let column of variableColumns; let i=index">
                <th class="columns-default" mat-header-cell *matHeaderCellDef> {{column}} </th>
                <td 
                    mat-cell 
                    class="columns-default"
                    *matCellDef="let element"
                    matTooltip='{{ isObject(element[column]) }}'
                    matTooltipPosition="below">
                    <div [ngClass]="{'columns-variables': i > 6}">{{ isObject(element[column]) }}</div></td>
                </ng-container>
            
                <ng-container matColumnDef="star" stickyEnd>
                    <th class="columns" mat-header-cell *matHeaderCellDef></th>
                    <td class="columns" mat-cell *matCellDef="let element">
                        <button 
                            mat-menu-item
                            [matMenuTriggerFor]="menu"
                            matTooltip="Opciones de registro"
                            >
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button 
                                mat-menu-item
                                (click)="openDialogLogs(element)">
                              <mat-icon>event_note</mat-icon>
                              <span>Historia de registro</span>
                            </button>
                            <button 
                                mat-menu-item
                                (click)="openDialogEditRegistro(false, element)">
                              <mat-icon>edit</mat-icon>
                              <span>Editar registro</span>
                            </button>
                            <button 
                                mat-menu-item
                                (click)="deleteRegistro(false, element)">
                              <mat-icon>delete</mat-icon>
                              <span>Eliminar registro</span>
                            </button>
                        </mat-menu>
                    </td>
                </ng-container>
            
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
            </table>
        </div>
    </mat-sidenav-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cerrar</button>
</mat-dialog-actions>