import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

import Swal from 'sweetalert2';

import { ConexionNuevaComponent } from './conexion-nueva/conexion-nueva.component';

import { ConexionService } from './conexion.service';

@Component({
  selector: 'app-conexion',
  templateUrl: './conexion.component.html',
  styleUrls: ['./conexion.component.scss']
})
export class ConexionComponent implements OnInit {

  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast: any) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  dataSource = null;
  conexionList = [];
  displayedColumns = ['alias', 'menu'];

  constructor(
    public dialog: MatDialog,
    private conexionService:ConexionService
  ) { }

  ngOnInit(): void {
    this.readConexion();
  }

  /////////////Busca conexiones
  readConexion(){
    this.conexionService.readConexion()
      .subscribe( (data) => {
        this.conexionList = Object.keys(data).map( (i) => {
          return {
            id: data[i][0], 
            alias: data[i][1]
          }
        });
        this.dataSource = new MatTableDataSource(this.conexionList);
      },
      (error) => {
        console.log(error)
      }
    );
  }

  ////////////Edita conexion
  updateConexion(id: number){

  }

  ////////////Elimina conexion
  deleteConexion(row: any){
    Swal.fire({
      title: `¿Estas seguro de eliminar la conexión?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, Eliminar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        
        this.conexionService.deleteConexion(row.id)
          .subscribe( (data) => {
            if (data) {
              this.Toast.fire({
                icon: 'success',
                title: 'Conexión eliminada correctamente'
              });
              this.readConexion();  
            } else {
              this.Toast.fire({
                icon: 'error',
                title: 'Error al eliminar conexión'
              });  
            }
            
          },
          (error) => {
            this.Toast.fire({
              icon: 'error',
              title: 'Error al eliminar conexión'
            });
          });
      }
    })
  }

  /////////////Lleva a vista de importacion de datos
  openDialogConexion(){
    const dialogRef = this.dialog.open(ConexionNuevaComponent, {
      width: '100%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      this.readConexion();
    });
  }

}
