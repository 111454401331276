import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

import Swal from 'sweetalert2';

import { AnalyticsService } from './analytics.service';

import { CargarComponent } from './cargar/cargar.component';


@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {

  dataSource = null;
  camposList = [];
  datosList = [];
  
  opened: boolean = true;  

  procesos = [];
  carga = null;
  isType = {};

  constructor(
    public dialog: MatDialog,
    private analyticsService: AnalyticsService
  ) { }

  ngOnInit(): void {
  }

  ///////////////////////Carga de datos
  readDatos(body: any){
    Swal.fire({
      title: 'Cargando datos ...',
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      }
    });
    this.analyticsService.readDatos(body)
      .subscribe( (data) => {
        console.log(data);
        this.camposList = data['campos'];
        this.datosList = data['resultado'];
        this.camposList.forEach(element => {
          this.isType[element] = 0;
        });
        this.dataSource = new MatTableDataSource(this.datosList);
        Swal.close();
      },
      (error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error al cargar, intente más tarde.',
        });
      }
    );
  }

  openDialogCarga(){
    const dialogRef = this.dialog.open(CargarComponent, {
      width: '100%',
      height: '98%',
      disableClose: true,
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.carga = result;
      this.readDatos(this.carga);
      console.log(this.carga);
    });
  }

  async openDialogRecodificar(column: String){
    const { value: text } = await Swal.fire({
      input: 'textarea',
      title: `Recodificación de variable ${column}`,
      html: `<b>Nota</b>: Escriba los valores entre comillas, si la columna es de texto`,
      inputValue: 'valor_actual_1: valor_nuevo_1, valor_actual_2: valor_nuevo_2, valor_actual_3: valor_nuevo_3',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#d33'
    })
    
    if (text) {
      Swal.fire({
        title: 'Cargando datos ...',
        allowOutsideClick: false,
        onOpen: () => {
          Swal.showLoading();
        }
      });
      let valores = String(text);
      let salto = valores.search('\n');
      while (salto > 0) {
        valores = valores.replace('\n','')
        salto = valores.search('\n');
      }
      let valoresList = valores.split(',');

      this.procesos.push({ 'proceso': 'recode', 'config': [column, valoresList] });
      this.carga['procesos'] = this.procesos;
      this.dataSource = null;
      this.analyticsService.procesos(this.carga).subscribe( 
        (data) => {
          console.log(data);
          this.camposList = data['campos'];
          this.datosList = data['resultado'];
          this.dataSource = new MatTableDataSource(this.datosList);
          Swal.close();
        },
        (error) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Error al cargar, intente más tarde.',
          });
        });
      console.log(this.procesos);
    }
  }

  async openDialogTipoVar(column: String){
    const { value: tipo } = await Swal.fire({
      title: 'Tipo de variable',
      input: 'select',
      inputOptions: {
        'int': 'INT (Entero)',
        'float': 'Float (Flotante)',
        'str': 'String (cadena)'
      },
      inputPlaceholder: 'Selecciona tipo de variable',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#d33',
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (!value) {
            resolve('Campo requerido'); 
          }else{
            resolve('')
          }
        })
      }
    })
    
    if (tipo) {
      this.procesos.push({ 'proceso': 'convert_type', 'config': [column, tipo] });
      this.isType[String(column)] = 1;
      Swal.close();
    }
    
  }


}
