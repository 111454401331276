import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { MaterialModule } from '../../material.module';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ConexionRoutingModule } from './conexion-routing.module';

import { ConexionComponent } from './conexion.component';
import { ConexionNuevaComponent } from './conexion-nueva/conexion-nueva.component';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        ConexionRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        SelectDropDownModule,
        BrowserModule,
        NgApexchartsModule
    ],
    exports: [],
    declarations: [
        ConexionComponent,
        ConexionNuevaComponent
    ],
    providers: [],
})
export class ConexionModule { }