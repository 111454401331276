<h1 mat-dialog-title>Vista previa</h1>
<mat-dialog-content class="mat-typography bg-light" style="height: calc(100% - 80px);max-height: unset">
    <div class="table-responsive sticky-top">
        <table class="table table-bordered">
            <thead>
                <tr class="table-secondary">
                    <th scope="col" class="border">{{ identificador }}</th>
                    <th *ngFor="let text of textList" scope="col" class="border">{{ text }}</th>
                </tr>
            </thead>
            <tbody>
                <tr class="table-light">
                    <td scope="row" class="border">Valor</td>
                    <td *ngFor="let text of textList" class="border"> Valor </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="table-responsive">
        <table class="table">
            <tbody>
                <tr>
                    <td scope="row" style="width: 100px;">
                        <mat-form-field appearance="fill">
                            <mat-label>Estado</mat-label>
                            <mat-select [(ngModel)]="selectEstado">
                                <mat-option *ngFor="let estado of estadoList" [value]="estado.value">
                                {{ estado.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                    <td style="width: 100px;">
                        <button mat-raised-button color="basic">Buscar nuevo</button>
                    </td>
                    <td>
                        <button mat-raised-button color="primary" (click)="sendData()">Enviar</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <table class="table table-bordered" *ngFor="let table of displayedColumns; let i=index;">
        <thead>
            <tr class="table-secondary">
                <th scope="col"></th>
                <th *ngFor="let columns of displayedColumns[i]" scope="col">{{ columns }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let row of dataCod[i]; let j=index; ">
                <td *ngFor="let value of row; let k=index;" [ngClass]="{'table-secondary': k==0}" > 
                    <b *ngIf="k==0" >{{ value }}</b>
                    <textarea 
                    *ngIf="k>0 && !codigos[grupoCol[i][k]]"
                    matInput
                    class="border border-black"
                    [(ngModel)]="captura[i][j][k - 1]"
                    placeholder="Agrega texto"></textarea>

                    <ngx-select-dropdown 
                        *ngIf="k>0 && codigos[grupoCol[i][k]]"
                        style="float: left; width: 150px;"
                        [multiple]="false"
                        [config]="config"
                        [(ngModel)]="captura[i][j][k - 1]"
                        [options]="codigos[grupoCol[i][k]]"
                    >
                    </ngx-select-dropdown>
                    <button
                        *ngIf="k>0 && codigos[grupoCol[i][k]]"
                        mat-icon-button
                        color="warn"
                        matTooltip="Eliminar código"
                        matTooltipPosition="above"
                        style="float: left;"
                        (click)="deleteCode(i,j,k)">
                        <mat-icon>clear</mat-icon>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cerrar</button>
</mat-dialog-actions>