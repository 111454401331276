import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FormControl, FormGroup} from '@angular/forms';

import Swal from 'sweetalert2';

import { RegistroService } from '../registro.service';

@Component({
  selector: 'app-registro-nuevo',
  templateUrl: './registro-nuevo.component.html',
  styleUrls: ['./registro-nuevo.component.scss']
})
export class RegistroNuevoComponent implements OnInit {

  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast: any) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  singleForm: FormGroup;

  estadoList = [
    {value: 0, viewValue: 'Sin usar'},
    {value: 1, viewValue: 'Finalizado'},
    {value: 2, viewValue: 'Pendiente'},
    {value: 3, viewValue: 'No es posible completar'}
  ];
  selectedEstado = null;
  campoGrupo = null;
  codigos = null;
  camposList = null;

  config = {
    displayKey: 'description',
    search: true,
    height: '200px',
    placeholder:'Selecciona',
    noResultsFound: 'Sin resultados',
    searchPlaceholder:'Buscar...',
    clearOnSelection: false
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog,
    private registroService: RegistroService
  ) { }

  ngOnInit(): void {
    this.readRegistroCodigo();
    this.camposList = [this.data.campos[1], this.data.campos[2], ...this.data.campos.slice(9, this.data.campos.length)];
    let group = {};
    this.camposList.forEach(element => {
      group[element] = new FormControl(''); 
    });
    this.singleForm = new FormGroup(group);
  }

  /**Elimina Codigo */
  deleteCode(campo: any){
    this.singleForm.controls[campo].reset();
  }

  /* Busca codigos de codificacion */
  readRegistroCodigo(){
    this.registroService.readRegistroCodigo(this.data.codificacion).
      subscribe( (data) => {
        this.campoGrupo = data['campos'][0];
        let codigos = data['codigos'] ? data['codigos'] : [];
        this.codigos = codigos.reduce((o, key) => ({...o, [key.grupo]: key.codigos}), {});
      },
      (error) => {
        console.log(error);
      });
  }

  /**Añadir registro */
  async uploadRegistro(){
    let body = {
      data: [this.singleForm.value],
      codificacion: this.data.codificacion,
      control: this.data.control,
      control_value: this.singleForm.value[this.data.control]
    };
    this.registroService.createRegistro(body)
      .subscribe( (data) => {
        this.Toast.fire({
          icon: 'success',
          title: `Registro cargado correctamente`
        });
      },
      (error) => {
        Swal.fire(
          'Error',
          'Ocurrio un error al cargar el registro, intenta más tarde.',
          'error'
        );
      });
  }

}
