import { Component, OnInit } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { AuthService } from '../../service/auth.service';

import Swal from 'sweetalert2';

import {SelectionModel} from '@angular/cdk/collections';
import { UsuarioService } from './usuario.service';
import { UsuarioNuevoComponent } from './usuario-nuevo/usuario-nuevo.component';
import { AsignaComponent } from './asigna/asigna.component';
import { AsignaVerComponent } from './asigna-ver/asigna-ver.component';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.scss']
})
export class UsuarioComponent implements OnInit {
  
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast: any) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  permissions = null;

  displayedColumns: string[] = ['select' ,'Nombre', 'Email', 'Nivel', 'menu'];
  selection = new SelectionModel(true, []);
  dataSource = null;

  usuarioList = null;
  codificadoresList = null;


  constructor(
    private usuarioService: UsuarioService,
    public dialog: MatDialog,
    private auth: AuthService,
    private router: Router,
  ) { }

  async ngOnInit() {
    await this.getPermissions();
    await this.readUsuario();
  }

  ///////////////////Busca permisos
  async getPermissions(){
    this.auth.getPermissions()
      .subscribe( (data) => {
        this.permissions = data;
      },
      (error) => {
        this.router.navigateByUrl('/login');
      }
    );
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  /////////////////////////////// Busca proyectos
  async readUsuario(){
    this.codificadoresList = [];
    this.usuarioService.readUsuario()
      .subscribe( (data) => {
        this.usuarioList = Object.keys(data).map( (i) => {
          return {
            id: data[i][0], 
            nombre: data[i][1],
            email: data[i][2],
            password: data[i][3],
            nivel: data[i][4],
            nivel_name: data[i][5]
          }
        });
        this.usuarioList.forEach(element => {
          if (element.nivel == 1) {
            this.codificadoresList.push(element); 
          }
        });
        this.dataSource = new MatTableDataSource(this.usuarioList);
      },
      (error) => {
        console.log(error)
      }
    );
  }

  ///////////////////////////// Crear usuario
  openCreateUsuario(){
    const dialogRef = this.dialog.open(UsuarioNuevoComponent, {
      width: '400px',
      disableClose: true,
      data: { level: this.permissions.level, action: 'create' }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.readUsuario();
    });
  }

  ///////////////////////////// Actualizar usuario
  openUpdateUsuario(usuario: any){
    const dialogRef = this.dialog.open(UsuarioNuevoComponent, {
      width: '400px',
      disableClose: true,
      data: { level: this.permissions.level, usuario: usuario, action: 'update' }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.readUsuario();
    });
  }

  ///////////////////////// Eliminar usuario
  deleteUsuario(usuario: any){
    Swal.fire({
      title: `¿Estas seguro de eliminar el usuario ${usuario.nombre}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, Eliminar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        
        this.usuarioService.deleteUsuario(usuario.id)
          .subscribe( (data) => {
            this.Toast.fire({
              icon: 'success',
              title: 'Usuario eliminado correctamente'
            });
            this.readUsuario();
          },
          (error) => {
            this.Toast.fire({
              icon: 'error',
              title: 'Error al eliminar usuario'
            });
          });
      }
    })
  }

  ///////////////////////////// Asigna usuario
  openAsignaUsuario(){
    const dialogRef = this.dialog.open(AsignaComponent, {
      disableClose: true,
      data: { codificadores: this.codificadoresList }
    });
  }

  ///////////////////////////// Busca proyectos asignados
  openAsignacion(usuario: any){
    const dialogRef = this.dialog.open(AsignaVerComponent, {
      width: '900px',
      disableClose: true,
      data: { usuario: usuario }
    });
  }

}
