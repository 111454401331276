<h1 mat-dialog-title>Filtro de registro</h1>
<mat-dialog-content class="mat-typography">
    <mat-accordion>
        <mat-expansion-panel (opened)="panelOpenState = true"
            (closed)="panelOpenState = false">

            <mat-expansion-panel-header>
                <mat-panel-title>
                    Fechas de carga
                </mat-panel-title>
                <mat-panel-description>
                    Busca registros cargados en el rango de fechas seleccionado.
                </mat-panel-description>
            </mat-expansion-panel-header>

            <mat-form-field appearance="fill">
                <mat-label>Fecha inicio</mat-label>
                <input matInput [matDatepicker]="pickerinicio" [formControl]="fechaIniForm">
                <mat-datepicker-toggle matSuffix [for]="pickerinicio"></mat-datepicker-toggle>
                <mat-datepicker #pickerinicio></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Fecha fin</mat-label>
                <input matInput [matDatepicker]="pickerfin" [formControl]="fechaFinForm">
                <mat-datepicker-toggle matSuffix [for]="pickerfin"></mat-datepicker-toggle>
                <mat-datepicker #pickerfin></mat-datepicker>
            </mat-form-field>
        </mat-expansion-panel>

        <mat-expansion-panel (opened)="panelOpenState = true"
                             (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Estado de registro
                </mat-panel-title>
                <mat-panel-description>
                    Busca registros por estado.
                </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-form-field appearance="fill">
                <mat-label>Estado</mat-label>
                <mat-select [formControl]="estadoForm" multiple>
                  <mat-option *ngFor="let estado of estadoList" [value]="estado.value">{{estado.viewValue}}</mat-option>
                </mat-select>
            </mat-form-field>
        </mat-expansion-panel>  

        <mat-expansion-panel (opened)="panelOpenState = true"
                             (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Filtro personalizado
                </mat-panel-title>
                <mat-panel-description>
                    Busca registros con filtro personalizado.
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div class=" m-3">
                <div class="example-button-container">
                    <mat-form-field appearance="fill">
                        <mat-label>Campos disponibles</mat-label>
                        <mat-select [formControl]="camposForm" multiple>
                        <mat-option *ngFor="let campo of camposList" [value]="campo">{{campo}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <button 
                        mat-mini-fab 
                        color="primary" 
                        class="ml-2"
                        [disabled]="!camposForm.value"
                        (click)="camposSelect()"><mat-icon matTooltip="Cargar">add</mat-icon></button>
                </div>
        
                <div *ngIf="multipleForm">
                    <form [formGroup]="multipleForm">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Campo</th>
                                    <th>Valores a buscar</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="selectList">
                                <tr *ngFor="let campo of selectList; let i=index">
                                    <td>{{ campo }}</td>
                                    <td *ngIf="campo!='estado'">
                                        <input type="text" class="form-control" placeholder="Ej. valor1, valor2, ..." [formControlName]="campo">
                                    </td>
                                    <td *ngIf="campo=='estado'">
                                        <mat-form-field appearance="fill">
                                            <mat-label>Selecciona estado</mat-label>
                                            <mat-select [formControlName]="campo">
                                              <mat-option *ngFor="let estado of estadoList" [value]="estado.value">
                                                {{estado.viewValue}}
                                              </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cerrar</button>
    <button mat-button (click)="readRegistro()">Buscar</button>
</mat-dialog-actions>