import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { MaterialModule } from '@app/material.module';
import { PagesComponent } from './pages.component';

@NgModule({
    imports: [
        MaterialModule,
        RouterModule,
        CommonModule
    ],
    exports: [],
    declarations: [PagesComponent],
    providers: [],
})
export class PagesModule { }
