import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "@env/environment";

@Injectable({
  providedIn: 'root'
})
export class EventoService {

  env = environment;
  constructor(
    private http: HttpClient
  ) { }

  ///////////////////////Busca grupo
  readEvento(){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.get(`${ this.env.api }/evento`, { headers });
  }
}
