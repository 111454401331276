import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MaterialModule } from './material.module';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { NgApexchartsModule } from 'ng-apexcharts';

import { PagesModule } from './pages/pages.module';
import { ProyectoModule } from './pages/proyecto/proyecto.module';
import { UsuarioModule } from './pages/usuario/usuario.module';
import { ConexionModule } from './pages/conexion/conexion.module';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { PublicComponent } from './public/public.component';
import { AnalyticsModule } from './pages/analytics/analytics.module';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PublicComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    HttpClientModule,
    PagesModule,
    ProyectoModule,
    UsuarioModule,
    ConexionModule,
    AnalyticsModule,
    NgApexchartsModule,
    AppRoutingModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
