import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';

import { RegistroService } from '../registro.service';

@Component({
  selector: 'app-registro-logs',
  templateUrl: './registro-logs.component.html',
  styleUrls: ['./registro-logs.component.scss']
})
export class RegistroLogsComponent implements OnInit {

  logsList = [];
  dataSourceLogs = null;
  logsColumns: string[] = ['fecha' ,'usuario', 'identificador', 'mensaje'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog,
    private registroService: RegistroService
  ) { }

  ngOnInit(): void {
    this.readLogs();
  }

  /////////////////////////////// Busca eventos
  readLogs(){
    this.registroService.readLogs(this.data.codificacion, this.data.registro)
      .subscribe( (data) => {
        this.logsList = Object.keys(data).map( (row) => {
          return {
            fecha: data[row][0],
            usuario: data[row][1],
            identificador: data[row][2],
            mensaje: data[row][3]
          }
        });
        this.dataSourceLogs = new MatTableDataSource(this.logsList);
      },
      (error) => {
        console.log(error)
      }
    );
  }

}
