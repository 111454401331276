<h2 mat-dialog-title>Avance: {{ titulo }} </h2>
<mat-dialog-content class="mat-typography">
  <div class="example-button-row">
    <div class="example-flex-container">
      <div class="example-button-container">
        <button 
          mat-mini-fab 
          color="primary"
          matTooltip="Descarga reporte"
          matTooltipPosition="right"
          (click)="downloadAvance()">
          <mat-icon>download</mat-icon>
        </button>
      </div>
      <div class="example-button-container">
        <button 
          mat-mini-fab 
          color="warn"
          matTooltip="Filtro"
          matTooltipPosition="right"
          (click)="openDialogFiltro()">
          <mat-icon>filter_alt</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <apx-chart
    *ngIf="chartOptions && data.tipo=='proyecto'"
    [series]="chartOptions.series"
    [chart]="chartOptions.chart"
    [dataLabels]="chartOptions.dataLabels"
    [markers]="chartOptions.markers"
    [title]="chartOptions.title"
    [fill]="chartOptions.fill"
    [yaxis]="chartOptions.yaxis"
    [xaxis]="chartOptions.xaxis"
    [tooltip]="chartOptions.tooltip"
  ></apx-chart>

  <apx-chart
    *ngIf="chartOptionsSerie && data.tipo=='pantalla'"
    [series]="chartOptionsSerie.series"
    [chart]="chartOptionsSerie.chart"
    [xaxis]="chartOptionsSerie.xaxis"
    [stroke]="chartOptionsSerie.stroke"
    [colors]="chartOptionsSerie.colors"
    [dataLabels]="chartOptionsSerie.dataLabels"
    [legend]="chartOptionsSerie.legend"
    [markers]="chartOptionsSerie.markers"
    [grid]="chartOptionsSerie.grid"
    [yaxis]="chartOptionsSerie.yaxis"
    [title]="chartOptionsSerie.title"
  ></apx-chart>

  <div *ngIf="chartOptionsPie && data.tipo=='pantalla'" class="pie">
    <h2>Avance general</h2>
    <apx-chart
      [series]="chartOptionsPie.series"
      [chart]="chartOptionsPie.chart"
      [labels]="chartOptionsPie.labels"
      [responsive]="chartOptionsPie.responsive"
    ></apx-chart>
  </div>
  
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cerrar</button>
</mat-dialog-actions>
