<h1 mat-dialog-title>{{ title }}</h1>
<mat-dialog-content class="mx-auto">
    <mat-form-field appearance="fill" class="tam-input">
        <mat-label>Nombre de usuario</mat-label>
        <input matInput [formControl]="nameFormControl" required>
        <mat-error *ngIf="nameFormControl.hasError('required')">
            Nombre <strong>requerido</strong>
        </mat-error>
    </mat-form-field>
    <br>
    <mat-form-field appearance="fill" class="tam-input">
        <mat-label>Email</mat-label>
        <input type="email" matInput [formControl]="emailFormControl" [errorStateMatcher]="matcher"
           placeholder="Ej. pat@example.com" required>
        <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
            Por favor ingresa un email valido
        </mat-error>
        <mat-error *ngIf="emailFormControl.hasError('required')">
            Email es <strong>requerido</strong>
        </mat-error>
    </mat-form-field>
    <br>
    <mat-form-field appearance="fill" class="tam-input">
        <mat-label>Contraseña</mat-label>
        <input matInput [formControl]="passFormControl" [type]="hide ? 'password' : 'text'" required>
        <button mat-icon-button matSuffix (click)="hide = !hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        <mat-error *ngIf="passFormControl.hasError('required')">
            contraseña <strong>requerida</strong>
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" class="tam-input">
        <mat-label>Nivel</mat-label>
        <mat-select [formControl]="levelFormControl" required>
            <mat-option *ngFor="let level of levelList" [value]="level.value">
              {{level.viewValue}}
            </mat-option>
          </mat-select>
        <mat-error *ngIf="levelFormControl.hasError('required')">
            nivel <strong>requerido</strong>
        </mat-error>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cerrar</button>
    <button 
        *ngIf="this.data.action=='create'"
        mat-button
        [disabled]="emailFormControl.status!='VALID'||nameFormControl.status!='VALID'||passFormControl.status!='VALID'||levelFormControl.status!='VALID'" 
        (click)="createUsuario()">Guardar</button>
        <button 
        *ngIf="this.data.action=='update'"
        mat-button
        [disabled]="emailFormControl.status!='VALID'||nameFormControl.status!='VALID'||passFormControl.status!='VALID'||levelFormControl.status!='VALID'" 
        (click)="updateUsuario()">Guardar</button>
</mat-dialog-actions>
  