<h2 mat-dialog-title>Filtro de avance</h2>
<mat-dialog-content class="mat-typography">
    <div class="m-3">
        <form class="example-form">
            <p>Esta opción te permite filtar por el valor de la variable de <b>{{ data.control }}</b>.</p>
            <mat-form-field class="example-full-width">
                <mat-label>Campo {{ data.control }}</mat-label>
                <input matInput placeholder="Ej. {{ data.control }} 1" [(ngModel)]="control" name="control">
            </mat-form-field>
            <br>
            <p>Esta opción te permite filtar por rango de fecha.</p>
            <mat-form-field appearance="fill">
                <mat-label>Fecha inicio</mat-label>
                <input matInput [matDatepicker]="pickerinicio" [formControl]="fechaIniForm">
                <mat-datepicker-toggle matSuffix [for]="pickerinicio"></mat-datepicker-toggle>
                <mat-datepicker #pickerinicio></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Fecha fin</mat-label>
                <input matInput [matDatepicker]="pickerfin" [formControl]="fechaFinForm">
                <mat-datepicker-toggle matSuffix [for]="pickerfin"></mat-datepicker-toggle>
                <mat-datepicker #pickerfin></mat-datepicker>
            </mat-form-field>
            <br>
            <button 
                mat-stroked-button 
                color="primary" 
                (click)="filtro()">Filtrar avance</button>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cerrar</button>
</mat-dialog-actions>
