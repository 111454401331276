<h1 mat-dialog-title>Pantalla</h1>
<div mat-dialog-content class="container">
  
  <mat-accordion class="example-headers-align">

    <!-- Seccion de variables de texto -->
    <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Variables de texto
        </mat-panel-title>
        <mat-panel-description>
          Se crean las variables de texto que contendrá la tabla
          <mat-icon>text_rotation_none</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

        <button 
          mat-icon-button
          class="text-success"
          matTooltip="Agregar variable texto" 
          matTooltipPosition="right"
          (click)="addText()">
          <mat-icon>add_circle_outline</mat-icon>
        </button>
        <button
          *ngIf="textList.length>0" 
          mat-icon-button
          class="text-primary"
          matTooltip="Crear filtro de texto" 
          matTooltipPosition="right"
          (click)="filter()">
          <mat-icon>filter_alt</mat-icon>
        </button>
        <div 
          cdkDropList 
          cdkDropListOrientation="horizontal" 
          class="example-list"
          style="width: 1000px;"
          *ngIf="textList.length"
          (cdkDropListDropped)="dropText($event)">
          <div class="example-box" *ngFor="let txt of textList; let i=index;" cdkDrag>
            
            <div>{{txt}}</div>
            <div class="example-button-container">
              <button mat-icon-button [matMenuTriggerFor]="menuScreen" class="text-secondary" matTooltip="Más">
                <mat-icon>more_vert</mat-icon>
                <mat-menu #menuScreen="matMenu" xPosition="before">
                  <button 
                    mat-menu-item
                    (click)="editText(i)"
                    >
                    <mat-icon>edit</mat-icon>
                    <span>Editar</span>
                  </button>
                  <button 
                    mat-menu-item
                    (click)="deleteText(i)"
                    >
                    <mat-icon>delete</mat-icon>
                    <span>Eliminar</span>
                  </button>
                </mat-menu>
              </button>
            </div>
          </div>
        </div>
      <mat-action-row>
        <button mat-button color="warn" (click)="prevStep()">Regresar</button>
        <button mat-button color="primary" (click)="nextStep()">Siguiente</button>
      </mat-action-row>
    </mat-expansion-panel>

    <!-- Seccion de variables de captura -->
    <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Variables de captura
        </mat-panel-title>
        <mat-panel-description>
          Se crean las variables de captura que contendrá la tabla
          <mat-icon>code</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <button 
        mat-icon-button
        class="text-success"
        matTooltip="Agregar tabla" 
        matTooltipPosition="right"
        (click)="addTable()">
        <mat-icon>add_circle_outline</mat-icon>
      </button>

      <table *ngFor="let table of dataCod; let k=index" class="mat-elevation-z8">
        <tr>
          <td 
            class="border p-3">
            <button mat-icon-button [matMenuTriggerFor]="menuTable" class="text-secondary" matTooltip="Más">
              <mat-icon>more_vert</mat-icon>
              <mat-menu #menuTable="matMenu" xPosition="before">
                <button 
                  mat-menu-item
                  (click)="addColumn(k)">
                  <mat-icon>view_column</mat-icon>
                  <span>Agregar columna</span>
                </button>
                <button 
                  mat-menu-item
                  (click)="addRow(k)">
                  <mat-icon>table_rows</mat-icon>
                  <span>Agregar renglón</span>
                </button>
                <button 
                  mat-menu-item
                  (click)="valide('renglon',k)">
                  <mat-icon>check</mat-icon>
                  <span>Validar renglones</span>
                </button>
                <button 
                  mat-menu-item
                  (click)="deleteTable(k)">
                  <mat-icon>delete</mat-icon>
                  <span>Eliminar tabla</span>
                </button>
              </mat-menu>
            </button>
          </td>
          <td
            class="border p-3"
            *ngFor="let item of displayedColumns[k]; let c=index">
            <button mat-icon-button [matMenuTriggerFor]="menuCol" class="text-secondary" matTooltip="Más">
              <mat-icon>more_vert</mat-icon>
              <mat-menu #menuCol="matMenu" xPosition="before">
                <button 
                  mat-menu-item
                  (click)="editCol(k,c)"
                  >
                  <mat-icon>edit</mat-icon>
                  <span>Editar</span>
                </button>
                <button 
                  mat-menu-item
                  (click)="selectGrupo(k,c)">
                  <mat-icon>code</mat-icon>
                  <span>Códigos de columna</span>
                </button>
                <button 
                  mat-menu-item
                  (click)="valide('columna',k,c)">
                  <mat-icon>check</mat-icon>
                  <span>Validar columna</span>
                </button>
                <button 
                  mat-menu-item
                  (click)="deleteCol(k,c)"
                  >
                  <mat-icon>delete</mat-icon>
                  <span>Eliminar columna</span>
                </button>
              </mat-menu>
            </button>
            {{ item }}
          </td>
        </tr>
        <tr *ngFor="let row of dataCod[k]; let i=index;">
          <td 
            class="border p-3"
            *ngFor="let element of row let j=index;">

            <button mat-icon-button [matMenuTriggerFor]="menuScreen" class="text-secondary" matTooltip="Más">
              <mat-icon>more_vert</mat-icon>
              <mat-menu #menuScreen="matMenu" xPosition="before">
                <button 
                  mat-menu-item
                  (click)="editCode(k,i,j)"
                  >
                  <mat-icon>edit</mat-icon>
                  <span>Editar</span>
                </button>
                <button 
                  mat-menu-item
                  *ngIf="j==0"
                  (click)="deleteRow(k,i)"
                  >
                  <mat-icon>delete</mat-icon>
                  <span>Eliminar renglón</span>
                </button>
              </mat-menu>
            </button>
            {{ element }}

          </td>
        </tr>
      </table>

      <mat-action-row>
        <button mat-button color="warn" (click)="prevStep()">Regresar</button>
      </mat-action-row>

    </mat-expansion-panel>

  </mat-accordion>

</div>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="ispantalla" cdkFocusInitial>Cerrar</button>
  <button 
    mat-button 
    [disabled]="textList.length==0 || dataCod.length==0 || displayedColumns.length==0"
    (click)="createPantalla()">
    Guardar pantalla
  </button>
</mat-dialog-actions>