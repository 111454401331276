import { Component, OnInit } from '@angular/core';

import { AuthService } from '../service/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {
  user: String;
  permissions = {};

  pages = [
    { label: 'Proyectos', path: '/proyectos', auth: 'proyecto' },
    { label: 'Usuarios', path: '/usuarios', auth: 'usuario' },
    { label: 'Analytics', path: '/analytics', auth: 'analytics' },
    { label: 'Conexiones', path: '/conexiones', auth: 'conexion' }
  ];

  constructor(
    private auth: AuthService,
    private router: Router
  ) { }

  async ngOnInit() {
    this.user = localStorage.getItem('email');
    await this.getPermissions();
  }

  logout(){
    this.auth.logout();
    this.router.navigateByUrl('/login');
  }

  async getPermissions(){
    this.auth.getPermissions()
      .subscribe( (data) => {
        this.permissions = data;
      },
      (error) => {
        this.router.navigateByUrl('/login');
      }
    );
  }

}
