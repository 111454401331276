import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class AvanceExcelService {

  constructor() { }

  ///////////////////////Descarga de codificacion
  downloadAvance(body: any){
    const name = body.tipo == 'proyecto' ? body.proyecto : body.codificacion;
    const title = 'Avance: ' + name;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(name);
    // Add new row
    worksheet.addRow([title]);
    worksheet.addRow(['Creado por Procod']);
    worksheet.addRow([]);

    let headerRow = worksheet.addRow(body.columns);
    for (let row of body.avance){
      let temp=[];
      for(let value of row){
        temp.push(value);
      }
      worksheet.addRow(temp);
    }

    headerRow.eachCell((cell, number) => {
      worksheet.getColumn(number).width = 20;
      cell.alignment = { horizontal: 'center' };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4D79FF' },
        bgColor: { argb: '4D79FF' }
      }
      cell.style.font = {
        bold: true,
        color: { argb: 'FFFFFF' }
      }
    });
    //#4d79ff
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, `Avance - ${name}.xlsx`);
    });

  }

}
