import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from "@env/environment";

@Injectable({
  providedIn: 'root'
})
export class ConexionService {

  env = environment;
  constructor(
    private http: HttpClient
  ) { }

  ///////////////////////Busca conexiones
  readConexion(){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.get(`${ this.env.api }/conexion`, { headers });
  }

  ///////////////////////Prueba de conexion
  testConexion(body: any){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.post(`${ this.env.api }/conexion/test`, body, { headers });
  }

  ///////////////////////Crear de conexion
  createConexion(body: any){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.post(`${ this.env.api }/conexion/create`, body, { headers });
  }

  ///////////////////////Eliminar de conexion
  deleteConexion(conexion: number){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.delete(`${ this.env.api }/conexion/${conexion}`, { headers });
  }

}
