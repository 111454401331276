import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from "@env/environment";

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  env = environment;
  constructor(
    private http: HttpClient
  ) { }

  
  ///////////////////////Busca conexiones
  readConexion(){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.get(`${ this.env.api }/conexion`, { headers });
  }

  ///////////////////////Busca tablas de DB
  readTablas(conexion: Number){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.get(`${ this.env.api }/importar/tablas/${conexion}`, { headers });
  }

  ///////////////////////Busca campos de tabla de DB 
  readCamposTabla(conexion: number, tabla: String){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.get(`${ this.env.api }/importar/tablas/${conexion}/${tabla}`, { headers });
  }

  ///////////////////////Carga de datos DB
  readDatos(body: any){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.post(`${ this.env.api }/analytics/datos`, body, { headers });
  }

  ///////////////////////Procesamiento de datos
  procesos(body: any){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.post(`${ this.env.api }/analytics/procesos`, body, { headers });
  }

}
