import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { MaterialModule } from '../../material.module';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NgApexchartsModule } from 'ng-apexcharts';
import { AnalyticsRoutingModule } from './analytics-routing.module';

import { AnalyticsComponent } from './analytics.component';
import { CargarComponent } from './cargar/cargar.component';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        AnalyticsRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        SelectDropDownModule,
        BrowserModule,
        NgApexchartsModule
    ],
    exports: [],
    declarations: [
        AnalyticsComponent,
        CargarComponent
    ],
    providers: [],
})
export class AnalyticsModule { }