import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import Swal from 'sweetalert2';

import { AvanceExcelService } from './avance-excel.service';
import { AvanceService } from './avance.service';

import { AvanceFiltroComponent } from './avance-filtro/avance-filtro.component';

@Component({
  selector: 'app-avance',
  templateUrl: './avance.component.html',
  styleUrls: ['./avance.component.scss']
})
export class AvanceComponent implements OnInit {

  chartOptions = null;
  chartOptionsPie = null;
  campos = null;
  avance = null;
  avancePie = null;

  chartOptionsSerie = null;
  
  titulo = null;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AvanceComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private avanceService: AvanceService,
    private avanceExcelService: AvanceExcelService
  ) { }

  ngOnInit(): void {
    let body = null;
    if (this.data.tipo == 'proyecto') {
      this.titulo = this.data.proyectoName;
      body = {
        tipo: this.data.tipo,
        proyecto: this.data.proyecto,
        control: null,
        fechaini: null,
        fechafin: null
      };
      this.readAvanceProyecto(body);
    } else {
      this.titulo = this.data.codificacionName;
      body = {
        tipo: this.data.tipo,
        proyecto: this.data.proyecto,
        codificacion: this.data.codificacion,
        control: null,
        fechaini: null,
        fechafin: null
      };
      this.readAvanceCodificacion(body);
    }
  }

  ///////////Busca reporte de proyecto
  readAvanceProyecto(body: any){
    Swal.fire({
      title: 'Cargando avance...',
      allowOutsideClick: false,
      onOpen: () =>{
        Swal.showLoading();
      }
    });
    this.avanceService.readAvanceProyecto(body)
      .subscribe( (data) => {
        this.campos = data['avance']['campos'];
        this.avance = data['avance']['resultado'];
        this.chartOptions = {
          series: data['series'],
          chart: {
            type: "bar",
            height: 350,
            stacked: true,
            stackType: "100%"
          },
          responsive: [{
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0
              }
            }
          }],
          plotOptions: {
            bar: {
              horizontal: false,
            }
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return parseFloat(val).toFixed(1) + "%";
            }
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"]
          },
          xaxis: {
            categories: data['categorias']
          },
          yaxis: {
            yaxis: {
              axisBorder: {
                show: false
              },
              axisTicks: {
                show: false,
              },
              labels: {
                show: false,
                formatter: function (val) {
                  return parseFloat(val).toFixed(1) + "%";
                }
              }
            }
          },
          legend: {
            position: 'right',
            offsetY: 40
          },
          fill: {
            opacity: 1
          },
        };
        Swal.close();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  ///////////Busca reporte de codificacion
  readAvanceCodificacion(body: any){
    Swal.fire({
      title: 'Cargando avance...',
      allowOutsideClick: false,
      onOpen: () =>{
        Swal.showLoading();
      }
    });
    this.avanceService.readAvanceCodificacion(body)
      .subscribe( (data) => {
        this.campos = data['avance']['campos'];
        this.avance = data['avance']['resultado'];
        let maxNumber = [];
        this.avance.forEach(element => {
          maxNumber.push(element[2]);
          maxNumber.push(element[3]);
          maxNumber.push(element[4]);
          maxNumber.push(element[5]);
        });
        console.log(maxNumber)
        this.avancePie = data['avance_pie'];

        this.chartOptionsSerie = {
          series: data['series'],
          chart: {
            height: 350,
            type: "line",
            dropShadow: {
              enabled: true,
              color: "#000",
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2
            },
            toolbar: {
              show: false
            }
          },
          colors: ["#33CC33", "#FFA31A", "#FF5050"],
          dataLabels: {
            enabled: true
          },
          stroke: {
            curve: "straight"
          },
          title: {
            text: "Estado de registros por día",
            align: "left"
          },
          grid: {
            borderColor: "#e7e7e7",
            row: {
              colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
              opacity: 0.5
            }
          },
          markers: {
            size: 1
          },
          xaxis: {
            categories: data['categorias'],
            title: {
              text: "Día"
            }
          },
          yaxis: {
            title: {
              text: "Cantidad"
            },
            min: 0,
            max: Math.max(...maxNumber) + 10
          },
          legend: {
            position: "top",
            horizontalAlign: "right",
            floating: true,
            offsetY: -25,
            offsetX: -5
          }
        };

        this.chartOptionsPie = {
          series: this.avancePie,
          chart: {
            type: "donut"
          },
          labels: ["Sin usar", "Finalizado", "Pendiente", "No es posible completar"],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 70
                },
                legend: {
                  position: "bottom"
                }
              }
            }
          ]
        };
        Swal.close();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  downloadAvance(){
    let body = {
      tipo: this.data.tipo,
      proyecto: this.data.proyectoName,
      codificacion: this.data.codificacionName,
      columns: this.campos,
      avance: this.avance
    };
    this.avanceExcelService.downloadAvance(body);
  }

  openDialogFiltro(){
    const dialogRef = this.dialog.open(AvanceFiltroComponent, {
      width: '50%',
      disableClose: true,
      data: {control: this.data.control}
    });
    dialogRef.afterClosed().subscribe(result => {
      let body = null;
      if(result){
        if (this.data.tipo == 'proyecto') {
          body = {
            tipo: this.data.tipo,
            proyecto: this.data.proyecto,
            control: result.control,
            fechaini: result.fechaini,
            fechafin: result.fechafin
          };
          this.readAvanceProyecto(body);
        } else {
          body = {
            tipo: this.data.tipo,
            proyecto: this.data.proyecto,
            codificacion: this.data.codificacion,
            control: result.control,
            fechaini: result.fechaini,
            fechafin: result.fechafin
          };
          this.readAvanceCodificacion(body);
        }
      }
    });
  }

}
