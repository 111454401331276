<h2 mat-dialog-title>Eventos de proyectos</h2>
<mat-dialog-content class="mat-typography">
    <div class="container-table mat-elevation-z8">
        <table mat-table [dataSource]="dataSourceEvento">
            <!-- Columns -->
            <ng-container [matColumnDef]="column" *ngFor="let column of eventoColumns">
            <th mat-header-cell *matHeaderCellDef> {{ column }} </th>
            <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
            </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="eventoColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: eventoColumns;"></tr>
        </table>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cerrar</button>
</mat-dialog-actions>
