import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FormControl, FormGroup} from '@angular/forms';

import Swal from 'sweetalert2';
import { RevisionService } from './revision.service';
import { RevisionFiltroComponent } from './revision-filtro/revision-filtro.component';

@Component({
  selector: 'app-revision',
  templateUrl: './revision.component.html',
  styleUrls: ['./revision.component.scss']
})
export class RevisionComponent implements OnInit {

  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast: any) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  identificador: string;
  textList = [];
  dataCod = [];
  displayedColumns = [];

  grupoCol = [];
  codigosDefault = null;
  codigos = null;

  valRowList = [];
  valColList = [];

  captura = [];

  estadoList = [
    { value: 1, name: 'Completado' },
    { value: 2, name: 'Pendiente' },
    { value: 3, name: 'No es posible completar' }
  ];

  selectEstado = null;

  config = {
    displayKey: 'description',
    search: true,
    height: '200px',
    placeholder:'Selecciona',
    noResultsFound: 'Sin resultados',
    searchPlaceholder:'Buscar...',
    clearOnSelection: false
  }

  registro = null;
  observacion = null;

  observacion_usuario = null;

  controlValue = null;
  filtro = null;
  filtroValue = null;
  filtroEstado = null;
  isvista = false;

  multipleForm: FormGroup = null;

  constructor(
    public dialogRef: MatDialogRef<RevisionComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog,
    private revisionService: RevisionService
  ) { }

  ngOnInit(): void {
    this.readVista();
  }

  /////////////////////////////// Busca Vista
  readVista(){
    Swal.fire({
      title: 'Cargando pantalla...',
      allowOutsideClick: false,
      onOpen: () =>{
        Swal.showLoading();
      }
    });
    let group = {};
    this.revisionService.readVista(this.data.id)
      .subscribe(async (data) => {
        this.filtro = data['filtros'] ? data['filtros'] : null;
        this.identificador = data['id'] ? data['id'] : null;
        this.textList = data['txt'] ? data['txt'] : [];
        this.displayedColumns = data['colnota'] ? data['colnota'] : [];
        this.dataCod = data['codes'] ? data['codes'] : [];
        this.grupoCol = data['grupos'] ? data['grupos'] : [];
        let codigos = data['codigos'] ? data['codigos'] : [];
        this.codigosDefault = codigos.reduce((o, key) => ({...o, [key.grupo]: key.codigos}), {});
        this.valColList = data['valcol'] ? data['valcol'] : [];
        this.valRowList = data['valrow'] ? data['valrow'] : [];
        this.dataCod.forEach((table, i) => {
          this.captura.push([]);
          table.forEach((row, j) => {
            this.captura[i].push([]);
            row.forEach((element,k) => {
              k > 0 ? this.captura[i][j].push(null) : null;
              k > 0 ? group[element] = new FormControl() : null;
            });
          });
        });
        this.multipleForm = new FormGroup(group);
        await this.readRegistro();
        Swal.close();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  /**Busca validaciones */
  getValide(){
    let data = {
      captura: this.captura,
      codificacion: this.data.id
    }
    this.revisionService.getValide(data)
      .subscribe( (data: any) => {
        if(data.length > 0){
          let messages = data.join('');
          Swal.fire({
            title: 'Revisa las siguientes observaciones',
            icon: 'info',
            width: 700,
            html: `
              <ul>
                ${messages}
              <ul>
            `
          });
        }else{
          let body = {
            observacion: this.observacion,
            data: this.multipleForm.value,
            codificacion: this.data.id,
            estado: this.selectEstado,
            identificador: this.registro['procod_id'],
            revision: true
          };
          this.updateRegistro(body);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }


  /**Guarda  informacion*/
  async sendData(){
    if (!this.selectEstado) {
      Swal.fire(
        '¡Debes seleccionar un estado!',
        '',
        'info'
      ); 
    }
    
    if (this.selectEstado > 1) {
      const { value: text } = await Swal.fire({
        title: 'Observaciones',
        input: 'textarea',
        inputPlaceholder: 'Escribe tus observaciones aquí...',
        confirmButtonText: 'Enviar',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        cancelButtonColor: '#d33',
      });
      
      if (text) {
        this.observacion = text;
        let body = {
          observacion: this.observacion,
          data: this.multipleForm.value,
          codificacion: this.data.id,
          estado: this.selectEstado,
          identificador: this.registro['procod_id'],
          revision: true
        }
        
        this.updateRegistro(body);
      }
    }else if(this.selectEstado == 1){
      this.getValide();
    }
  }

  /////////////////////////////// Busca registro a codificar
  async readRegistro(){
    this.registro = null;
    let body = {
      codificacion: this.data.id,
      control: this.data['control'],
      control_value: this.controlValue,
      filtro: this.filtro,
      filtro_value: this.filtroValue,
      filtro_estado: this.filtroEstado
    };
    this.captura = [];
    this.dataCod.forEach((table, i) => {
      this.captura.push([]);
      table.forEach((row, j) => {
        this.captura[i].push([]);
        row.forEach((element,k) => {
          k > 0 ? this.captura[i][j].push(null) : null;
        });
      });
    });
    return new Promise((resolve) => {
      this.revisionService.readRegistro(body)
        .subscribe( (data) => {
          this.isvista = false;
          this.registro = data['registro'];
          if (this.registro) {
            this.observacion_usuario = this.registro['procod_observacion'];
            this.selectEstado = this.registro['procod_estado'];
            Object.keys(this.registro).forEach(element => {
              this.multipleForm.controls[element] && this.registro[element] != null ? 
              this.multipleForm.controls[element].setValue(this.registro[element]) : 
              null;
            });
            if (data['codigos'].length > 0) {
              this.codigos = data['codigos'].reduce((o, key) => ({...o, [key.grupo]: key.codigos}), {});;
            }else{
              this.codigos = this.codigosDefault;
            }
    
            for (let table = 0; table < this.displayedColumns.length; table++) {
              for (let row = 0; row < this.dataCod[table].length; row++) {
                for (let column = 1; column < this.dataCod[table][row].length; column++) {
                  this.captura[table][row][column - 1] = 
                  this.multipleForm.value[this.dataCod[table][row][column]] != "" ?
                  this.multipleForm.value[this.dataCod[table][row][column]] :
                  null;
                }
              }
            }
            this.isvista = true;
            resolve(data);
          } else {
            Swal.fire({
              icon: 'info',
              text: 'No hay registro disponibles',
            });
          }    
        },
        (error) => {
          console.log(error);
        }
      );
    }); 
  }

  ///////////////////////// Guardar registro
  updateRegistro(body: any){
    Swal.fire({
      title: 'Guardando...',
      allowOutsideClick: false,
      onOpen: () =>{
        Swal.showLoading();
      }
    });
    this.revisionService.updateRegistro(body)
      .subscribe(async (data) => {
        this.Toast.fire({
          icon: 'success',
          title: 'Registro guardado correctamente'
        });
        this.multipleForm.reset();
        this.selectEstado = null;
        this.observacion = null;
        this.captura = [];
        this.dataCod.forEach((table, i) => {
          this.captura.push([]);
          table.forEach((row, j) => {
            this.captura[i].push([]);
            row.forEach((element,k) => {
              k > 0 ? this.captura[i][j].push(null) : null;
            });
          });
        });
        await this.readRegistro();
      },
      (error) => {
        this.Toast.fire({
          icon: 'error',
          title: 'Error al guardar el registro'
        });
      }
    );
  }

  /////////////// Seleccionar filtro de registros
  openDialogFilterRegistro(){
    const dialogRef = this.dialog.open(RevisionFiltroComponent, {
      width: '400px',
      disableClose: true,
      data: { 
        control: this.data['control'], 
        controlValue: this.controlValue, 
        filtro: this.filtro, 
        filtroValue: this.filtroValue,
        filtroEstado: this.filtroEstado
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        Swal.fire({
          title: 'Cargando...',
          allowOutsideClick: false,
          onOpen: () =>{
            Swal.showLoading();
          }
        });
        this.filtroValue = result.filtroValue;
        this.controlValue = result.controlValue;
        this.filtroEstado = result.filtroEstado;
        this.multipleForm.reset();
        this.selectEstado = null;
        this.observacion = null;
        this.captura = [];
        this.dataCod.forEach((table, i) => {
          this.captura.push([]);
          table.forEach((row, j) => {
            this.captura[i].push([]);
            row.forEach((element,k) => {
              k > 0 ? this.captura[i][j].push(null) : null;
            });
          });
        });
        await this.readRegistro();
        Swal.close();
      }
    });
  }


  /////////////// Actualiza arreglo de captura
  updateCaptura(table: number, row: number, column: number, type: string, $event: any){
    if (type == 'input' && $event != undefined) {
      this.captura[table][row][column] = $event.target.value;
    } else if (type == 'select' && $event != undefined) {
      this.captura[table][row][column] = $event;
    }else {
      this.captura[table][row][column] = null;
    }
  }

}
