<mat-sidenav-container class="example-container">
    <mat-sidenav #sidenav mode="side" position="end" style="width: 200px;" class="bg-light" opened>
        <mat-nav-list>
            <div class="m-3">
                <a 
                    mat-list-item
                    routerLinkActive="active"
                    (click)="openAsignaUsuario()"
                ><mat-icon class="mr-2">alt_route</mat-icon>Asignar proyecto</a>
                <a 
                    mat-list-item
                    routerLinkActive="active"
                    (click)="openCreateUsuario()"
                ><mat-icon class="mr-2">person_add</mat-icon>Crear Usuario</a>
            </div>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="m-5">
            <h1><span class="material-icons">groups</span>Usuarios</h1>
            <div *ngIf="dataSource" style="height: 500px; overflow-y: auto;">
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                    <!-- Checkbox Column -->
                    <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox (change)="$event ? masterToggle() : null"
                                    [checked]="selection.hasValue() && isAllSelected()"
                                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                                    [aria-label]="checkboxLabel()"
                                    color="primary">
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="$event ? selection.toggle(row) : null"
                                    [checked]="selection.isSelected(row)"
                                    [aria-label]="checkboxLabel(row)"
                                    color="primary">
                        </mat-checkbox>
                    </td>
                    </ng-container>
                
                    <!-- Name Column -->
                    <ng-container matColumnDef="Nombre">
                    <th mat-header-cell *matHeaderCellDef> Nombre </th>
                    <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
                    </ng-container>
                
                    <!-- Email Column -->
                    <ng-container matColumnDef="Email">
                    <th mat-header-cell *matHeaderCellDef> Email </th>
                    <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                    </ng-container>

                    <!-- Level Column -->
                    <ng-container matColumnDef="Nivel">
                        <th mat-header-cell *matHeaderCellDef> Nivel </th>
                        <td mat-cell *matCellDef="let element"> {{element.nivel_name}} </td>
                    </ng-container>

                    <!-- Activity Column -->
                    <ng-container matColumnDef="menu">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element"> 
                            <button mat-icon-button [matMenuTriggerFor]="menu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button
                                    *ngIf="element.nivel==1" 
                                    mat-menu-item
                                    (click)="openAsignacion(element)">
                                    <mat-icon>visibility</mat-icon>
                                    <span>Ver proyectos</span>
                                </button>
                                <button 
                                    mat-menu-item
                                    (click)="openUpdateUsuario(element)">
                                    <mat-icon>edit</mat-icon>
                                    <span>Editar usuario</span>
                                </button>
                                <button 
                                    mat-menu-item
                                    (click)="deleteUsuario(element)">
                                    <mat-icon>person_remove</mat-icon>
                                    <span>Eliminar Usuario</span>
                                </button>
                            </mat-menu>
                        </td>
                    </ng-container>
                
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                    </tr>
                </table>
            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>