import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FormControl, FormGroup} from '@angular/forms';

import Swal from 'sweetalert2';

import { RegistroService } from '../registro.service';

@Component({
  selector: 'app-registro-edita',
  templateUrl: './registro-edita.component.html',
  styleUrls: ['./registro-edita.component.scss']
})
export class RegistroEditaComponent implements OnInit {

  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast: any) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  camposList = null;
  camposForm = new FormControl();
  singleForm: FormGroup;
  multipleForm: FormGroup;
  selectList = null;
  registrosList = [];

  estadoList = [
    {value: 0, viewValue: 'Sin usar'},
    {value: 1, viewValue: 'Finalizado'},
    {value: 2, viewValue: 'Pendiente'},
    {value: 3, viewValue: 'No es posible completar'}
  ];
  selectedEstado = null;

  campoGrupo = null;
  codigos = null;

  config = {
    displayKey: 'description',
    search: true,
    height: '200px',
    placeholder:'Selecciona',
    noResultsFound: 'Sin resultados',
    searchPlaceholder:'Buscar...',
    clearOnSelection: false
  }

  isdisabled = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog,
    private registroService: RegistroService
  ) { }

  ngOnInit(): void {
    this.readRegistroCodigo();
    this.camposList = [this.data.campos[2], this.data.campos[4], ...this.data.campos.slice(8, this.data.campos.length)];
    this.data.registros.forEach(element => {
      this.registrosList.push(element['procod_id']);
    });
    if (!this.data.multiple) {
      switch (this.data.registros[0]['estado']) {
        case 'Sin usar':
          this.selectedEstado = 0;
          break;
        case 'Finalizado':
          this.selectedEstado = 1;
          break;
        case 'Pendiente':
          this.selectedEstado = 2;
          break;
        case 'No es posible completar':
          this.selectedEstado = 3;
          break;
      }

      let group = {};
      this.camposList.forEach(element => {
        if (element=='estado') {
          group[element] = new FormControl(this.selectedEstado); 
        } else {
          group[element] = new FormControl(this.data.registros[0][element] ? this.data.registros[0][element] : ''); 
        }
      });
      this.singleForm = new FormGroup(group);
    }
  }

  camposSelect(){
    let group = {};
    this.selectList = this.camposForm.value;
    this.selectList.forEach(element => {
      group[element] = new FormControl('');
    });
    this.camposForm.disable();
    this.isdisabled = false;
    this.multipleForm = new FormGroup(group);    
  }

  camposDelete(){
    this.camposForm.reset();
    this.camposForm.enable();
    this.selectList = null;
    this.multipleForm = null;
    this.isdisabled = true;
  }

  updateRegistro(){
    let body = null;
    if (this.data.multiple) {
      body = {
        edit: this.multipleForm.value,
        registro: this.registrosList,
        codificacion: this.data.codificacion
      }
    } else {
      body = {
        edit: this.singleForm.value,
        registro: this.registrosList,
        codificacion: this.data.codificacion
      }
    }
    Swal.fire({
      title: '¿Estas seguro de editar los registros seleccionados?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, editar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Editando registros...',
          allowOutsideClick: false,
          onOpen: () =>{
            Swal.showLoading();
          }
        });
        this.registroService.updateRegistro(body)
          .subscribe( (data) => {
            this.Toast.fire({
              icon: 'success',
              title: 'Registros editados correctamente'
            });
          },
          (error) => {
            Swal.fire(
              'Error',
              'Ocurrio un error al editar los registros, intenta más tarde.',
              'error'
            );
          });
      }
    })
    
  }
  
  /* Busca codigos de codificacion */
  readRegistroCodigo(){
    this.registroService.readRegistroCodigo(this.data.codificacion).
      subscribe( (data) => {
        this.campoGrupo = data['campos'][0];
        let codigos = data['codigos'] ? data['codigos'] : [];
        this.codigos = codigos.reduce((o, key) => ({...o, [key.grupo]: key.codigos}), {});
      },
      (error) => {
        console.log(error);
      });
  }

  /**Elimina Codigo */
  deleteCode(campo: any){
    this.singleForm.controls[campo].reset();
  }

}
