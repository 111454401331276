import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { MatTable } from '@angular/material/table';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-filtro',
  templateUrl: './filtro.component.html',
  styleUrls: ['./filtro.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class FiltroComponent implements OnInit {

  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast: any) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;

  expandedFiltro = null;
  dataFilter = []

  isEdit = false;

  columnsToDisplay = ['Campo', 'Grupo', 'Texto'];
  @ViewChild(MatTable,{static:true}) table: MatTable<any>;


  constructor(
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit(): void {
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
    this.thirdFormGroup = this._formBuilder.group({
      thirdCtrl: ['', Validators.required],
      fourthCtrl: ['', Validators.required]
    });
    this.dataFilter = this.data.dataFilter;
  }

  /**Agrega filtro */
  addFilter(){
    this.dataFilter.push({
      Campo: this.firstFormGroup.controls.firstCtrl.value, 
      Grupo: this.secondFormGroup.controls.secondCtrl.value[0],
      idGrupo: this.secondFormGroup.controls.secondCtrl.value[1],
      Texto: this.thirdFormGroup.controls.thirdCtrl.value, 
      Codigos: this.thirdFormGroup.controls.fourthCtrl.value
    });
    this.Toast.fire({
      icon: 'success',
      title: 'Filtro agregado exitosamente!'
    });
    this.table.renderRows();
        
  }

  /**Elimina filtro */
  deleteFilter(element: any){
    this.dataFilter = this.dataFilter.filter((value,key)=>{
      return value.Texto != element.Texto;
    });
  }

  /**Editar filtro */
  openEditFilter(element: any){
    this.isEdit = true;
  }

  editFilter(element: any){
    this.isEdit = false;
    this.dataFilter = this.dataFilter.filter((value,key)=>{
      if(value.Texto == element.Texto){
        value.name = element.Codigos;
      }
      return true;
    });
  }

}
