import { Component, OnInit } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';

import { EventoService } from './evento.service';

@Component({
  selector: 'app-evento',
  templateUrl: './evento.component.html',
  styleUrls: ['./evento.component.scss']
})
export class EventoComponent implements OnInit {

  eventoList = [];
  dataSourceEvento = null;
  eventoColumns: string[] = ['fecha' ,'usuario', 'evento', 'proyecto', 'codificacion'];

  constructor(
    private eventoService: EventoService
  ) { }

  ngOnInit(): void {
    this.readEvento();
  }

  /////////////////////////////// Busca eventos
  readEvento(){
    this.eventoService.readEvento()
      .subscribe( (data) => {
        this.eventoList = Object.keys(data).map( (row) => {
          return {
            fecha: data[row][0],
            usuario: data[row][1],
            evento: data[row][2],
            proyecto: data[row][3],
            codificacion: data[row][4]
          }
        });
        this.dataSourceEvento = new MatTableDataSource(this.eventoList);
      },
      (error) => {
        console.log(error)
      }
    );
  }

}
