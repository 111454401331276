import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';

import Swal from 'sweetalert2';

import { UsuarioService } from '../usuario.service';

@Component({
  selector: 'app-asigna-ver',
  templateUrl: './asigna-ver.component.html',
  styleUrls: ['./asigna-ver.component.scss']
})
export class AsignaVerComponent implements OnInit {

  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast: any) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  dataSource = null;
  asignacionList = [];
  selection = new SelectionModel(true, []);
  displayedColumns = ['select', 'proyecto', 'pantalla']

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog,
    private usuarioService: UsuarioService
  ) { }

  ngOnInit(): void {
    this.readAsignacion();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  readAsignacion(){
    this.usuarioService.readAsignacion(this.data.usuario.id)
      .subscribe( (data) => {
        this.asignacionList = Object.keys(data).map( (i) => {
          return {
            id: data[i][0], 
            usuario: data[i][1],
            proyecto: data[i][2],
            pantalla: data[i][3]
          }
        });
        this.dataSource = new MatTableDataSource(this.asignacionList);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  deleteAsignacion(){
    let deleteList = [];
    let deletes: string = null;
    this.selection['_selected'].forEach(element => {
      deleteList.push(element.id);
    });
    deletes = deleteList.join(',');
    this.usuarioService.deleteAsignacion(deletes)
      .subscribe( (data) => {
        this.dataSource = null;
        this.Toast.fire({
          icon: 'success',
          title: 'Asignación eliminada exitosamente'
        });
        this.readAsignacion();
      },
      (error) => {
        this.Toast.fire({
          icon: 'error',
          title: 'Error al eliminar la asignación'
        });
      }
    );
  }

}
