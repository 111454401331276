<h2 mat-dialog-title>Proyectos asignados - {{ data.usuario.nombre }} </h2>
<mat-dialog-content class="mat-typography">
    <div *ngIf="dataSource" class="bg-light" style="height: 250px; overflow-y: auto;">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle() : null"
                              [checked]="selection.hasValue() && isAllSelected()"
                              [indeterminate]="selection.hasValue() && !isAllSelected()"
                              [aria-label]="checkboxLabel()"
                              color="primary">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()"
                              (change)="$event ? selection.toggle(row) : null"
                              [checked]="selection.isSelected(row)"
                              [aria-label]="checkboxLabel(row)"
                              color="primary">
                </mat-checkbox>
              </td>
            </ng-container>
          
            <!-- Proyecto Column -->
            <ng-container matColumnDef="proyecto">
              <th mat-header-cell *matHeaderCellDef> Proyecto </th>
              <td mat-cell *matCellDef="let element"> {{element.proyecto}} </td>
            </ng-container>
          
            <!-- Codificacion Column -->
            <ng-container matColumnDef="pantalla">
              <th mat-header-cell *matHeaderCellDef> Pantalla </th>
              <td mat-cell *matCellDef="let element"> {{element.pantalla}} </td>
            </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;">
            </tr>
          </table>
    </div>
    <div>
        <button
            *ngIf="dataSource"
            [disabled]="selection['_selected'].length==0"
            (click)="deleteAsignacion()" 
            mat-button>Eliminar asignaciones</button>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cerrar</button>
</mat-dialog-actions>