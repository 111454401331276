import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DetalleExcelService } from '../detalle-proyecto/detalle-excel.service';
import { DetalleProyectoService } from '../detalle-proyecto/detalle-proyecto.service';

@Component({
  selector: 'app-descarga',
  templateUrl: './descarga.component.html',
  styleUrls: ['./descarga.component.scss']
})
export class DescargaComponent implements OnInit {

  estadoList = [
    { value: 0, name: 'Sin usar' },
    { value: 1, name: 'Completado' },
    { value: 2, name: 'Pendiente' },
    { value: 3, name: 'No es posible completar' }
  ];

  selectEstado = null;
  controlValue = null;
  codificacionList = null;
  selectCodificacion = null;

  labels = 'value';

  constructor(
    public dialogRef: MatDialogRef<DescargaComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog,
    private detalleExcelService: DetalleExcelService,
    private detalleProyectoService: DetalleProyectoService,
  ) { }

  ngOnInit(): void {
  }

  downloadProyecto(){
    let body = {
      proyecto: Number(this.data.proyecto),
      identificador: this.data.identificador,
      estado: this.selectEstado,
      campoControl: this.data.control,
      control: this.controlValue,
      codificacion: this.selectCodificacion,
    };
    this.detalleProyectoService.downloadProyecto(body).
      subscribe((data)=>{
        this.detalleExcelService.downloadProyecto(this.data.nameProyecto, data, this.labels);
    },
    (error) => {
      console.log(error);
    });
  }

}
