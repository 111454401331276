import { Component, OnInit, Inject } from '@angular/core';

import {SelectionModel} from '@angular/cdk/collections';
import {MatTableDataSource} from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

import { CodigoService } from './codigo.service';
import { CodigoExcelService } from './codigo-excel.service';

export interface DialogData {
  proyecto: number;
  proyectoName: string;
}

export interface Codigos {
  id: number;
  nombre: string;
  codigo: number;
}

@Component({
  selector: 'app-codigo',
  templateUrl: './codigo.component.html',
  styleUrls: ['./codigo.component.scss']
})
export class CodigoComponent implements OnInit {

  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast: any) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });
  
  grupoList = null;
  codigoList = null;
  isGrupo = false;
  id_grupo = null;
  name_grupo = null;

  codigoColumns: string[] = ['select' ,'codigo', 'nombre', 'mas'];
  grupoColumns: string[] = ['id', 'grupo', 'mas'];

  dataSourceCode = null;
  selectionCode = new SelectionModel<Codigos>(true, []);
    
  constructor(
    public dialogRef: MatDialogRef<CodigoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private codigoService: CodigoService,
    private codigoExcelService: CodigoExcelService
  ) { }

  ngOnInit() {
    this.readGrupo();
  }

  /////////////////////////////// Busca grupo
  readGrupo(){
    this.codigoService.readGrupo(this.data.proyecto)
      .subscribe( (data) => {
        this.grupoList = Object.keys(data).map( (row) => {
          return {
            id: data[row][0], 
            name: data[row][1],
          }
        });
      },
      (error) => {
        console.log(error)
      }
    );
  }

  /////////////////////////////// Crea grupo
  createGrupo(){
    Swal.mixin({
      input: 'text',
      confirmButtonText: 'Crear',
      showCancelButton: true,
      allowEscapeKey: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      preConfirm: (values) =>{
        if (!values) {
          Swal.showValidationMessage(
            'Campo requerido'
          )
        }
      }
    }).queue([
      {
        title: 'Nuevo grupo',
        text: 'Escribe el nombre del grupo nuevo'
      }
    ]).then((result: any) => {
      if (result.value) {
        const name = result.value[0];
        this.codigoService.createGrupo(name, this.data.proyecto)
          .subscribe( (data) => {
            this.Toast.fire({
              icon: 'success',
              title: 'Grupo creado correctamente'
            });
            this.readGrupo();
          },
          (error) => {
            Swal.fire(
              `¡Se generó un error al crear el grupo ${name}, revise que el nombre del grupo no este duplicado, o intente más tarde!`,
              '',
              'info'
            );
          }
        );
      }
    })
  }

  /////////////////////////////// Edita grupo
  updateGrupo(id: number, name: string){
    Swal.mixin({
      input: 'text',
      confirmButtonText: 'Editar',
      showCancelButton: true,
      allowEscapeKey: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      preConfirm: (values) =>{
        if (!values) {
          Swal.showValidationMessage(
            "Campo requerido"
          )
        }
      }
    }).queue([
      {
        title: 'Edita grupo',
        text: 'Escribe el nuevo nombre del grupo',
        inputValue: `${name}`
      }
    ]).then((result: any) => {
      if (result.value) {
        const newname = result.value[0];
        this.codigoService.updateGrupo(newname, id)
          .subscribe( (data) => {
            this.Toast.fire({
              icon: 'success',
              title: 'Grupo editado creado correctamente'
            });
            this.readGrupo();
          },
          (error) => {
            Swal.fire(
              `¡Se generó un error al ceditado el grupo '${name}', revise que el nombre del grupo no este duplicado, o intente más tarde!`,
              '',
              'info'
            );
          }
        );
      }
    })
  }

  //////////////////////Elimina grupo
  deleteGrupo(id: number, name: string){
    Swal.fire({
      title: `¿Estas seguro de eliminar el grupo ${name}?`,
      text: 'Se eliminara todo lo relacionado con el grupo',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, Eliminar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.codigoService.deleteGrupo(id)
          .subscribe( (data) => {
            this.Toast.fire({
              icon: 'success',
              title: 'El grupo se eliminó correctamente'
            });
            this.readGrupo();
          },
          (error) => {
            Swal.fire(
              'Error',
              'Ocurrio un error al eliminar el grupo, intenta más tarde.',
              'error'
            );
          });
      }
    })
  }

  ////////////Selecciona grupo
  selectGrupo(id: number){
    this.isGrupo = true;
    this.id_grupo = id;
    this.readCodigo(this.id_grupo);
  }

   /////////////////////////////// Busca Codigo
   readCodigo(grupo: number){
    this.codigoService.readCodigo(grupo)
      .subscribe( (data) => {
        this.codigoList = Object.keys(data).map( (row) => {
          return {
            id: Number(data[row][0]),
            code: Number(data[row][1]),
            name: data[row][2]
          }
        });
        this.selectionCode = new SelectionModel<Codigos>(true, []);
        this.dataSourceCode = new MatTableDataSource<Codigos>(this.codigoList);
      },
      (error) => {
        console.log(error)
      }
    );
  }

  /////////////////////////////// Crea Codigo
  createCodigo(){
    Swal.mixin({
      input: 'text',
      confirmButtonText: 'Siguiente &rarr;',
      showCancelButton: true,
      allowEscapeKey: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      preConfirm: (values) =>{
        if (!values) {
          Swal.showValidationMessage(
            'Campo requerido'
          )
        }
      }
    }).queue([
      {
        title: 'Nuevo código',
        text: 'Escribe el código nuevo',
        input: 'number'
      },
      {
        title: 'Nombre de código',
        text: 'Escribe la nombre del código',
      }
    ]).then((result: any) => {
      if (result.value) {
        let codigo = result.value[0];
        let nombre = result.value[1];
        let data = [
          [codigo,nombre]
        ];
        Swal.fire({
          title: `Código nuevo: '${codigo}' &rarr; '${nombre}'`,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '¡Crear!',
          cancelButtonText: 'Cancelar'
        }).then((result: any) => {
          this.codigoService.createCodigo(data, this.id_grupo)
            .subscribe( (data) => {
              this.Toast.fire({
                icon: 'success',
                title: 'Código creado exitosamente!'
              });
              this.readCodigo(this.id_grupo);
            },
            (error) => {
              Swal.fire(
                `¡Se generó un error al crear código '${codigo}' &rarr; '${nombre}', revise que el código o el nombre no este duplicado, o intente más tarde!`,
                '',
                'info'
              );
            }
          );
        });
      }
    })
  }

  /////////////////////////////// edita codigo
  updateCodigo(id: number, code: number, name: string){
    Swal.mixin({
      input: 'text',
      confirmButtonText: 'Siguiente &rarr;',
      showCancelButton: true,
      allowEscapeKey: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      preConfirm: (values) =>{
        if (!values) {
          Swal.showValidationMessage(
            "Campo requerido"
          )
        }
      }
    }).queue([
      {
        title: `Edita código &rarr; ${code}`,
        text: `Escribe el código nuevo`,
        input: 'number',
        inputValue: `${code}`
      },
      {
        title: 'Edita nombre del código',
        text: `Escribe el nuevo nombre del código '${name}'`,
        inputValue: `${name}`
      }
    ]).then((result: any) => {
      if (result.value) {
        const newcode = result.value[0];
        const newname = result.value[1];
        Swal.fire({
          title: `¡Editar '${code}' &rarr; '${newcode}', '${name}' &rarr; '${newname}' !`,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '!Editar!',
          cancelButtonText: 'Cancelar'
        }).then((result: any) => {
          this.codigoService.updateCodigo(id, newcode, newname)
            .subscribe( (data) => {
              this.Toast.fire({
                icon: 'success',
                title: 'Código editado exitosamente!'
              });
              this.readCodigo(this.id_grupo);
            },
            (error) => {
              Swal.fire(
                `¡Se generó un error al editar el código '${name}', revise que el código o el nombre no esten duplicados, o intente más tarde!`,
                '',
                'info'
              );
            }
          );
        });
      }
    })
  }

  /////////////////////////////// Elimina codificacion
  deleteCodigo(id: string, code: string, name: string){
    Swal.fire({
      title: `¿Estas seguro de eliminar el código '${code}' &rarr; '${name}'?`,
      text: "Se eliminara permanentemente el código",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, Eliminar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.codigoService.deleteCodigo(id)
          .subscribe( (data) => {
            this.Toast.fire({
              icon: 'success',
              title: 'El código se eliminó correctamente!'
            });
            this.selectionCode.clear();
            this.readCodigo(this.id_grupo);
          },
          (error) => {
            Swal.fire(
              'Error',
              'Ocurrio un error al eliminar el código, intenta más tarde.',
              'error'
            );
          });
      }
    })
  }

  /** Seleccciona todos los codigos */
  isAllSelected() {
    const numSelected = this.selectionCode.selected.length;
    const numRows = this.dataSourceCode.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
    this.selectionCode.clear() :
    this.dataSourceCode.data.forEach(row => this.selectionCode.select(row));
  }

  checkboxLabel(row?: Codigos): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selectionCode.isSelected(row) ? 'deselect' : 'select'} row ${row.codigo + 1}`;
  }

  /** Elimina codigos seleccionados */
  deleteSelectCodes(){
    let item = [];
    for(let select of this.selectionCode.selected){
      item.push(select.id);
    }
    let deleted = item.toString();
    Swal.fire({
      title: `¿Estas seguro de eliminar los ${item.length} códigos seleccionados?`,
      text: "Se eliminaran permanentemente los códigos",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, Eliminar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.codigoService.deleteCodigo(deleted)
          .subscribe( (data) => {
            this.Toast.fire({
              icon: 'success',
              title: 'Los códigos se eliminaron correctamente!'
            });
            this.selectionCode.clear();
            this.readCodigo(this.id_grupo);
          },
          (error) => {
            Swal.fire(
              'Error',
              'Ocurrio un error al eliminar los códigos, intenta más tarde.',
              'error'
            );
          });
      }
    })
    
  }
  
  async uploadCodigo(){
    Swal.fire({
      title: 'Selecciona xlsx',
      input: 'file',
      inputAttributes: {
          'accept': 'file/*',
          'aria-label': 'Carga archivo xlsx'
      },
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Ok',
      showLoaderOnConfirm: true,
      preConfirm: (file) => {
          if (file) {
              const reader = new FileReader;
              reader.onload = (e) => {
                const data = reader.result;
                let workBook = XLSX.read(data, { type: 'binary' });
                let sheet1 = workBook.SheetNames[0];
                let sheet = workBook.Sheets[sheet1];
                let jsonData = XLSX.utils.sheet_to_json(sheet);
                let arrayData = [];
                jsonData.forEach(element => {
                  let row = [];
                  Object.keys(element).map(r =>{
                    row.push(element[r]);
                  });
                  arrayData.push(row);
                });
                Swal.fire({
                  title: 'Carga de datos',
                  text: `El archivo contiene ${arrayData.length} filas`,
                  icon: 'info',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonText: 'Cancelar',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Cargar'
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.codigoService.createCodigo(arrayData,this.id_grupo)
                      .subscribe( (data) => {
                        Swal.fire(
                          'Códigos',
                          `Se cargaron ${data} códigos correctamente.`,
                          'success'
                        );
                        this.readCodigo(this.id_grupo);
                      },
                      (error) => {
                        Swal.fire(
                          'Error',
                          'Ocurrio un error al cargar los códigos, intenta más tarde.',
                          'error'
                        );
                      });
                  }
                })
              };
              reader.readAsBinaryString(file);
          }
      },
      allowOutsideClick: () => !Swal.isLoading()
    });
    
  }

  /**Descargar codigos */
  downloadCodigo(){
    this.codigoService.downloadCodigo(this.data.proyecto).
      subscribe((data) => {
        this.codigoExcelService.downloadCodigo(this.data.proyectoName, data);
      },
      (error) => {
        console.log(error);
      });
  }


}
