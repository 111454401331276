import { Component, OnInit } from '@angular/core';
import { ConexionService } from '../conexion.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-conexion-nueva',
  templateUrl: './conexion-nueva.component.html',
  styleUrls: ['./conexion-nueva.component.scss']
})
export class ConexionNuevaComponent implements OnInit {

  manejadorList = [
    {value: 'MySQL', viewValue: 'MySQL'}, 
    {value: 'PostgreSQL', viewValue: 'PostgreSQL'}
  ];
  alias = null;
  sistemadb = null;
  host = null;
  db = null;
  usuario = null;
  password = null;
  puerto = null;

  isconexion = false;

  conexionList = [];

  constructor(
    public conexionService: ConexionService
  ) { }

  ngOnInit(): void {
  }

  ///////////////Prueba de conexion
  testConexion(){
    Swal.fire({
      icon: 'info',
      title: 'Probando conexión',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });

    let body = {
      alias: this.alias,
      sistema: this.sistemadb,
      host: this.host,
      db: this.db,
      usuario: this.usuario,
      password: this.password,
      port: this.puerto
    };

    this.conexionService.testConexion(body)
      .subscribe( (data) => {
        if (data) {
          Swal.fire({
            icon: 'success',
            title: 'Conexión exitosa',
            showConfirmButton: false,
            timer: 1500
          })
          this.isconexion = true;
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Error de conexión, revise la información.',
          })
        }
      },
      (error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Revise su conexión a internet.',
        })
      }
    );
    
  }

  //////////Crear conexion
  createConexion(){
    Swal.fire({
      icon: 'info',
      title: 'Creando conexión',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });

    let body = {
      alias: this.alias,
      sistema: this.sistemadb,
      host: this.host,
      db: this.db,
      usuario: this.usuario,
      password: this.password,
      port: this.puerto
    };

    this.conexionService.createConexion(body)
      .subscribe( (data) => {
        if (data) {
          Swal.fire({
            icon: 'success',
            title: 'Conexión creada correctamente',
            showConfirmButton: false,
            timer: 1500
          })
          this.isconexion = true;
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Error al crear conexión, revise la información.',
          })
        }
      },
      (error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Revise su conexión a internet.',
        })
      }
    );
  }


  ///////////////Puerto predeterminado de manejador BD
  change(event: any){
    switch (event.value) {
      case 'MySQL':
        this.puerto = '3306';
        break;
      
      case 'PostgreSQL':
        this.puerto = '5432';
        break;
      default:
        break;
    }
  }

}
