<h1 mat-dialog-title>Importar datos</h1>
<mat-dialog-content class="mat-typography bg-light" style="height: calc(100% - 80px);max-height: unset">
    <mat-tab-group 
        [selectedIndex]="selected.value"
        (selectedIndexChange)="selected.setValue($event)"
        mat-align-tabs="start">
        <mat-tab label="Mis importaciones">
            <div class="mt-3">
                <table mat-table [dataSource]="dataSourceImportacion" class="mat-elevation-z8">
                    <ng-container matColumnDef="codificacion">
                      <th mat-header-cell *matHeaderCellDef>Codificación</th>
                      <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
                    </ng-container>

                    <ng-container matColumnDef="accion">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <button mat-icon-button [matMenuTriggerFor]="menu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button
                                    mat-menu-item
                                    (click)="initImportacion(element)">
                                    <mat-icon>import_export</mat-icon>
                                    <span>Iniciar importación</span>
                                </button>
                                <button
                                    mat-menu-item
                                    (click)="openUpdateImportacion(element)">
                                    <mat-icon>edit</mat-icon>
                                    <span>Editar</span>
                                </button>
                                <button 
                                    mat-menu-item
                                    (click)="deleteImportacion(element)">
                                    <mat-icon>delete</mat-icon>
                                    <span>Eliminar</span>
                                </button>
                            </mat-menu>
                        </td>
                    </ng-container>
                  
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsImportacion; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsImportacion;"></tr>
                </table>
            </div>
        </mat-tab>

        <mat-tab label="Asistente de importación">
            <div class="mt-3">
                <p>Asistente para importar datos de base de datos externa.</p>
                <mat-horizontal-stepper [linear]="true" #stepper>
                    <mat-step [stepControl]="firstFormGroup">
                      <form [formGroup]="firstFormGroup">
                        <ng-template matStepLabel>Conexión</ng-template>
                        <h4>Selecciona conexión.</h4>
                        <mat-form-field appearance="fill">
                            <mat-label>Conexión</mat-label>
                            <mat-select formControlName="firstCtrl1" required>
                                <mat-option *ngFor="let conexion of conexionList" [value]="conexion">
                                    {{conexion.viewValue}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <br>
                        <div *ngIf="firstFormGroup.controls.firstCtrl1.status=='VALID'">
                            <label>Seleccione una opción: </label>
                            <mat-radio-group 
                                formControlName="firstCtrl2" 
                                color="primary" 
                                (ngModelChange)="selectOption($event)">
                                <mat-radio-button value="1">Agregar tabla</mat-radio-button>
                                <mat-radio-button value="2">Crear consulta SQL</mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <br>
                        <div
                            *ngIf="firstFormGroup.controls.firstCtrl2.value=='1'" 
                            style="overflow: auto; height: 200px;">
                            <h4>Información de tabla.</h4>
                            <span>Tabla a importar:</span>
                            <ngx-select-dropdown 
                                [multiple]="false" 
                                [config]="configTablas"
                                [options]="tablasList"
                                (ngModelChange)="readCamposTabla()"
                                formControlName="firstCtrl3">
                            </ngx-select-dropdown>
                            <br>
                            <span>
                                Campos a importar:<br>
                                <b>Nota: </b>Puede agregar una variable que sirva como filtro,
                                y disminuir el tiempo de importación.
                            </span>
                            <ngx-select-dropdown 
                                [multiple]="true" 
                                [config]="configCamposTablas"
                                [options]="camposTablaList"
                                formControlName="firstCtrl4">
                            </ngx-select-dropdown>
                        </div>
                        <br>
                        <div *ngIf="firstFormGroup.controls.firstCtrl2.value=='2'">
                            <h4>Consulta SQL</h4>
                            <mat-form-field appearance="fill" style="width: 100%;">
                                <mat-label>SQL</mat-label>
                                <textarea 
                                    matInput 
                                    formControlName="firstCtrl5"
                                    placeholder="Ej. SELECT campo1, campo2, ... FROM tabla"></textarea>
                            </mat-form-field>
                        </div>
                        <br>
                        
                        <div>
                            <button mat-button matStepperNext>Siguiente</button>
                        </div>
                      </form>
                    </mat-step>

                    <mat-step [stepControl]="previewFormGroup" label="Vista previa">
                        <h4>En esta sección puedes visualizar los datos de tu consulta.</h4>
                        <form [formGroup]="previewFormGroup">
                            <button
                                mat-raised-button
                                class="mb-3"
                                color="primary"
                                (click)="readDataPreview()">
                                Cargar datos
                            </button>
                            <div>
                                <button mat-button matStepperPrevious>Regresar</button>
                                <button mat-button matStepperNext>Siguiente</button>
                            </div>
                        </form>
                        <div *ngIf="dataSourcePreview" class="example-container mat-elevation-z8">
                            <table mat-table [dataSource]="dataSourcePreview">                            
                                <ng-container [matColumnDef]="column" *ngFor="let column of camposPreview; let i=index">
                                <th class="columns-default" mat-header-cell *matHeaderCellDef> {{column}} </th>
                                <td 
                                    mat-cell 
                                    class="columns-default"
                                    *matCellDef="let element">
                                    {{ element[column] }}
                                </td>
                                </ng-container>
                            
                                <tr mat-header-row *matHeaderRowDef="camposPreview; sticky: true"></tr>
                                <tr mat-row *matRowDef="let row; columns: camposPreview;"></tr>
                            </table>
                        </div>
                    </mat-step>

                    <mat-step [stepControl]="secondFormGroup" label="Seleción de pantalla">
                        <h4>Seleccione la pantalla en la que desea importar los datos.</h4>
                        <form [formGroup]="secondFormGroup">
                            <mat-selection-list
                                formControlName="secondCtrl" 
                                [multiple]="false" 
                                required>
                                <mat-list-option 
                                    *ngFor="let codificacion of codificacionList"
                                    [value]="codificacion"
                                    (click)="readCamposPantalla();">
                                    {{codificacion.name}}
                                </mat-list-option>
                            </mat-selection-list>
                            <div>
                                <button mat-button matStepperPrevious>Regresar</button>
                                <button mat-button matStepperNext>Siguiente</button>
                            </div>
                        </form>
                    </mat-step>

                    <mat-step [stepControl]="mapeoForm" label="Mapeo de campos">
                        <h4>Seleccione los campos de la tabla que requiera mapear con los campos de la pantalla.</h4>    
                        <form [formGroup]="mapeoForm" *ngIf="dataSourceMapeo">
                            <div style="overflow: auto; height: 350px;">
                                <table mat-table [dataSource]="dataSourceMapeo" class="mat-elevation-z8">
                                    <ng-container matColumnDef="tipo">
                                        <th mat-header-cell *matHeaderCellDef> Tipo </th>
                                        <td mat-cell *matCellDef="let element"> {{element[0]}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="pantalla">
                                        <th mat-header-cell *matHeaderCellDef> Campo de pantalla </th>
                                        <td mat-cell *matCellDef="let element"> {{element[1]}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="tabla">
                                        <th mat-header-cell *matHeaderCellDef> Campo de tabla </th>
                                        <td mat-cell *matCellDef="let element">
                                            <ngx-select-dropdown 
                                                [multiple]="false" 
                                                [config]="configCamposTablas"
                                                [formControlName]="element[1]"
                                                [options]="selectCamposList">
                                            </ngx-select-dropdown>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="accion">
                                        <th mat-header-cell *matHeaderCellDef></th>
                                        <td mat-cell *matCellDef="let element">
                                            <button
                                                *ngIf="element[1]=='filtro'"
                                                mat-icon-button
                                                color="warn"
                                                matTooltip="Eliminar filtro"
                                                matTooltipPosition="above"
                                                style="float: left;"
                                                (click)="mapeoForm.controls['filtro'].reset()">
                                                <mat-icon>clear</mat-icon>
                                            </button>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumnsMapeo; sticky: true"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumnsMapeo;"></tr>
                                </table>
                            </div>
                            <div>
                                <button mat-button matStepperPrevious>Regresar</button>
                                <button 
                                    *ngIf="!isupdate"
                                    mat-button 
                                    (click)="createImportar()">Guardar</button>
                                <button 
                                    *ngIf="isupdate"
                                    mat-button 
                                    (click)="updateImportacion()">Editar</button>
                            </div>
                        </form>
                    </mat-step>

                </mat-horizontal-stepper>
            </div>
        </mat-tab>
    </mat-tab-group>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cerrar</button>
</mat-dialog-actions>