import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from "@env/environment";
import { UsuarioModel } from '../models/usuario.model';

import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  env = environment;
  userToken: string = '';
  constructor( 
    private http: HttpClient
  ) { }

  login( usuario: UsuarioModel ){

    const authData = {
      ...usuario
    };

    return this.http.post(
      `${ this.env.api }/auth`,
      authData
    ).pipe(
      map( (resp) => {
        this.saveToken(resp['idToken']);
        return resp;
      })
    );
  }

  private saveToken(idToken: string){
    this.userToken = idToken;
    localStorage.setItem('idToken', idToken);
  }

  getToken(){
    if (this.userToken) {
      this.userToken = localStorage.getItem('idToken');  
    }else{
      this.userToken = '';
    }
    return this.userToken;
  }

  statusAuth(): boolean {
    if (localStorage.getItem('idToken')) {
      return true;  
    }
    return false;
  }

  logout(){
    localStorage.removeItem('idToken');
  }

  getPermissions(){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.get(`${ this.env.api }/auth`, { headers });

  }

}


