import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class CodigoExcelService {

  constructor() { }
  
  downloadCodigo(proyectoName: any, body: any){
    let workbook = new Workbook();
    for (let grupo of body) {
      const title = 'Códigos: ' + grupo.grupo;
      let worksheet = workbook.addWorksheet(grupo.grupo);
      // Add new row
      let titleRow = worksheet.addRow([title]);
      worksheet.addRow(['Creado por Procod']);
      worksheet.addRow([]);

      titleRow.font = { name: 'Arial', family: 4, size: 14, bold: true };
  
      let headerRow = worksheet.addRow(grupo.campos);
      for (let row of grupo.resultado){
        let keys = grupo.campos;
        let temp=[];
        for(let key of keys){
          temp.push(row[key]);
        }
        worksheet.addRow(temp);
      }
  
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '4D79FF' },
          bgColor: { argb: '4D79FF' }
        }
        cell.style.font = {
          bold: true,
          color: { argb: 'FFFFFF' }
        }
      });
    }
    
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, `Códigos - ${proyectoName}.xlsx`);
    });

  }


}
