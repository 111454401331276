<mat-sidenav-container class="sidenav-container">
    <mat-sidenav 
        #sidenav
        position="end" 
        style="width: 200px;" 
        class="bg-light"
        mode="side" 
        [(opened)]="opened">
        <button
            mat-icon-button
            matTooltip="Cerrar"
            matTooltipPosition="left"
            (click)="sidenav.toggle()">
            <mat-icon>chevron_right</mat-icon>
        </button>
        
        <mat-nav-list>
            <div class="m-3">
                <a 
                    mat-list-item
                    routerLinkActive="active"
                    (click)="openDialogCarga()"
                ><mat-icon class="mr-2">arrow_circle_up</mat-icon>Cargar datos</a>
                <a 
                    mat-list-item
                    routerLinkActive="active"
                ><mat-icon class="mr-2">arrow_circle_down</mat-icon>Descargar tabla</a>
            </div>
        </mat-nav-list>

    </mat-sidenav>

    <mat-sidenav-content>
        <button 
            mat-icon-button
            *ngIf="!opened"
            matTooltip="Menú"
            matTooltipPosition="left"
            class="float-right" 
            (click)="sidenav.toggle()">
            <mat-icon>chevron_left</mat-icon>
        </button>
        <div class="m-5">
            <h1><span class="material-icons">query_stats</span>Analytics</h1>
            <div *ngIf="dataSource" class="container mat-elevation-z8">
                <table mat-table [dataSource]="dataSource">
                    <ng-container [matColumnDef]="column" *ngFor="let column of camposList; let i=index">
                    <th class="columns-default" mat-header-cell *matHeaderCellDef>
                        <table>
                            <tr>
                                <td>{{column}}</td>
                                <td>
                                    <button 
                                        mat-icon-button
                                        [matMenuTriggerFor]="menu">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <button
                                            mat-menu-item
                                            (click)="openDialogTipoVar(column)">
                                            <mat-icon>workspaces</mat-icon>
                                            <span>Tipo de variable</span>
                                        </button>
                                        <button
                                            mat-menu-item
                                            
                                            (click)="openDialogRecodificar(column)">
                                            <mat-icon>hdr_weak</mat-icon>
                                            <span>Recodificar columna</span>
                                        </button>
                                    </mat-menu>
                                </td>
                            </tr>
                        </table>
                    </th>
                    <td 
                        mat-cell 
                        class="columns-default"
                        *matCellDef="let element">
                        {{ element[column] }}
                    </td>
                    </ng-container>
                
                    <tr mat-header-row *matHeaderRowDef="camposList; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: camposList;"></tr>
                </table>
            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>