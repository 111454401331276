<h1 mat-dialog-title>Validación de {{ data['type'] }}</h1>
<div mat-dialog-content>
  <p>Selecciona la validaciones que requieras:</p>
  <span class="example-list-section">
    <ul>
      <li>
        <mat-checkbox [(ngModel)]="data.codigo">No permitir códigos duplicados</mat-checkbox>
      </li>
      <li>
        <mat-checkbox [(ngModel)]="data.vacio">No permitir llenado parcial</mat-checkbox>
      </li>
    </ul>
  </span>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Ok</button>
</div>