import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-avance-filtro',
  templateUrl: './avance-filtro.component.html',
  styleUrls: ['./avance-filtro.component.scss']
})
export class AvanceFiltroComponent implements OnInit {

  fechaIniForm = new FormControl();
  fechaFinForm = new FormControl();
  control = null;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit(): void {
  }

  filtro(){
    let data = {
      control: this.control,
      fechaini: this.fechaIniForm.value ? (new Date(this.fechaIniForm.value)).toDateString() : null,
      fechafin: this.fechaFinForm.value ? (new Date(this.fechaFinForm.value)).toDateString() : null
    }
    this.dialogRef.close(data);
  }

}
