<h1 mat-dialog-title>Edición de registros</h1>
<mat-dialog-content class="mat-typography">
    
    <div *ngIf="!data.multiple" class=" m-3">
        <form [formGroup]="singleForm">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>Campo</th>
                        <th>Valor</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let campo of data.campos; let i=index">
                        <td *ngIf="i==1">{{ campo }}</td>
                        <td *ngIf="i==1">{{ data.registros[0][campo] }}</td>

                        <td *ngIf="i==2">{{ campo }}</td>
                        <td *ngIf="i==2"><input type="text" class="form-control" [formControlName]="campo" [value]="data.registros[0][campo]" ></td>

                        <td *ngIf="i==4">{{ campo }}</td>
                        <td *ngIf="i==4">
                            <mat-form-field appearance="fill">
                                <mat-label>Selecciona estado</mat-label>
                                <mat-select [formControlName]="campo">
                                  <mat-option *ngFor="let estado of estadoList" [value]="estado.value">
                                    {{estado.viewValue}}
                                  </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>


                        <td *ngIf="i>=8 && campoGrupo">{{ campo }}</td>
                        <td *ngIf="i>=8 && campoGrupo">
                            <input
                                *ngIf="!campoGrupo[campo]"
                                type="text" 
                                class="form-control" 
                                [formControlName]="campo" 
                                [value]="data.registros[0][campo]" >
                            <ngx-select-dropdown 
                                *ngIf="campoGrupo[campo]"
                                style="float: left; width: 500px;"
                                [multiple]="false"
                                [formControlName]="campo"
                                [config]="config"
                                [options]="codigos[campoGrupo[campo]]"
                            >
                            </ngx-select-dropdown>
                            <br><br>
                            <p 
                                *ngIf="campoGrupo[campo] &&
                                singleForm.value[campo].value">
                                Seleccionado: <b>{{ singleForm.value[campo]['description'] }}</b>
                            </p>
                        </td>
                        <td *ngIf="i>=8 && campoGrupo">
                            <button
                                *ngIf="campoGrupo[campo]"
                                mat-icon-button
                                color="warn"
                                matTooltip="Eliminar código"
                                matTooltipPosition="above"
                                style="float: left;"
                                (click)="deleteCode(campo)">
                                <mat-icon>clear</mat-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </form>
    </div>

    <div *ngIf="data.multiple" class=" m-3">
        <div class="example-button-container">
            <mat-form-field appearance="fill">
                <mat-label>Campos editables</mat-label>
                <mat-select [formControl]="camposForm" multiple>
                <mat-option *ngFor="let campo of camposList" [value]="campo">{{campo}}</mat-option>
                </mat-select>
            </mat-form-field>
            <button
                *ngIf="isdisabled"
                mat-mini-fab 
                color="primary" 
                class="ml-2"
                [disabled]="!camposForm.value"
                (click)="camposSelect()"><mat-icon matTooltip="Cargar">add</mat-icon></button>
            <button
                *ngIf="!isdisabled"
                mat-mini-fab 
                color="warn" 
                class="ml-2"
                (click)="camposDelete()"><mat-icon matTooltip="Eliminar selección">delete</mat-icon></button>
        </div>

        <div *ngIf="multipleForm">
            <form [formGroup]="multipleForm">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>Campo</th>
                            <th>Valor</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="selectList">
                        <tr *ngFor="let campo of selectList; let i=index">
                            <td>{{ campo }}</td>
                            <td *ngIf="campo!='estado'">
                                <!-- <input type="text" class="form-control" [formControlName]="campo"> -->
                                <input
                                    *ngIf="!campoGrupo[campo]"
                                    type="text" 
                                    class="form-control" 
                                    [formControlName]="campo">
                                <ngx-select-dropdown 
                                    *ngIf="campoGrupo[campo]"
                                    style="float: left; width: 500px;"
                                    [multiple]="false"
                                    [formControlName]="campo"
                                    [config]="config"
                                    [options]="codigos[campoGrupo[campo]]"
                                >
                                </ngx-select-dropdown>
                                <br><br>
                                <p 
                                    *ngIf="campoGrupo[campo] &&
                                    multipleForm.value[campo].value">
                                    Seleccionado: <b>{{ multipleForm.value[campo]['description'] }}</b>
                                </p>
                            </td>
                            <td *ngIf="campoGrupo[campo]">
                                <button
                                    mat-icon-button
                                    color="warn"
                                    matTooltip="Eliminar código"
                                    matTooltipPosition="above"
                                    style="float: left;"
                                    (click)="deleteCode(campo)">
                                    <mat-icon>clear</mat-icon>
                                </button>
                            </td>
                            <td *ngIf="campo=='estado'">
                                <mat-form-field appearance="fill">
                                    <mat-label>Selecciona estado</mat-label>
                                    <mat-select [formControlName]="campo">
                                      <mat-option *ngFor="let estado of estadoList" [value]="estado.value">
                                        {{estado.viewValue}}
                                      </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cerrar</button>
    <button mat-button (click)="updateRegistro()">Editar</button>
</mat-dialog-actions>