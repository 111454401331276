import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ProyectoComponent } from './proyecto.component';
import { DetalleProyectoComponent } from './detalle-proyecto/detalle-proyecto.component';

import { Pages } from '../pages.service';

const routes: Routes = [
    Pages.childRoutes([
        { path: 'proyectos', component: ProyectoComponent },
        { path: 'proyectos/:proyecto', component: DetalleProyectoComponent }
    ])
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: []
})
export class ProyectoRoutingModule {}
