import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from "@env/environment";

@Injectable({
  providedIn: 'root'
})
export class AvanceService {

  env = environment;
  constructor(
    private http: HttpClient
  ) { }

  
  ///////////////////////Busca codificacion
  readAvanceProyecto(body: any){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.post(`${ this.env.api }/avance/proyecto`, body, { headers });
  }
  
  ///////////////////////Busca codificacion
  readAvanceCodificacion(body: any){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.post(`${ this.env.api }/avance/codificacion`, body, { headers });
  }


}
