import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import Swal from 'sweetalert2';

import { AuthService } from '../../service/auth.service';
import { ProyectoService } from './proyecto.service';

import { EventoComponent } from './evento/evento.component';

@Component({
  selector: 'app-proyecto',
  templateUrl: './proyecto.component.html',
  styleUrls: ['./proyecto.component.scss']
})
export class ProyectoComponent implements OnInit {
  
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast: any) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  permissions = null;
  proyectoList = null;
  
  constructor(
    private proyectoService: ProyectoService,
    private auth: AuthService,
    public dialog: MatDialog,
    private router: Router
  ) {  }

  async ngOnInit() {
    await this.getPermissions();
    this.readProyecto();
  }

  ///////////////////Busca permisos
  async getPermissions(){
    this.auth.getPermissions()
      .subscribe( (data) => {
        this.permissions = data;
      },
      (error) => {
        this.router.navigateByUrl('/login');
      }
    );
  }

  /////////////////////////////// Busca proyectos
  readProyecto(){
    this.proyectoService.readProyecto()
      .subscribe( (data) => {
        this.proyectoList = Object.keys(data).map( (i) => {
          return {id: i, name: data[i]}
        });
      },
      (error) => {
        console.log(error)
      }
    );
  }

  /////////////////////Selecciona proyecto
  selectProyecto(id: number){
    this.router.navigateByUrl('proyectos/'+String(id));
  }

  /////////////////////////////// Crea proyecto
  createProyecto(){
    let campo = 1;
    Swal.mixin({
      input: 'text',
      confirmButtonText: 'Siguiente &rarr;',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#d33',
      allowEscapeKey: true,
      preConfirm: (values) =>{
        if (!values && campo < 3) {
          Swal.showValidationMessage(
            'Campo requerido'
          );
        }else{
          campo += 1;
        }
      }
    }).queue([
      {
        title: 'Nuevo proyecto',
        text: 'Escribe el nombre del proyecto nuevo'
      },
      {
        title: 'Identificador',
        text: 'Escribe el nombre del campo que servira de identificador para tus tablas de codificación'
      },
      {
        title: 'Variable de control (Opcional)',
        text: 'Escribe el nombre del campo que servira como variable de control (Variable de corte, periodo, etc.). Por default se creara una variable llamada "control"'
      }
    ]).then((result: any) => {
      if (result.value) {
        let body = {
          nombre: result.value[0],
          identificador: result.value[1].toLowerCase(),
          control: result.value[2].toLowerCase()
        };
        this.proyectoService.createProyecto(body)
          .subscribe( (data) => {
            this.Toast.fire({
              icon: 'success',
              title: 'Proyecto creado correctamente'
            });
            this.readProyecto();
          },
          (error) => {
            Swal.fire(
              `¡Se generó un error al crear el proyecto ${result.value[0]}, revise que el nombre del proyecto no este duplicado, o intente más tarde!`,
              '',
              'info'
            );
          }
        );
      }
    })
  }

  /////////////Lleva a vista de eventos
  openDialogEvento() {
    const dialogRef = this.dialog.open(EventoComponent, {
      width: '100%',
      disableClose: true
    });

  }

}
