<h2 mat-dialog-title>Opciones avanzadas</h2>
<mat-dialog-content class="mat-typography">
    <mat-tab-group>
        <mat-tab label="Cambiar estado">
            <div class="m-3">
                <p>Esta opción te permite cambiar el estado de los registros del proyecto.</p>
                <mat-form-field appearance="fill">
                    <mat-label>Estado</mat-label>
                    <mat-select [(ngModel)]="estado" name="estado">
                    <mat-option *ngFor="let estado of estadoList" [value]="estado.value">{{estado.viewValue}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="example-full-width">
                    <mat-label>Registros</mat-label>
                    <textarea matInput placeholder="Ej. {{data.identificador}}_1, {{data.identificador}}_2, ..." [(ngModel)]="registrosEstado" name="registros-estado"></textarea>
                </mat-form-field>
                <button 
                    mat-stroked-button
                    [disabled]="estado==null||registrosEstado==null"
                    color="primary" 
                    (click)="updateAvanzadas('estado')">Editar Registros</button>
            </div>
        </mat-tab>
        <mat-tab label="Cambiar {{ data.control }}">
            <div class="m-3">
                <p>Esta opción te permite cambiar el valor de la variable de <b>{{ data.control }}</b> de los registros del proyecto.</p>
                <form class="example-form">
                    <mat-form-field class="example-full-width">
                        <mat-label>Campo {{ data.control }}</mat-label>
                        <input matInput placeholder="Ej. {{ data.control }} 1" [(ngModel)]="control" name="control">
                    </mat-form-field>
                    <mat-form-field class="example-full-width">
                        <mat-label>Registros</mat-label>
                        <textarea matInput placeholder="Ej. {{data.identificador}}_1, {{data.identificador}}_2, ..." [(ngModel)]="registrosControl" name="registros-control"></textarea>
                    </mat-form-field>
                    <button 
                        mat-stroked-button 
                        [disabled]="control==null||registrosControl==null"
                        color="primary" 
                        (click)="updateAvanzadas(data.control)">Editar Registros</button>
                </form>
            </div>
        </mat-tab>
    </mat-tab-group>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cerrar</button>
</mat-dialog-actions>
