<h2 mat-dialog-title>Crear conexión</h2>
<mat-dialog-content class="mat-typography">
  <h3 class="m-3">Asistente para conexión a base de datos externa.</h3>
  <table class="mx-auto" style="width: 80%;">
    <tr>
      <td>
        <h4>Escriba un alias que identifique la conexión:</h4>
        <mat-form-field class="form-field">
          <mat-label>Alias</mat-label>
          <input matInput type="text" [(ngModel)]="alias" required>
          <mat-error *ngIf="!alias">Alias requerido</mat-error>
          <button mat-button *ngIf="alias" matSuffix mat-icon-button aria-label="Clear" (click)="alias=''">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </td>
      <td>&nbsp;</td>
      <td>
        <h4>Selecciona el sistema de gestión de base de datos:</h4>
        <mat-form-field appearance="fill" class="form-field">
          <mat-label>Sistema</mat-label>
          <mat-select [(ngModel)]="sistemadb" (selectionChange)="change($event)" required>
            <mat-option *ngFor="let manejador of manejadorList" [value]="manejador.value">
              {{manejador.viewValue}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="!sistemadb">Sistema requerido</mat-error>
        </mat-form-field>
      </td>
    </tr>

    <tr>
      <td>
        <h4>Escriba la dirección del host de la base de datos:</h4>
        <mat-form-field class="form-field">
          <mat-label>Host</mat-label>
          <input matInput type="text" [(ngModel)]="host" required>
          <mat-error *ngIf="!host">Host requerido</mat-error>
          <button mat-button *ngIf="host" matSuffix mat-icon-button aria-label="Clear" (click)="host=''">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </td>
      <td>&nbsp;</td>
      <td>
        <h4>Escriba el nombre de la base de datos:</h4>
        <mat-form-field class="form-field">
          <mat-label>Base de datos</mat-label>
          <input matInput type="text" [(ngModel)]="db" required>
          <mat-error *ngIf="!db">Base de datos requerida</mat-error>
          <button mat-button *ngIf="db" matSuffix mat-icon-button aria-label="Clear" (click)="db=''">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </td>
    </tr>

    <tr>
      <td>
        <h4>Escriba el nombre de usuario:</h4>
        <mat-form-field class="form-field">
          <mat-label>Usuario</mat-label>
          <input matInput type="text" [(ngModel)]="usuario" required>
          <mat-error *ngIf="!usuario">Usuario requerido</mat-error>
          <button mat-button *ngIf="usuario" matSuffix mat-icon-button aria-label="Clear" (click)="usuario=''">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </td>
      <td>&nbsp;</td>
      <td>
        <h4>Escriba la contraseña:</h4>
        <mat-form-field class="form-field">
          <mat-label>Contraseña</mat-label>
          <input matInput type="password" [(ngModel)]="password" required>
          <mat-error *ngIf="!password">Contraseña requerida</mat-error>
          <button mat-button *ngIf="password" matSuffix mat-icon-button aria-label="Clear" (click)="password=''">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </td>
    </tr>
    <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
    <tr>
      <td>
        <h4>Escriba el puerto:</h4>
        <mat-form-field class="form-field">
          <mat-label>Puerto</mat-label>
          <input matInput type="text" [(ngModel)]="puerto" required>
          <mat-error *ngIf="!puerto">Puerto requerido</mat-error>
          <button mat-button *ngIf="puerto" matSuffix mat-icon-button aria-label="Clear" (click)="puerto=''">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </td>
      <td>&nbsp;</td>
      <td>
        <div class="button-row">
          <button mat-raised-button class="mr-2" (click)="testConexion()">Probar conexión</button>
          <button mat-raised-button color="primary" [disabled]="!isconexion" (click)="createConexion()">Guardar datos</button>
        </div>
      </td>
    </tr>
  </table>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cerrar</button>
</mat-dialog-actions>
