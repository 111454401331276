import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import Swal from 'sweetalert2';

import { AnalyticsService } from '../analytics.service';

@Component({
  selector: 'app-cargar',
  templateUrl: './cargar.component.html',
  styleUrls: ['./cargar.component.scss']
})
export class CargarComponent implements OnInit {

  firstFormGroup: FormGroup;
  conexionList = [];
  tablasList = [];
  camposTablaList = [];

  configTablas = {
    displayKey: 'description',
    search: true,
    height: '200px',
    placeholder:'Selecciona tabla',
    noResultsFound: 'Sin resultados',
    searchPlaceholder:'Buscar...',
    clearOnSelection: false
  }

  configCamposTablas = {
    displayKey: 'description',
    search: true,
    height: '200px',
    moreText: 'más',
    placeholder:'Selecciona campos',
    noResultsFound: 'Sin resultados',
    searchPlaceholder:'Buscar...',
    clearOnSelection: false
  }

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: FormBuilder,
    private analyticsService: AnalyticsService,
  ) { }

  ngOnInit(): void {
    this.readConexion();
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl1: ['', Validators.required],
      firstCtrl2: ['', Validators.required]
    });
  }

  /////////////Busca conexiones
  readConexion(){
    this.analyticsService.readConexion()
      .subscribe( (data) => {
        this.conexionList = Object.keys(data).map( (i) => {
          return {
            value: data[i][0], 
            viewValue: data[i][1]
          }
        });
      },
      (error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error al cargar, intente más tarde.',
        });
      }
    );
  }

  ///////////////////////Busca tablas
  readTablas(){
    Swal.fire({
      title: 'Cargando tablas ...',
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      }
    });
    let conexion = parseInt(this.firstFormGroup.value.firstCtrl1.value);
    this.analyticsService.readTablas(conexion)
      .subscribe( (data) => {
        this.tablasList = Object.keys(data).map( (i) => {
          return {
            value: data[i], 
            description: data[i]
          }
        });
        Swal.close();
      },
      (error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error al cargar, intente más tarde.',
        });
      }
    );
  }

  ///////////////////////Busca campos de tabla
  readCamposTabla(){
    Swal.fire({
      title: 'Cargando campos ...',
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      }
    });
    let conexion = parseInt(this.firstFormGroup.value.firstCtrl1.value);
    let tabla = this.firstFormGroup.value.firstCtrl3.value;
    this.analyticsService.readCamposTabla(conexion, tabla)
      .subscribe( (data) => {
        this.camposTablaList = Object.keys(data).map( (i) => {
          return {
            value: data[i], 
            description: data[i]
          }
        });
        this.camposTablaList = [{value: 'Todos', description: 'Todos'}, ...this.camposTablaList]
        Swal.close();
      },
      (error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error al cargar, intente más tarde.',
        });
      }
    );
  }

  selectOption(event: any){
    if (event == 1) {
      this.firstFormGroup.removeControl('firstCtrl5');
      this.firstFormGroup.addControl('firstCtrl3', new FormControl('', Validators.required));
      this.firstFormGroup.addControl('firstCtrl4', new FormControl('', Validators.required));
      this.readTablas();
    } else {
      this.firstFormGroup.removeControl('firstCtrl3');
      this.firstFormGroup.removeControl('firstCtrl4');
      this.firstFormGroup.addControl('firstCtrl5', new FormControl('', Validators.required));
    }
  }

  closeCarga(){
    let conexion = this.firstFormGroup.value.firstCtrl1;
    let opcion = parseInt(this.firstFormGroup.value.firstCtrl2);
    let body = null;
    if (opcion == 1) {
      let tabla = this.firstFormGroup.value.firstCtrl3;
      let campos = this.firstFormGroup.value.firstCtrl4;
      body = {
        conexion: conexion,
        opcion: opcion,
        tabla: tabla,
        campos: campos,
        consulta: null
      };
    } else {
      let consulta = this.firstFormGroup.value.firstCtrl5;
      body = {
        conexion: conexion,
        opcion: opcion,
        tabla: null,
        campos: null,
        consulta: consulta
      };
    }
    this.dialogRef.close(body);
  }

}
