import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from "@env/environment";

@Injectable({
  providedIn: 'root'
})
export class RegistroService {

  env = environment;
  constructor(
    private http: HttpClient
  ) { }

  ///////////////////////Busca registros
  readRegistro(body: any){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });
    
    return this.http.post(`${ this.env.api }/registro/read`, body, { headers });  
  }

  
  /**
   * @description Crea registro
   * @param body Informacion de registros
   * @returns Estatus de respuesta
   * @deprecated En proceso de cambio por createRegistroJson
   */
  createRegistro(body: any){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.post(`${ this.env.api }/registro`, body, { headers });
  }

  ///////////////////////Crea registro
  deleteRegistro(registro: any, codificacion: number){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.delete(`${ this.env.api }/registro/${codificacion}/${registro}`, { headers });
  }

  ///////////////////////Edita registro
  updateRegistro(body: any){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });
    return this.http.put(`${ this.env.api }/registro`, body, { headers });
  }

  ///////////////////////Busca registros con filtro
  readRegistroFiltro(body: any){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.post(`${ this.env.api }/registro/filtro`, body, { headers });
  }

  ///////////////////////Busca registros con filtro
  readRegistroCodigo(codificacion: number){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.get(`${ this.env.api }/registro/${codificacion}/codigo`, { headers });
  }

  ///////////////////////Busca logs
  readLogs(codificacion: number, procod_id: number){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.get(`${ this.env.api }/logs/${codificacion}/${procod_id}`, { headers });
  }


  /**
   * @description Insert de registro nuevo
   * @param body Información de registro
   * @returns Estatus de respuesta
   */
  async createRegistroJson(body: any){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.post(`${ this.env.api }/registro`, body, { headers }).toPromise();
  }

  /**
   * @description Valida códigos
   */
  validaCodigos(body: any){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.post(`${ this.env.api }/registro/valida`, body, { headers });
  }


}
