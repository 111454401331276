import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from "@env/environment";

@Injectable({
  providedIn: 'root'
})
export class ImportarService {

  env = environment;
  constructor(
    private http: HttpClient
  ) { }

  ///////////////////////Busca importaciones
  readImportacion(proyecto: Number){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.get(`${ this.env.api }/importar/${proyecto}`, { headers });
  }

  ///////////////////////Busca conexiones
  readConexion(){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.get(`${ this.env.api }/conexion`, { headers });
  }

  ///////////////////////Busca codificacion
  readCod(proyecto: number){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.get(`${ this.env.api }/codificacion/${proyecto}`, { headers });
  }

  ///////////////////////Busca campos de pantalla
  readCamposPantalla(codificacion: Number){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.get(`${ this.env.api }/importar/campos/pantalla/${codificacion}`, { headers });
  }

  ///////////////////////Busca tablas de DB
  readTablas(conexion: Number){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.get(`${ this.env.api }/importar/tablas/${conexion}`, { headers });
  }

  ///////////////////////Busca campos de tabla de DB 
  readCamposTabla(conexion: number, tabla: String){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.get(`${ this.env.api }/importar/tablas/${conexion}/${tabla}`, { headers });
  }


  ///////////////////////Previsualizacion de datos DB
  readDataPreview(body: any){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.post(`${ this.env.api }/importar/tablas/preview`, body, { headers });
  }


  ///////////////////////Crea importacion
  createImportar(body: any){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.post(`${ this.env.api }/importar`, body, { headers });
  }

  ///////////////////////Inicia importacion
  initImportar(body: any){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });
  
    return this.http.post(`${ this.env.api }/importar/iniciar`, body, { headers });  
  }

  ///////////////////////Elimina importacion
  deleteImportacion(importacion: Number){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });
  
    return this.http.delete(`${ this.env.api }/importar/pantalla/${importacion}`, { headers });  
  }

  ///////////////////////Actualiza importacion
  updateImportar(body: any){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.put(`${ this.env.api }/importar`, body, { headers });
  }

}
