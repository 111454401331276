import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "@env/environment";

@Injectable({
  providedIn: 'root'
})
export class CodigoService {

  env = environment;
  constructor(
    private http: HttpClient
  ) { }


  ///////////////////////Busca grupo
  readGrupo(id: number){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.get(`${ this.env.api }/grupo/${id}`, { headers });
  }

  ///////////////////////Crea grupo
  createGrupo(name: string, id: number){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });
    
    let body = {
      nombre: name,
      id: id
    };

    return this.http.post(`${ this.env.api }/grupo`, body, { headers });
  }

  ///////////////////////Edita grupo
  updateGrupo(name: string, id: number){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });
    
    let body = {
      nombre: name,
      id: id
    };

    return this.http.put(`${ this.env.api }/grupo`, body, { headers });
  }

  ///////////////////////Elimina grupo
  deleteGrupo(id: number){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.delete(`${ this.env.api }/grupo/${id}`, { headers });
  }

  ///////////////////////Busca codigo
  readCodigo(id: number){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.get(`${ this.env.api }/codigo/${id}`, { headers });
  }

  ///////////////////////Crea codigo
  createCodigo(data: any, grupo: number){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });
    
    let body = {
      data: data,
      grupo: grupo
    };

    return this.http.post(`${ this.env.api }/codigo`, body, { headers });
  }

  ///////////////////////Edita codigo
  updateCodigo(id: number, codigo:number, nombre: string){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });
    
    let body = {
      id: id,
      codigo: codigo,
      nombre: nombre
    };

    return this.http.put(`${ this.env.api }/codigo`, body, { headers });
  }

  ///////////////////////Elimina codigo
  deleteCodigo(id: string){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.delete(`${ this.env.api }/codigo/${id}`, { headers });
  }


  ////////////////////Descarga codigos
  downloadCodigo(proyecto: number){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.get(`${ this.env.api }/codigo/descarga/${proyecto}`, { headers });
  }


}
