import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FormControl, FormGroup} from '@angular/forms';

import Swal from 'sweetalert2';
import { RegistroService } from '../registro.service';

@Component({
  selector: 'app-registro-filtro',
  templateUrl: './registro-filtro.component.html',
  styleUrls: ['./registro-filtro.component.scss']
})
export class RegistroFiltroComponent implements OnInit {

  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast: any) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  panelOpenState = false;

  estadoList = [
    {value: 0, viewValue: 'Sin usar'},
    {value: 1, viewValue: 'Finalizado'},
    {value: 2, viewValue: 'Pendiente'},
    {value: 3, viewValue: 'No es posible completar'}
  ];
  selectedEstado = null;

  estadoForm = new FormControl();
  fechaIniForm = new FormControl();
  fechaFinForm = new FormControl();
  camposForm = new FormControl();
  multipleForm: FormGroup = null;

  camposList = null;
  selectList = null;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private registroService: RegistroService
  ) { }

  ngOnInit(): void {
    this.camposList = [this.data.campos[1], this.data.campos[2], ...this.data.campos.slice(9, this.data.campos.length)];
  }

  /**Seleccion de campo */
  camposSelect(){
    let group = {};
    this.selectList = this.camposForm.value;
    this.camposForm.value.forEach(element => {
      group[element] = new FormControl('');
    });
    this.multipleForm = new FormGroup(group);
  }

  //////////Busca registros
  readRegistro(){
    Swal.fire({
      title: 'Cargando registros...',
      allowOutsideClick: false,
      onOpen: () =>{
        Swal.showLoading();
      }
    });
    let body = {
      codificacion: this.data.codificacion,
      estado: this.estadoForm.value,
      fechaini: this.fechaIniForm.value ? (new Date(this.fechaIniForm.value)).toDateString() : null,
      fechafin: this.fechaFinForm.value ? (new Date(this.fechaFinForm.value)).toDateString() : null,
      custom: this.multipleForm ? this.multipleForm.value : null
    };
    this.registroService.readRegistroFiltro(body)
      .subscribe( (data) => {
        Swal.close();
        this.dialogRef.close(data);
      },
      (error) => {
        Swal.fire(
          'Error',
          'Ocurrio un error al filtrar los registros, intenta más tarde.',
          'error'
        );
      });
  }

}
