import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from "@env/environment";

@Injectable({
  providedIn: 'root'
})
export class CodificacionService {

  env = environment;
  constructor(
    private http: HttpClient
  ) { }

  ///////////////////////Busca pantalla
  readVista(id: number){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.get(`${ this.env.api }/vista/${id}`, { headers });
  }

  ///////////////////////Busca validacion
  getValide(data: any){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.post(`${ this.env.api }/valida`, data, { headers });
  }

  ///////////////////////Busca registro a codificar
  readRegistro(body: any){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.post(`${ this.env.api }/vista/registro`, body, { headers });
  }

  
  updateRegistro(body: any){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.put(`${ this.env.api }/vista/registro`, body, { headers });
  }

}
