<form
  (ngSubmit)="login(f)"
  #f="ngForm"
  class="form-signin">
  
  <!-- <img class="mb-4" src="" alt="" width="72" height="72"> -->
  <h1 class="font-weight-light text-primary mb-5" style="font-size: 60px;">Procod</h1>
  <h1 class="h3 mb-3 font-weight-normal">Iniciar sesión</h1>

  <span *ngIf="f.submitted && f.controls['email'].errors"
    class="text-danger">El email es obligatorio</span>
  <input 
    type="email" 
    class="form-control" 
    placeholder="Email" 
    name="email"
    [(ngModel)]="usuario.email"
    required 
    email>

  <span *ngIf="f.submitted && f.controls['password'].errors"
    class="text-danger">El password es obligatorio</span>
  <input 
    type="password" 
    class="form-control" 
    placeholder="Password"
    name="password"
    [(ngModel)]="usuario.password"
    required>

  <div class="checkbox mb-3">
    <label>
      <input
        name="remember" 
        [(ngModel)]="remember"
        type="checkbox" 
        value="remember-me"> Recordarme
    </label>
  </div>

  <button class="btn btn-lg btn-primary btn-block" type="submit">Iniciar sesión</button>
  <p class="mt-5 mb-3 text-muted">&copy; Procod</p>

</form>
