<h2 mat-dialog-title>Asignación de proyectos</h2>
<mat-dialog-content class="mat-typography">
    <mat-horizontal-stepper [linear]="true" #stepper>
        <mat-step [stepControl]="firstFormGroup">
        <form [formGroup]="firstFormGroup">
            <ng-template matStepLabel>Codificadores</ng-template>
            <p>Selecciona los usuarios a asignar</p>
            <div class="bg-light" style="height: 250px; overflow-y: auto;">
                <mat-selection-list formControlName="firstCtrl" required>
                    <mat-list-option *ngFor="let codificador of data.codificadores" [value]="codificador.id">
                    {{codificador.nombre}}
                    </mat-list-option>
                </mat-selection-list>
            </div>
            <div>
                <button mat-button matStepperNext>Siguiente</button>
            </div>
        </form>
        </mat-step>
        <mat-step [stepControl]="secondFormGroup" label="Proyectos">
        <form [formGroup]="secondFormGroup">
            <p>Selecciona proyecto a asignar</p>
            <div class="bg-light" style="height: 250px; overflow-y: auto;">
                <mat-selection-list formControlName="secondCtrl" [multiple]="false" required>
                    <mat-list-option *ngFor="let proyecto of proyectoList" [value]="proyecto.id" (click)="readCod(proyecto.id)">
                    {{proyecto.name}}
                    </mat-list-option>
                </mat-selection-list>
            </div>
            <div>
                <button mat-button matStepperPrevious>Regresar</button>
                <button mat-button matStepperNext>Siguiente</button>
            </div>
        </form>
        </mat-step>
        <mat-step [stepControl]="thirdFormGroup" label="Pantallas">
            <form [formGroup]="thirdFormGroup">
                <p>Selecciona pantallas a asignar</p>
                <div class="bg-light" style="height: 250px; overflow-y: auto;">
                    <mat-selection-list formControlName="thirdCtrl" required>
                        <mat-list-option *ngFor="let codificacion of codificacionList" [value]="codificacion.id">
                        {{codificacion.name}}
                        </mat-list-option>
                    </mat-selection-list>
                </div>
                <div>
                    <button mat-button matStepperPrevious>Regresar</button>
                    <button 
                        [disabled]="firstFormGroup.status!='VALID'||secondFormGroup.status!='VALID'||thirdFormGroup.status!='VALID'"
                        mat-button 
                        (click)="createAsignacion()">
                        Guardar
                    </button>
                </div>
            </form>
        </mat-step>

    </mat-horizontal-stepper>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cerrar</button>
</mat-dialog-actions>