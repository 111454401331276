<h1 mat-dialog-title>Carga de datos</h1>
<mat-dialog-content class="mat-typography bg-light" style="height: calc(100% - 80px);max-height: unset">
    <h3>Asistente para carga de base de datos.</h3>
    <form [formGroup]="firstFormGroup">
        <ng-template matStepLabel>Conexión</ng-template>
        <h4>Selecciona conexión.</h4>
        <mat-form-field appearance="fill">
            <mat-label>Conexión</mat-label>
            <mat-select formControlName="firstCtrl1" required>
                <mat-option *ngFor="let conexion of conexionList" [value]="conexion">
                    {{conexion.viewValue}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <br>
        <div *ngIf="firstFormGroup.controls.firstCtrl1.status=='VALID'">
            <label>Seleccione una opción: </label>
            <mat-radio-group 
                formControlName="firstCtrl2" 
                color="primary" 
                (ngModelChange)="selectOption($event)">
                <mat-radio-button value="1">Agregar tabla</mat-radio-button>
                <mat-radio-button value="2">Crear consulta SQL</mat-radio-button>
            </mat-radio-group>
        </div>
        <br>
        <div
            *ngIf="firstFormGroup.controls.firstCtrl2.value=='1'" 
            style="overflow: auto; height: 200px;">
            <h4>Información de tabla.</h4>
            <span>Tabla a cargar:</span>
            <ngx-select-dropdown 
                [multiple]="false" 
                [config]="configTablas"
                [options]="tablasList"
                (ngModelChange)="readCamposTabla()"
                formControlName="firstCtrl3">
            </ngx-select-dropdown>
            <br>
            <span>Campos a cargar:</span>
            <ngx-select-dropdown 
                [multiple]="true" 
                [config]="configCamposTablas"
                [options]="camposTablaList"
                formControlName="firstCtrl4">
            </ngx-select-dropdown>
        </div>
        <br>
        <div *ngIf="firstFormGroup.controls.firstCtrl2.value=='2'">
            <h4>Consulta SQL</h4>
            <mat-form-field appearance="fill" style="width: 100%;">
                <mat-label>SQL</mat-label>
                <textarea 
                    matInput 
                    formControlName="firstCtrl5"
                    placeholder="Ej. SELECT campo1, campo2, ... FROM tabla"></textarea>
            </mat-form-field>
        </div>
        <br>
        
        <div class="bottom">
            <button 
                mat-stroked-button
                [disabled]="firstFormGroup.status=='INVALID'"
                color="primary" 
                (click)="closeCarga()">Cargar datos</button>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cerrar</button>
</mat-dialog-actions>