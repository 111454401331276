import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import Swal from 'sweetalert2';

import { AuthService } from '../../../service/auth.service';
import { DetalleProyectoService } from './detalle-proyecto.service';

import { CodigoComponent } from '../codigo/codigo.component';
import { PantallaComponent } from '../pantalla/pantalla.component';
import { VistaComponent } from '../vista/vista.component';
import { RegistroComponent } from '../registro/registro.component';
import { AvanzadasComponent } from '../avanzadas/avanzadas.component';
import { CodificacionComponent } from '../codificacion/codificacion.component';
import { DescargaComponent } from '../descarga/descarga.component';
import { AvanceComponent } from '../avance/avance.component';
import { RevisionComponent } from '../revision/revision.component';
import { ImportarComponent } from '../importar/importar.component';

@Component({
  selector: 'app-detalle-proyecto',
  templateUrl: './detalle-proyecto.component.html',
  styleUrls: ['./detalle-proyecto.component.scss']
})
export class DetalleProyectoComponent implements OnInit {

  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast: any) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  permissions = null;

  proyecto = null;
  idProyecto = null;
  identificador = null;
  control = null;
  codificacion = null;
  idCodificacion = null;
  descripcion = null;
  codificacionList = null;

  iscod = false;
  ispantalla = false;
  
  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private auth: AuthService,
    public dialog: MatDialog,
    private detalleService: DetalleProyectoService,
  ) { 
    this.activeRoute.params.subscribe( parametro => {
      this.idProyecto = parametro.proyecto;
    });
  }

  async ngOnInit() {
    await this.getPermissions();
    this.readCod();
  }

  ///////////////////Busca permisos
  async getPermissions(){
    this.auth.getPermissions()
      .subscribe( (data) => {
        this.permissions = data;
      },
      (error) => {
        this.router.navigateByUrl('/login');
      }
    );
  }

  ///////////////////////Busca pantallas
  readCod(){
    this.detalleService.readCod(this.idProyecto)
      .subscribe( (data) => {
        this.codificacionList = Object.keys(data['pantallas']).map( (row) => {
          return {
            id: data['pantallas'][row][0], 
            name: data['pantallas'][row][1],
            pantalla: data['pantallas'][row][2],
            descripcion: data['pantallas'][row][3]
          }
        });
        this.proyecto = data['proyecto'][0][0];
        this.identificador = data['proyecto'][0][1];
        this.control = data['proyecto'][0][2];
      },
      (error) => {
        console.log(error)
      }
    );
  }

  ///////////////////Seleccion de pantalla
  selectCod(id: number, name: string, descripcion: string, pantalla: boolean){
    this.idCodificacion = id;
    this.codificacion = name;
    this.descripcion = descripcion;
    this.ispantalla = pantalla;
    this.iscod = true;
  }

  /////////////////////Regresa a proyectos
  backProyecto(){
    this.router.navigateByUrl('proyectos');
  }

  //////////////////////Edita proyecto
  updateProyecto(){
    let campo = 1;
    Swal.mixin({
      input: 'text',
      confirmButtonText: 'Siguiente &rarr;',
      allowEscapeKey: true,
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      preConfirm: (values) =>{
        if (!values && campo < 3) {
          Swal.showValidationMessage(
            "Campo requerido"
          );
        }else{
          campo += 1;
        }
      }
    }).queue([
      {
        title: 'Edita proyecto',
        text: 'Escribe el nuevo nombre del proyecto',
        inputValue: `${this.proyecto}`
      },
      {
        title: 'Identificador',
        text: 'Escribe el nuevo nombre del campo que servira de identificador para tus tablas de codificación',
        inputValue: `${this.identificador}`
      },
      {
        title: 'Variable de control (Opcional)',
        html: 'Escribe el nuevo nombre del campo que servira como variable de control (Variable de corte, periodo, etc.).',
        inputValue: `${this.control}`
      }
    ]).then((result: any) => {
      if (result.value) {
        let body = {
          proyecto: this.idProyecto,
          nombre: result.value[0],
          identificador: result.value[1].toLowerCase(),
          control: result.value[2].toLowerCase()
        };
        this.detalleService.updateProyecto(body)
          .subscribe( (data) => {
            this.Toast.fire({
              icon: 'success',
              title: 'Proyecto editado correctamente'
            });
            this.readCod();
          },
          (error) => {
            Swal.fire(
              `¡Se generó un error al editar el proyecto ${result.value[0]}, revise que el nombre del proyecto no este duplicado, o intente más tarde!`,
              '',
              "info"
            );
          }
        );
      }
    })
  }

  //////////////////////Elimina proyecto
  deleteProyecto(){
    Swal.fire({
      title: `¿Estas seguro de eliminar el proyecto ${this.proyecto}?`,
      text: 'Se eliminara todo lo relacionado con el proyecto',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, Eliminar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.detalleService.deleteProyecto(this.idProyecto)
          .subscribe( (data) => {
            this.Toast.fire({
              icon: 'success',
              title: 'Proyecto eliminado correctamente'
            });
            this.backProyecto();
          },
          (error) => {
            Swal.fire(
              'Error',
              'Ocurrio un error al eliminar el proyecto, intenta más tarde.',
              'error'
            );
          });
      }
    })
  }


  /////////////////////////////// Crea codificacion
  createCod(){
    Swal.mixin({
      input: 'text',
      confirmButtonText: 'Siguiente &rarr;',
      showCancelButton: true,
      allowEscapeKey: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#d33',
      preConfirm: (values) =>{
        if (!values) {
          Swal.showValidationMessage(
            "Campo requerido"
          )
        }
      }
    }).queue([
      {
        title: 'Nueva codificación',
        text: 'Escribe el nombre de la codificación nueva'
      },
      {
        title: 'Descripción de codificación',
        text: 'Escribe la descripción de la codificación'
      }
    ]).then((result: any) => {
      if (result.value) {
        const name = result.value[0];
        const descripcion = result.value[1];
        Swal.fire({
          title: `¡Todo listo para crear la codificación!`,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '¡Crear!',
          cancelButtonText: 'Cancelar'
        }).then((result: any) => {
          this.detalleService.createCod(name, descripcion, this.idProyecto)
            .subscribe( (data) => {
              this.Toast.fire({
                icon: 'success',
                title: 'Codificación creada correctamente'
              });
              this.readCod();
            },
            (error) => {
              Swal.fire(
                `¡Se generó un error al crear la codificación ${name}, revise que el nombre de la codificación no este duplicado, o intente más tarde!`,
                '',
                'info'
              );
            }
          );
        });
      }
    })
  }

  /////////////////////////////// edita codificacion
  updateCod(){
    Swal.mixin({
      input: 'text',
      confirmButtonText: 'Siguiente &rarr;',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      allowEscapeKey: true,
      preConfirm: (values) =>{
        if (!values) {
          Swal.showValidationMessage(
            "Campo requerido"
          )
        }
      }
    }).queue([
      {
        title: 'Edita codificación',
        text: 'Escribe el nombre nuevo de la codificación',
        inputValue: `${this.codificacion}`
      },
      {
        title: 'Edita descripción de codificación',
        text: 'Escribe la nueva descripción de la codificación',
        inputValue: `${this.descripcion}`
      }
    ]).then((result: any) => {
      if (result.value) {
        const newname = result.value[0];
        const descripcion = result.value[1];
        Swal.fire({
          title: `¡Todo listo para editar la codificación!`,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: '!Editar!',
          cancelButtonColor: '#d33',
          cancelButtonText: 'Cancelar'
        }).then((result: any) => {
          this.detalleService.updateCod(newname, descripcion, this.idCodificacion)
            .subscribe( (data) => {
              this.Toast.fire({
                icon: 'success',
                title: 'Codificación editada correctamente'
              });
              this.readCod();
              this.selectCod(this.idCodificacion, newname, descripcion, this.ispantalla);
            },
            (error) => {
              Swal.fire(
                `¡Se generó un error al editar la codificación '${newname}', revise que el nombre de la codificación no este duplicado, o intente más tarde!`,
                '',
                "info"
              );
            }
          );
        });
      }
    })
  }

  /////////////////////////////// Elimina codificacion
  deleteCod(){
    Swal.fire({
      title: `¿Estas seguro de eliminar la codificación ${this.descripcion}?`,
      text: 'Se eliminara todo lo relacionado con la codificación',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, Eliminar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.detalleService.deleteCod(this.idCodificacion)
          .subscribe( (data) => {
            this.Toast.fire({
              icon: 'success',
              title: 'Codificación eliminada correctamente'
            });
            this.readCod();
          },
          (error) => {
            Swal.fire(
              'Error',
              'Ocurrio un error al eliminar la codificación, intenta más tarde.',
              'error'
            );
          });
      }
    })
  }

  /////////////Lleva a vista de codigos
  openDialogCod() {
    const dialogRef = this.dialog.open(CodigoComponent, {
      width: '100%',
      disableClose: true,
      data: { proyecto: this.idProyecto, proyectoName: this.proyecto }
    });
  }

  /////////////Lleva a vista de crear pantalla
  openDialogPantalla() {
    const dialogRef = this.dialog.open(PantallaComponent, {
      width: '100%',
      disableClose: true,
      data: { proyecto: this.idProyecto, id: this.idCodificacion, pantalla: this.ispantalla }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.readCod();
      this.selectCod(this.idCodificacion, this.codificacion, this.descripcion, result);
    });
  }

  /////////////Lleva a vista de vista previa de pantalla
  openDialogVista() {
    const dialogRef = this.dialog.open(VistaComponent, {
      width: '100%',
      height: '98%',
      disableClose: true,
      data: { id: this.idCodificacion }
    });

  }

  /////////////Lleva a vista de registros
  openDialogRegistro() {
    const dialogRef = this.dialog.open(RegistroComponent, {
      width: '100%',
      disableClose: true,
      data: { id: this.idCodificacion, codificacion: this.codificacion }
    });

  }

  /////////////Lleva a vista de opciones avanzadas
  openDialogAvanzadas() {
    const dialogRef = this.dialog.open(AvanzadasComponent, {
      disableClose: true,
      data: { control: this.control, identificador: this.identificador, proyecto: this.idProyecto, codificacion: this.idCodificacion }
    });

  }

  /////////////Lleva a vista de iniciar codificacion
  openDialogIniciar() {
    const dialogRef = this.dialog.open(CodificacionComponent, {
      width: '100%',
      height: '98%',
      disableClose: true,
      data: { id: this.idCodificacion, control: this.control, descripcion: this.descripcion }
    });

  }

  /////////////Lleva a vista de iniciar codificacion
  openDialogRevision() {
    const dialogRef = this.dialog.open(RevisionComponent, {
      width: '100%',
      height: '98%',
      disableClose: true,
      data: { id: this.idCodificacion, control: this.control }
    });

  }

  /////////////Lleva a vista de descarga de proyecto
  openDialogDescarga() {
    const dialogRef = this.dialog.open(DescargaComponent, {
      width: '50%',
      disableClose: true,
      data: {
        nameProyecto: this.proyecto,
        proyecto: this.idProyecto, 
        identificador: this.identificador, 
        control: this.control, 
        codificacionList: this.codificacionList 
      }
    });

  }

  /////////////Lleva a vista de descarga de proyecto
  openDialogAvance(tipo: string) {
    const dialogRef = this.dialog.open(AvanceComponent, {
      width: '100%',
      disableClose: true,
      data: {
        tipo: tipo, 
        proyectoName: this.proyecto, 
        proyecto: this.idProyecto, 
        codificacionName: this.codificacion, 
        codificacion: this.idCodificacion, 
        control: this.control
      }
    });

  }

  /**
   * @description Deshabilita proyecto
   */
  async disableProyecto(){
    console.log(this.idProyecto)
    Swal.fire({
      title: `¿Estas seguro de deshabilitar el proyecto ${this.proyecto}?`,
      text: 'El proyecto no se eliminará, sólo quedará oculto.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, deshabilitar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.detalleService.disableProyecto(this.idProyecto)
          .subscribe( (data) => {
            this.Toast.fire({
              icon: 'success',
              title: 'Proyecto deshabilitado correctamente'
            });
            this.backProyecto();
          },
          (error) => {
            Swal.fire(
              'Error',
              'Ocurrio un error al deshabilitar el proyecto, intenta más tarde.',
              'error'
            );
          });
      }
    })
  }

  openDialogImport(){
    const dialogRef = this.dialog.open(ImportarComponent, {
      width: '100%',
      height: '98%',
      disableClose: true,
      data: {
        idProyecto: this.idProyecto,
        control: this.control
      }
    });
  }


}
