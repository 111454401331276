<h2 mat-dialog-title>Añadir registro</h2>
<mat-dialog-content class="mat-typography">
    <div class=" m-3">
        <form [formGroup]="singleForm">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>Campo</th>
                        <th>Valor</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let campo of data.campos; let i=index">
                        <td *ngIf="i==1">{{ campo }}</td>
                        <td *ngIf="i==1"><input type="text" class="form-control" [formControlName]="campo" ></td>

                        <td *ngIf="i==2">{{ campo }}</td>
                        <td *ngIf="i==2"><input type="text" class="form-control" [formControlName]="campo" ></td>

                        <td *ngIf="i>=9 && campoGrupo">{{ campo }}</td>
                        <td *ngIf="i>=9 && campoGrupo">
                            <input
                                *ngIf="!campoGrupo[campo]"
                                type="text" 
                                class="form-control" 
                                [formControlName]="campo">
                            <ngx-select-dropdown 
                                *ngIf="campoGrupo[campo]"
                                style="float: left; width: 500px;"
                                [multiple]="false"
                                [formControlName]="campo"
                                [config]="config"
                                [options]="codigos[campoGrupo[campo]]"
                            >
                            </ngx-select-dropdown>
                            <br><br>
                            <p 
                                *ngIf="campoGrupo[campo] &&
                                singleForm.value[campo].value">
                                Seleccionado: <b>{{ singleForm.value[campo]['description'] }}</b>
                            </p>
                        </td>
                        <td *ngIf="i>=9 && campoGrupo">
                            <button
                                *ngIf="campoGrupo[campo]"
                                mat-icon-button
                                color="warn"
                                matTooltip="Eliminar código"
                                matTooltipPosition="above"
                                style="float: left;"
                                (click)="deleteCode(campo)">
                                <mat-icon>clear</mat-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cerrar</button>
  <button mat-button (click)="uploadRegistro()">Añadir</button>
</mat-dialog-actions>
