<mat-toolbar color="primary" style="z-index: 3; position: absolute;">
    <mat-toolbar-row>
        <span>ProCod</span>
        <span class="example-spacer"></span>
        <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>account_circle</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <p  mat-menu-item>Usuario: {{ user }}</p>
        </mat-menu>
        <button mat-icon-button class="example-icon" (click)="logout()">
        <mat-icon>logout</mat-icon>
        </button>
    </mat-toolbar-row> 
</mat-toolbar>

<mat-sidenav-container class="example-container bg-light">
    <mat-sidenav mode="side" style="width: 200px;" opened>
        <mat-nav-list>
            <ng-container *ngFor="let page of pages" >
            <a 
                mat-list-item
                routerLinkActive="active"
                routerLink="{{ page.path }}"
                *ngIf="permissions[page.auth]"
            ><mat-icon>arrow_right</mat-icon> {{ page.label }}</a>
            </ng-container>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>