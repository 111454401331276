import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { MaterialModule } from '../../material.module';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { UsuarioRoutingModule } from './usuario-routing.module';
import { UsuarioComponent } from './usuario.component';
import { UsuarioNuevoComponent } from './usuario-nuevo/usuario-nuevo.component';
import { AsignaComponent } from './asigna/asigna.component';
import { AsignaVerComponent } from './asigna-ver/asigna-ver.component';


@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        UsuarioRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        SelectDropDownModule,
        BrowserModule
    ],
    exports: [],
    declarations: [
        UsuarioComponent,
        UsuarioNuevoComponent,
        AsignaComponent,
        AsignaVerComponent
    ],
    providers: [],
})
export class UsuarioModule { }
