import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { UsuarioModel } from '../models/usuario.model';
import { AuthService } from '../service/auth.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  usuario: UsuarioModel = new UsuarioModel();
  remember = false;
  constructor(
    private auth: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    if (localStorage.getItem('email')) {
      this.usuario.email = localStorage.getItem('email');
      this.remember = true;
    }
  }

  login( form: NgForm){
    if(form.invalid){
      return;
    }

    Swal.fire({
      icon: 'info',
      title: 'Espere ...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });

    this.auth.login(this.usuario)
      .subscribe((data) => {
        Swal.close();
        if (this.remember) {
          localStorage.setItem('email', this.usuario.email);
        }
        if (data['level']==5) {
          this.router.navigateByUrl('/analytics');
        } else {
          this.router.navigateByUrl('/proyectos'); 
        }
      }, (err) => {

        console.log(err)
        Swal.fire({
          icon: 'error',
          title: 'Error de autenticación',
          text: '¡Intente nuevamente!'
        });

      });
  }

}
