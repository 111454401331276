import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData {
  codigo: boolean;
  vacio: boolean;
}

@Component({
  selector: 'app-valida',
  templateUrl: './valida.component.html',
  styleUrls: ['./valida.component.scss']
})
export class ValidaComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ValidaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
