<div class="m-5">
    <h1><span class="material-icons">electrical_services</span>Conexiones</h1>
    <p>Conexiones a bases de datos externas.</p>
    <button
        mat-icon-button
        class="text-success" 
        matTooltip="Crear conexión" 
        matTooltipPosition="above"
        (click)="openDialogConexion()">
        <mat-icon>add_circle_outline</mat-icon>
    </button>
    <div *ngIf="dataSource" style="height: 500px; overflow-y: auto;">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        
            <ng-container matColumnDef="alias">
                <th mat-header-cell *matHeaderCellDef> Alias </th>
                <td mat-cell *matCellDef="let element"> {{element.alias}} </td>
            </ng-container>

            <ng-container matColumnDef="menu">
                <th style="width: 10px;" mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element"> 
                    <button 
                        mat-icon-button
                        matTooltip="Eliminar conexión"
                        matTooltipPosition="left"
                        color="warn"
                        (click)="deleteConexion(element)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>
            </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;">
            </tr>
        </table>
    </div>
</div>
