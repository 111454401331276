import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CodificacionFiltroComponent } from '../../codificacion/codificacion-filtro/codificacion-filtro.component';

@Component({
  selector: 'app-revision-filtro',
  templateUrl: './revision-filtro.component.html',
  styleUrls: ['./revision-filtro.component.scss']
})
export class RevisionFiltroComponent implements OnInit {

  controlValue = null;
  filtroValue = null;
  filtroEstado = null;

  estadoList = [
    { value: 1, name: 'Completado' },
    { value: 2, name: 'Pendiente' },
    { value: 3, name: 'No es posible completar' }
  ];

  constructor(
    public dialogRef: MatDialogRef<CodificacionFiltroComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit(): void {
    this.controlValue = this.data.controlValue;
    this.filtroValue = this.data.filtroValue;
    this.filtroEstado = this.data.filtroEstado;
  }

  filtro(){
    let body = {
      controlValue: this.controlValue,
      filtroValue: this.filtroValue,
      filtroEstado: this.filtroEstado
    };
    this.dialogRef.close(body);
  }

}
