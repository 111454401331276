import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from "@env/environment";


@Injectable({
  providedIn: 'root'
})
export class AvanzadasService {

  env = environment;
  constructor(
    private http: HttpClient
  ) { }

  ///////////////////////Edita opciones avanzadas
  updateAvanzadas(body: any){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.put(`${ this.env.api }/avanzadas`, body, { headers });
  }

}
