import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Pages } from '../pages.service';

import { ConexionComponent } from './conexion.component';

const routes: Routes = [
    Pages.childRoutes([
        { path: 'conexiones', component: ConexionComponent }
    ])
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: []
})
export class ConexionRoutingModule {}
