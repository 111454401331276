import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-codificacion-filtro',
  templateUrl: './codificacion-filtro.component.html',
  styleUrls: ['./codificacion-filtro.component.scss']
})
export class CodificacionFiltroComponent implements OnInit {

  controlValue = null;
  filtroValue = null;

  constructor(
    public dialogRef: MatDialogRef<CodificacionFiltroComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit(): void {
    this.controlValue = this.data.controlValue;
    this.filtroValue = this.data.filtroValue;
  }

  filtro(){
    let body = {
      controlValue: this.controlValue,
      filtroValue: this.filtroValue
    };
    this.dialogRef.close(body);
  }

}
