import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { MaterialModule } from '../../material.module';
import { ProyectoRoutingModule } from './proyecto-routing.module';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgApexchartsModule } from 'ng-apexcharts';

import { ProyectoComponent } from './proyecto.component';
import { CodigoComponent } from './codigo/codigo.component';
import { PantallaComponent } from './pantalla/pantalla.component';
import { VistaComponent } from './vista/vista.component';
import { ValidaComponent } from './pantalla/valida/valida.component';
import { FiltroComponent } from './pantalla/filtro/filtro.component';
import { RegistroComponent } from './registro/registro.component';
import { DetalleProyectoComponent } from './detalle-proyecto/detalle-proyecto.component';
import { RegistroEditaComponent } from './registro/registro-edita/registro-edita.component';
import { RegistroFiltroComponent } from './registro/registro-filtro/registro-filtro.component';
import { AvanzadasComponent } from './avanzadas/avanzadas.component';
import { CodificacionComponent } from './codificacion/codificacion.component';
import { RevisionComponent } from './revision/revision.component';
import { CodificacionFiltroComponent } from './codificacion/codificacion-filtro/codificacion-filtro.component';
import { DescargaComponent } from './descarga/descarga.component';
import { AvanceComponent } from './avance/avance.component';
import { AvanceFiltroComponent } from './avance/avance-filtro/avance-filtro.component';
import { EventoComponent } from './evento/evento.component';
import { RegistroLogsComponent } from './registro/registro-logs/registro-logs.component';
import { RegistroNuevoComponent } from './registro/registro-nuevo/registro-nuevo.component';
import { RevisionFiltroComponent } from './revision/revision-filtro/revision-filtro.component';
import { ImportarComponent } from './importar/importar.component';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        ProyectoRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        SelectDropDownModule,
        NgSelectModule,
        BrowserModule,
        NgApexchartsModule
    ],
    exports: [],
    declarations: [
        ProyectoComponent,
        CodigoComponent,
        PantallaComponent,
        VistaComponent,
        ValidaComponent,
        FiltroComponent,
        RegistroComponent,
        DetalleProyectoComponent,
        RegistroEditaComponent,
        RegistroFiltroComponent,
        AvanzadasComponent,
        CodificacionComponent,
        RevisionComponent,
        CodificacionFiltroComponent,
        DescargaComponent,
        AvanceComponent,
        AvanceFiltroComponent,
        EventoComponent,
        RegistroLogsComponent,
        RegistroNuevoComponent,
        RevisionFiltroComponent,
        ImportarComponent
    ],
    providers: [],
})
export class ProyectoModule { }
