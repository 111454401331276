import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Pages } from '../pages.service';

import { AnalyticsComponent } from './analytics.component';


const routes: Routes = [
    Pages.childRoutes([
        { path: 'analytics', component: AnalyticsComponent }
    ])
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: []
})
export class AnalyticsRoutingModule {}
