<h2 mat-dialog-title>Filtro de texto</h2>
<mat-dialog-content class="mat-typography">
    <mat-tab-group>

        <mat-tab label="Mis filtros">
            <table mat-table
                #table
                [dataSource]="dataFilter" multiTemplateDataRows
                class="mat-elevation-z8">
                <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
                    <th mat-header-cell *matHeaderCellDef> {{column}} </th>
                    <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
                </ng-container>

                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element;" [attr.colspan]="columnsToDisplay.length">
                    <div class="example-element-detail"
                        [@detailExpand]="element == expandedFiltro ? 'expanded' : 'collapsed'">
                        <div class="example-element-description">
                            <div class="example-button-container">
                                <button 
                                    mat-mini-fab 
                                    matTooltip="Eliminar filtro" 
                                    matTooltipPosition="right"
                                    class="mr-3 ml-5"
                                    color="warn"
                                    (click)="deleteFilter(element)">
                                  <mat-icon>delete</mat-icon>
                                </button>
                            
                                <button 
                                    mat-mini-fab 
                                    matTooltip="Editar códigos" 
                                    matTooltipPosition="right" 
                                    color="primary"
                                    *ngIf="!isEdit"
                                    (click)="openEditFilter(element)">
                                  <mat-icon>edit</mat-icon>
                                </button>

                                <button 
                                    mat-mini-fab 
                                    matTooltip="Guardar filtro" 
                                    matTooltipPosition="right" 
                                    class="bg-success"
                                    *ngIf="isEdit"
                                    (click)="editFilter(element)">
                                  <mat-icon>check</mat-icon>
                                </button>
                            </div>
                            <b>Lista de codigos: </b>
                            <p *ngIf="!isEdit">{{element.Codigos}}</p>
                            <textarea 
                                matInput 
                                *ngIf="isEdit" 
                                class="mt-3 mb-5 border border-success" 
                                [value]="element.Codigos"
                                [(ngModel)]="element.Codigos"
                                placeholder="Ej. 1,2,3,4,5">
                            </textarea>
                        </div>
                    </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
                    class="example-element-row"
                    [class.example-expanded-row]="expandedFiltro === element"
                    (click)="expandedFiltro = expandedFiltro === element ? null : element">
                </tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
            </table>
        </mat-tab>

        <mat-tab label="Crear filtro">
            <mat-vertical-stepper [linear]="true" #stepper>
                <mat-step [stepControl]="firstFormGroup">
                    <form [formGroup]="firstFormGroup">
                        <ng-template matStepLabel>Selecciona la variable de texto a filtrar</ng-template>
                        <mat-form-field appearance="fill">
                            <mat-label>Selecciona variable texto</mat-label>
                            <mat-select formControlName="firstCtrl" required>
                                <mat-option *ngFor="let texto of data.textList" [value]="texto">
                                    {{texto}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div>
                            <button mat-button matStepperNext>Siguiente</button>
                        </div>
                    </form>
                </mat-step>
                <mat-step [stepControl]="secondFormGroup">
                  <form [formGroup]="secondFormGroup">
                    <ng-template matStepLabel>Selecciona el grupo de códigos que se filtrarán por la variable de texto</ng-template>
                    <mat-form-field appearance="fill">
                        <mat-label>Selecciona variable texto</mat-label>
                        <mat-select formControlName="secondCtrl" required>
                            <mat-option *ngFor="let grupo of data.grupos" [value]="[grupo.name, grupo.id]">
                                {{grupo.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div>
                        <button mat-button matStepperPrevious>Regresar</button>
                        <button mat-button matStepperNext>Siguiente</button>
                    </div>
                  </form>
                </mat-step>
                <mat-step [stepControl]="thirdFormGroup">
                    <form [formGroup]="thirdFormGroup">
                        <ng-template matStepLabel>Agrega texto y lista de códigos especificos a filtrar</ng-template>
                        
                        <mat-form-field class="example-full-width">
                        <mat-label>Texto</mat-label>
                            <input matInput formControlName="thirdCtrl" placeholder="Ej. Lobo">
                        </mat-form-field>
                        
                        <mat-form-field class="example-full-width">
                        <mat-label>Códigos</mat-label>
                            <textarea matInput formControlName="fourthCtrl" placeholder="Ej. 1,2,3,4,5"></textarea>
                        </mat-form-field>
                        <div>
                            <button mat-button matStepperPrevious>Regresar</button>
                            <button
                                [disabled]="this.firstFormGroup.status!='VALID'||this.secondFormGroup.status!='VALID'||this.thirdFormGroup.status!='VALID'"
                                mat-button 
                                (click)="addFilter()">Agregar</button>
                        </div>
                    </form>
                </mat-step>
            </mat-vertical-stepper>
        
        </mat-tab>
    </mat-tab-group>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="data.dataFilter" cdkFocusInitial>Cerrar</button>
</mat-dialog-actions>