import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import Swal from 'sweetalert2';

import { VistaService } from './vista.service';

export interface DialogData {
  id: number;
  name: string;
}

@Component({
  selector: 'app-vista',
  templateUrl: './vista.component.html',
  styleUrls: ['./vista.component.scss']
})
export class VistaComponent implements OnInit {

  identificador: string;
  textList = [];
  dataCod = [];
  displayedColumns = [];

  grupoCol = [];
  codigos = null;

  valRowList = [];
  valColList = [];

  captura = [];

  estadoList = [
    { value: 1, name: 'Completado' },
    { value: 2, name: 'Pendiente' },
    { value: 3, name: 'No es posible completar' }
  ];

  selectEstado = null;

  config = {
    displayKey: 'description',
    search: true,
    height: '200px',
    placeholder:'Selecciona',
    noResultsFound: 'Sin resultados',
    searchPlaceholder:'Buscar...',
    clearOnSelection: false
  }

  constructor(
    public dialogRef: MatDialogRef<VistaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private vistaService: VistaService
  ) { }

  ngOnInit(): void {
    this.readVista();
  }

  /////////////////////////////// Busca Vista
  readVista(){
    this.vistaService.readVista(this.data.id)
      .subscribe( (data) => {
        this.identificador = data['id'] ? data['id'] : null;
        this.textList = data['txt'] ? data['txt'] : [];
        this.displayedColumns = data['colnota'] ? data['colnota'] : [];
        this.dataCod = data['codes'] ? data['codes'] : [];
        this.grupoCol = data['grupos'] ? data['grupos'] : [];
        let codigos = data['codigos'] ? data['codigos'] : [];
        this.codigos = codigos.reduce((o, key) => ({...o, [key.grupo]: key.codigos}), {});
        this.valColList = data['valcol'] ? data['valcol'] : [];
        this.valRowList = data['valrow'] ? data['valrow'] : [];
        this.dataCod.forEach((table, i) => {
          this.captura.push([]);
          table.forEach((row, j) => {
            this.captura[i].push([]);
            row.forEach((element,k) => {
              k > 0 ? this.captura[i][j].push(null) : null;
            });
          });
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  /**Elimina Codigo */
  deleteCode(table: number, row: number, column: number){
    this.captura[table][row][column - 1] = null;
  }

  /**Busca validaciones */
  getValide(){
    let data = {
      captura: this.captura,
      codificacion: this.data.id
    }
    this.vistaService.getValide(data)
      .subscribe( (data: any) => {
        if(data.length > 0){
          let messages = data.join('');
          Swal.fire({
            title: 'Revisa las siguientes observaciones',
            icon: 'info',
            width: 700,
            html: `
              <ul>
                ${messages}
              <ul>
            `
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }


  /**Guarda  informacion*/
  async sendData(){
    if (!this.selectEstado) {
      Swal.fire(
        `¡Debes seleccionar un estado!`,
        "",
        'info'
      ); 
    }
    
    if (this.selectEstado > 1) {
      const { value: text } = await Swal.fire({
        title: 'Observaciones',
        input: 'textarea',
        inputPlaceholder: 'Escribe tus observaciones aquí...',
        showCancelButton: true
      });
      
      if (text) {
        Swal.fire(text);
      }
    }else if(this.selectEstado == 1){
      this.getValide();
    }


  }


}
