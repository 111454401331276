<div class="m-5">
  <h1><span class="material-icons">work</span>Proyectos</h1>    
  <div class="bg-white m-3">
      <mat-list>
      <div mat-subheader>
        <button
          *ngIf="permissions?.level>2" 
          mat-icon-button
          class="text-primary float-right" 
          matTooltip="Ver eventos" 
          matTooltipPosition="above"
          (click)="openDialogEvento()">
          <mat-icon>event_note</mat-icon>
        </button>
        <button
          *ngIf="permissions?.level>1" 
          mat-icon-button
          class="text-success" 
          matTooltip="Crea nuevo proyecto" 
          matTooltipPosition="above"
          (click)="createProyecto()">
          <mat-icon>add_circle_outline</mat-icon>
        </button>
        Proyecto
      </div>
      <mat-divider></mat-divider>
      <div style="height: 400px; overflow-y: auto;">
          <mat-selection-list #proyecto [multiple]="false">
              <mat-list-option *ngFor="let proyecto of proyectoList" [value]="proyecto.id">
                  <mat-icon mat-list-icon class="text-info">folder</mat-icon>
                  <div mat-line><a class="stretched-link" (click)="selectProyecto(proyecto.id)">{{proyecto.name}}</a></div>
              </mat-list-option>
          </mat-selection-list>
      </div>
      <mat-divider></mat-divider>
      </mat-list>
  </div>
</div>