import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from "@env/environment";

@Injectable({
  providedIn: 'root'
})
export class PantallaService {

  env = environment;
  constructor(
    private http: HttpClient
  ) { }

  ///////////////////////Busca pantalla
  readPantalla(id: number){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.get(`${ this.env.api }/pantalla/${id}`, { headers });
  }

  ///////////////////////Crea pantalla
  createPantalla(pantalla: any){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.post(`${ this.env.api }/pantalla`, pantalla, { headers });
  }

  ///////////////////////Busca grupo
  readGrupo(id: number){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.get(`${ this.env.api }/grupo/${id}`, { headers });
  }

}

