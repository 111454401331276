import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import Swal from 'sweetalert2';

import { ConexionService } from '@app/pages/conexion/conexion.service';
import { ImportarService } from './importar.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-importar',
  templateUrl: './importar.component.html',
  styleUrls: ['./importar.component.scss']
})
export class ImportarComponent implements OnInit {

  displayedColumnsMapeo = ['tipo', 'pantalla', 'tabla', 'accion'];
  displayedColumnsImportacion = ['codificacion', 'accion'];

  conexionList = [];
  codificacionList = [];
  camposList = [];
  tablasList = null;
  camposTablaList = null;
  camposPreview = [];
  datosPreview = [];
  dataSourcePreview = null;
  dataSourceMapeo = null;
  dataSourceImportacion = null;

  selectCamposList = null;
  importacionList = []

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  previewFormGroup: FormGroup;
  mapeoForm: FormGroup;

  isupdate = false;
  idImportacion = null;
  selected = new FormControl(0);

  configTablas = {
    displayKey: 'description',
    search: true,
    height: '200px',
    placeholder:'Selecciona tabla',
    noResultsFound: 'Sin resultados',
    searchPlaceholder:'Buscar...',
    clearOnSelection: false
  }

  configCamposTablas = {
    displayKey: 'description',
    search: true,
    height: '200px',
    moreText: 'más',
    placeholder:'Selecciona campos',
    noResultsFound: 'Sin resultados',
    searchPlaceholder:'Buscar...',
    clearOnSelection: false
  }

  constructor(
    public dialogRef: MatDialogRef<ImportarComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog,
    private conexionService: ConexionService,
    private importarService: ImportarService,
    private _formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.readImportacion();
    this.readConexion();
    this.readCod();

    this.firstFormGroup = this._formBuilder.group({
      firstCtrl1: ['', Validators.required],
      firstCtrl2: ['', Validators.required]
    });
    
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });

    this.previewFormGroup = this._formBuilder.group({
      previewCtrl: ['', Validators.required]
    });
  }

  /////////////Busca importaciones
  readImportacion(){
    this.importarService.readImportacion(parseInt(this.data.idProyecto))
      .subscribe( (data) => {
        this.importacionList = Object.keys(data).map( (row) => {
          return {
            id: data[row][0],
            conexion: data[row][1],
            opcion: data[row][2],
            consulta: data[row][3],
            tabla: data[row][4],
            campos: data[row][5],
            mapeo: data[row][6],
            codificacion: data[row][7],
            nombre: data[row][8],
            alias: data[row][9]
          }
        });
        this.dataSourceImportacion = new MatTableDataSource(this.importacionList);
      },
      (error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error al cargar, intente más tarde.',
        });
      }
    );
  }

  /////////////Busca conexiones
  readConexion(){
    this.conexionService.readConexion()
      .subscribe( (data) => {
        this.conexionList = Object.keys(data).map( (i) => {
          return {
            value: data[i][0], 
            viewValue: data[i][1]
          }
        });
      },
      (error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error al cargar, intente más tarde.',
        });
      }
    );
  }

  ///////////////////////Busca pantallas
  readCod(){
    this.importarService.readCod(this.data.idProyecto)
      .subscribe( (data) => {
        this.codificacionList = Object.keys(data['pantallas']).map( (row) => {
          return {
            id: data['pantallas'][row][0], 
            name: data['pantallas'][row][1]
          }
        });
      },
      (error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error al cargar, intente más tarde.',
        });
      }
    );
  }

  ///////////////////////Busca variables de pantalla
  readCamposPantalla(){
    if (this.mapeoForm) {
      this.dataSourceMapeo = null;
      this.camposList.forEach(element => {
        this.mapeoForm.removeControl(element[1])
      });
      this.camposList = [];
    }
    Swal.fire({
      title: 'Cargando campos de pantalla ...',
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      }
    });
    if(this.secondFormGroup.value.secondCtrl){
      let codificacion = parseInt(this.secondFormGroup.value.secondCtrl[0].id);
      let group = {};
      this.importarService.readCamposPantalla(codificacion)
        .subscribe( (data) => {
          this.camposList = [...Object(data), ['Filtro (Opcional)', 'filtro']];
          this.camposList.forEach(element => {
            group[element[1]] = new FormControl('');
          });
          this.mapeoForm = new FormGroup(group); 
          this.dataSourceMapeo = new MatTableDataSource(this.camposList);
          Swal.close();
        },
        (error) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Error al cargar, intente más tarde.',
          });
        }
      );
    }else{
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Error al cargar, intente más tarde.',
      });
    }
    
  }

  ///////////////////////Busca tablas
  readTablas(){
    Swal.fire({
      title: 'Cargando tablas ...',
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      }
    });
    let conexion = parseInt(this.firstFormGroup.value.firstCtrl1.value);
    this.importarService.readTablas(conexion)
      .subscribe( (data) => {
        this.tablasList = Object.keys(data).map( (i) => {
          return {
            value: data[i], 
            description: data[i]
          }
        });
        Swal.close();
      },
      (error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error al cargar, intente más tarde.',
        });
      }
    );
  }

  ///////////////////////Busca campos de tabla
  readCamposTabla(){
    Swal.fire({
      title: 'Cargando campos ...',
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      }
    });
    let conexion = parseInt(this.firstFormGroup.value.firstCtrl1.value);
    let tabla = this.firstFormGroup.value.firstCtrl3.value;
    this.importarService.readCamposTabla(conexion, tabla)
      .subscribe( (data) => {
        this.camposTablaList = Object.keys(data).map( (i) => {
          return {
            value: data[i], 
            description: data[i]
          }
        });
        Swal.close();
      },
      (error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error al cargar, intente más tarde.',
        });
      }
    );
  }

  ///////////////////////Busca previzualizacion de datos
  readDataPreview(){
    this.previewFormGroup.controls['previewCtrl'].setValue('1');
    Swal.fire({
      title: 'Cargando datos ...',
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      }
    });
    let conexion = parseInt(this.firstFormGroup.value.firstCtrl1.value);
    let opcion = parseInt(this.firstFormGroup.value.firstCtrl2);
    let body = null;
    if (opcion == 1) {
      body = {
        opcion: opcion,
        conexion: conexion,
        consulta: null,
        tabla: this.firstFormGroup.value.firstCtrl3.value,
        campos: this.firstFormGroup.value.firstCtrl4
      };
    } else {
      body = {
        opcion: opcion,
        conexion: conexion,
        consulta: this.firstFormGroup.value.firstCtrl5,
        tabla: null,
        campos: null
      };
    }
    this.importarService.readDataPreview(body)
      .subscribe( (data) => {
        this.camposPreview = data['campos'];
        this.datosPreview = data['resultado'];
        this.selectCamposList = Object.keys(data['campos']).map( (i) => {
          return {
            value: data['campos'][i], 
            description: data['campos'][i]
          }
        });
        this.dataSourcePreview = new MatTableDataSource(this.datosPreview);
        Swal.close();
      },
      (error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error al cargar, intente más tarde.',
        });
      }
    );
  }

  selectOption(event: any){
    if (event == 1) {
      this.firstFormGroup.removeControl('firstCtrl5');
      this.firstFormGroup.addControl('firstCtrl3', new FormControl('', Validators.required));
      this.firstFormGroup.addControl('firstCtrl4', new FormControl('', Validators.required));
      this.readTablas();
    } else {
      this.firstFormGroup.removeControl('firstCtrl3');
      this.firstFormGroup.removeControl('firstCtrl4');
      this.firstFormGroup.addControl('firstCtrl5', new FormControl('', Validators.required));
    }
  }

  createImportar(){
    let conexion = parseInt(this.firstFormGroup.value.firstCtrl1.value);
    let opcion = parseInt(this.firstFormGroup.value.firstCtrl2);
    let codificacion = parseInt(this.secondFormGroup.value.secondCtrl[0].id);
    Swal.fire({
      icon: 'info',
      title: 'Guardando información ...',
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      }
    });
    if (opcion==1) {
      var body = {
        conexion: conexion,
        opcion: opcion,
        codificacion: codificacion,
        consulta: null,
        tabla: this.firstFormGroup.value.firstCtrl3.value,
        campos: this.firstFormGroup.value.firstCtrl4,
        mapeo: this.mapeoForm.value
      };
    } else {
      var body = {
        conexion: conexion,
        opcion: opcion,
        codificacion: codificacion,
        consulta: this.firstFormGroup.value.firstCtrl5,
        tabla: null,
        campos: null,
        mapeo: this.mapeoForm.value
      };
    }
    this.importarService.createImportar(body).
      subscribe( (data) => {
        if (data) {
          this.readImportacion();
          Swal.fire({
            icon: 'success',
            title: 'Datos de importación guardados correctamente',
            showConfirmButton: false,
            timer: 1500
          });
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Error al guardar información, intente más tarde.',
          });
        }
      },
      (error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error al guardar información, intente más tarde.',
        });
      });
  }

  initImportacion(row: any){
    if (row['mapeo']['filtro']) {
      let campo = 1;
      Swal.mixin({
        icon: 'info',
        input: 'text',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        cancelButtonColor: '#d33',
        preConfirm: (values) => {
          if (!values && campo < 2) {
            Swal.showValidationMessage(
              "Campo requerido"
            );
          }else{
            campo += 1;
          }
        }
      }).queue([
        {
          title: 'Filtrar datos',
          text: `Escriba el valor a filtrar en la variable ${row['mapeo']['filtro']['value']}`,
          confirmButtonText: 'Siguiente &rarr;'
        },
        {
          title: 'Importar datos',
          html: `
            Agrega un valor a tu variable <b>${this.data.control}</b> (Opcional)
          `,
          confirmButtonText: 'Importar',
        }
      ]).then( (result) => {
        let body = {
          importacion: parseInt(row.id),
          filtro: result['value'][0],
          control: this.data.control,
          control_value: result['value'][1]
        };
        if (result) {
          Swal.fire({
            icon: 'info',
            title: 'Importando datos ...',
            allowOutsideClick: false,
            onOpen: () =>{
              Swal.showLoading();
            }
          });
          this.importarService.initImportar(body)
            .subscribe( (data) => {
              Swal.fire({
                icon: 'success',
                title: 'Importación completada',
                text: `Se importaron correctamente ${data} registros a la codificación ${row.nombre}`
              });
            },
            (error) => {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Error al importar datos, intente más tarde.'
              });
            });
        }
      });
    }else{
      Swal.fire({
        icon: 'info',
        input: 'text',
        title: 'Importar datos',
        html: `
          Agrega un valor a tu variable <b>${this.data.control}</b> (Opcional)
        `,
        showCancelButton: true,
        confirmButtonText: 'Importar',
        cancelButtonText: 'Cancelar',
        cancelButtonColor: '#d33',
      }).then((result) => {
        if (result.isConfirmed) {
          let body = {
            importacion: parseInt(row.id),
            filtro: null,
            control: this.data.control,
            control_value: result['value'][0]
          };
          Swal.fire({
            icon: 'info',
            title: 'Importando datos ...',
            allowOutsideClick: false,
            onOpen: () =>{
              Swal.showLoading();
            }
          });
          this.importarService.initImportar(body)
            .subscribe( (data) => {
              Swal.fire({
                icon: 'success',
                title: 'Importación completada',
                text: `Se importaron correctamente ${data} registros a la codificación ${row.nombre}`
              });
            },
            (error) => {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Error al importar datos, intente más tarde.',
              });
            });
        }
      });
    }
  }

  deleteImportacion(row: any){
    Swal.fire({
      title: '¿Estas seguro de eliminar la configuración de importación?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, Eliminar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.importarService.deleteImportacion(row.id)
          .subscribe( (data) => {
            if (data) {
              Swal.fire({
                icon: 'success',
                title: 'Eliminar importación',
                text: `Configuración de importación eliminada correctamente`
              });
              this.readImportacion();  
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Error al eliminar configuración, intente más tarde.',
              });  
            }
            
          },
          (error) => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Error al eliminar configuración, intente más tarde.',
            });
          });
      }
    });
  }

  openUpdateImportacion(row: any){
    this.isupdate = true;
    this.selected.setValue(1);
    this.idImportacion = row['id'];
    this.conexionList.forEach(element => {
      if (element['value'] == row['conexion']) {
        this.firstFormGroup.controls['firstCtrl1'].setValue(element); 
      }
    });
    this.firstFormGroup.controls['firstCtrl2'].setValue(String(row['opcion']));
    if (row['opcion'] == 1) {
      this.firstFormGroup.addControl('firstCtrl3', new FormControl({value: row['tabla'], description: row['tabla']}, Validators.required));
      this.firstFormGroup.addControl('firstCtrl4', new FormControl(row['campos'], Validators.required));
      this.readCamposTabla();
    }else{
      this.firstFormGroup.addControl('firstCtrl5', new FormControl(row['consulta'], Validators.required));
    }
    this.previewFormGroup.controls['previewCtrl'].setValue('1');
    this.readDataPreview();
    this.secondFormGroup.controls['secondCtrl'].setValue([{id: row['codificacion'], name: row['nombre']}]);
    let group = {};
    this.importarService.readCamposPantalla(row['codificacion'])
      .subscribe( (data) => {
        this.camposList = [...Object(data), ['Filtro (Opcional)', 'filtro']];
        this.camposList.forEach(element => {
          group[element[1]] = new FormControl(row['mapeo'][element[1]])
        });
        this.mapeoForm = new FormGroup(group); 
        this.dataSourceMapeo = new MatTableDataSource(this.camposList);
      },
      (error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error al cargar, intente más tarde.',
        });
      }
    );

  }

  updateImportacion(){
    let conexion = parseInt(this.firstFormGroup.value.firstCtrl1.value);
    let opcion = parseInt(this.firstFormGroup.value.firstCtrl2);
    let codificacion = parseInt(this.secondFormGroup.value.secondCtrl[0].id);
    Swal.fire({
      icon: 'info',
      title: 'Guardando información ...',
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      }
    });
    if (opcion==1) {
      var body = {
        id: this.idImportacion,
        conexion: conexion,
        opcion: opcion,
        codificacion: codificacion,
        consulta: null,
        tabla: this.firstFormGroup.value.firstCtrl3.value,
        campos: this.firstFormGroup.value.firstCtrl4,
        mapeo: this.mapeoForm.value
      };
    } else {
      var body = {
        id: this.idImportacion,
        conexion: conexion,
        opcion: opcion,
        codificacion: codificacion,
        consulta: this.firstFormGroup.value.firstCtrl5,
        tabla: null,
        campos: null,
        mapeo: this.mapeoForm.value
      };
    }
    this.importarService.updateImportar(body).
      subscribe( (data) => {
        if (data) {
          this.readImportacion();
          Swal.fire({
            icon: 'success',
            title: 'Datos de importación guardados correctamente',
            showConfirmButton: false,
            timer: 1500
          });
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Error al guardar información, intente más tarde.',
          });
        }
      },
      (error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error al guardar información, intente más tarde.',
        });
      });
  }

}
