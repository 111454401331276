import { Component, OnInit, Inject } from '@angular/core';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

import Swal from 'sweetalert2';

import { UsuarioService } from '../usuario.service';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-usuario-nuevo',
  templateUrl: './usuario-nuevo.component.html',
  styleUrls: ['./usuario-nuevo.component.scss']
})
export class UsuarioNuevoComponent implements OnInit {

  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast: any) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  hide = true;
  title = null;

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  nameFormControl = new FormControl('', [
    Validators.required,
  ]);
  passFormControl = new FormControl('', [
    Validators.required,
  ]);
  levelFormControl = new FormControl('', [
    Validators.required,
  ]);
  matcher = new MyErrorStateMatcher();

  levelList = null;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog,
    private usuarioService: UsuarioService
  ) { }

  ngOnInit(): void {
    if(this.data.level==3){
      this.levelList = [
        {value: 1, viewValue: 'Codificador'},
        {value: 2, viewValue: 'Supervisor'},
        {value: 3, viewValue: 'Administrador'}
      ];
    }else if(this.data.level==2){
      this.levelList = [
        {value: 1, viewValue: 'Codificador'},
      ];
    }

    if(this.data.action=='update'){
      this.title = 'Editar usuario';
      this.nameFormControl.setValue(this.data.usuario.nombre);
      this.emailFormControl.setValue(this.data.usuario.email);
      this.passFormControl.setValue(this.data.usuario.password);
      this.levelFormControl.setValue(this.data.usuario.nivel);
    }else{
      this.title = 'Crear usuario';
    }
  }

  createUsuario(){
    let body = {
      nombre: this.nameFormControl.value,
      email: this.emailFormControl.value,
      password: this.passFormControl.value,
      level: this.levelFormControl.value
    };
    this.usuarioService.createUsuario(body)
    .subscribe((data) => {
      this.Toast.fire({
        icon: 'success',
        title: 'Usuario creado correctamente'
      });
    },
    (error) => {
      Swal.fire(
        `¡Se generó un error al crear el usuario, revise que el email no este duplicado, o intente más tarde!`,
        '',
        "info"
      );
    });
  }

  updateUsuario(){
    let body = {
      usuario: this.data.usuario.id,
      nombre: this.nameFormControl.value,
      email: this.emailFormControl.value,
      password: this.passFormControl.value,
      level: this.levelFormControl.value
    };
    this.usuarioService.updateUsuario(body)
    .subscribe((data) => {
      this.Toast.fire({
        icon: 'success',
        title: 'Usuario actualizado correctamente'
      });
    },
    (error) => {
      Swal.fire(
        `¡Se generó un error al actualizar el usuario, revise que el email no este duplicado, o intente más tarde!`,
        '',
        "info"
      );
    });
  }

}
