<h2 mat-dialog-title>Descarga de proyecto</h2>
<mat-dialog-content class="mat-typography">
    <div>
        <mat-form-field appearance="fill">
          <mat-label>Seleccionar {{ data.control }} (opcional)</mat-label>
          <input matInput [(ngModel)]="controlValue">
        </mat-form-field>
        <br>
        <mat-form-field appearance="fill">
          <mat-label>Seleccionar estado</mat-label>
          <mat-select [(ngModel)]="selectEstado" multiple>
            <mat-option *ngFor="let estado of estadoList" [value]="estado.value">
              {{ estado.name }}
              </mat-option>
          </mat-select>
        </mat-form-field>
        <br>
        <mat-form-field appearance="fill">
          <mat-label>Selecciona codificación (opcional)</mat-label>
          <mat-select [(ngModel)]="selectCodificacion" multiple>
            <mat-option *ngFor="let codificacion of data.codificacionList" [value]="codificacion.id">
              {{ codificacion.name }}
              </mat-option>
          </mat-select>
        </mat-form-field>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cerrar</button>
    <button 
      mat-button 
      [disabled]="!selectEstado"
      (click)="downloadProyecto()">Descargar</button>
</mat-dialog-actions>