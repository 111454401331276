<h1 mat-dialog-title>Códigos</h1>
<mat-dialog-content class="mat-typography">
  <div class="row">
    <div class="col-sm-4 mt-1">

      <!-- Seccion de administracion de codigos -->
      <div class="container-table-grupo mat-elevation-z8">
          <table mat-table [dataSource]="grupoList">
            <!-- select Column -->
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef>
                <button 
                  mat-icon-button
                  class="text-success"
                  matTooltip="Crear grupo" 
                  matTooltipPosition="above"
                  (click)="createGrupo()">
                  <mat-icon>add_circle_outline</mat-icon>
                </button>
              </th>
              <td 
                mat-cell
                style="width: 30%;" 
                *matCellDef="let element">
                <mat-radio-button 
                  class="example-radio-button"
                  (click)="selectGrupo(element.id)"
                  [value]=" element.id "
                  color="primary" 
                  >
                </mat-radio-button>
              </td>
            </ng-container>
              
              <!-- codigo Column -->
              <ng-container matColumnDef="grupo">
                  <th mat-header-cell *matHeaderCellDef>
                    Grupo 
                  </th>
                  <td mat-cell *matCellDef="let element"> {{element.name}} </td>
              </ng-container>

              <!-- más Column -->
              <ng-container matColumnDef="mas">
                  <th mat-header-cell *matHeaderCellDef>
                    <button mat-icon-button [matMenuTriggerFor]="menuProyecto" class="text-secondary" matTooltip="Más">
                      <mat-icon>more_horiz</mat-icon>
                    </button>
                    <mat-menu #menuProyecto="matMenu" xPosition="before">
                      <button 
                        mat-menu-item
                        (click)="downloadCodigo()"
                        >
                        <mat-icon>download</mat-icon>
                        <span>Descargar códigos</span>
                      </button>
                    </mat-menu>
                  </th>
                  <td mat-cell *matCellDef="let element" style="width: 5%;"> 
                    <button mat-icon-button [matMenuTriggerFor]="menuProyecto" class="text-secondary" matTooltip="Más">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menuProyecto="matMenu" xPosition="before">
                      <button 
                        mat-menu-item
                        (click)="updateGrupo(element.id, element.name)"
                        >
                        <mat-icon>edit</mat-icon>
                        <span>Editar</span>
                      </button>
                      <button 
                        mat-menu-item
                        (click)="deleteGrupo(element.id, element.name)"
                        >
                        <mat-icon>delete</mat-icon>
                        <span>Eliminar</span>
                      </button>
                    </mat-menu>
                  </td>
              </ng-container>
            
              <tr mat-header-row *matHeaderRowDef="grupoColumns; sticky: true"></tr>
              <tr
                  mat-row 
                  *matRowDef="let row; columns: grupoColumns;" 
                  class="example-element-row"></tr>
          </table>
      </div>
    </div>

    <!-- Seccion de tabla de codigos -->
    <div class="col-sm">

      <mat-card
        *ngIf="!isGrupo"
        style="max-width: 400px"
        class="bg-info text-white">
        <span class="material-icons">
          info
        </span>
        Selecciona un grupo para ver los códigos.
      </mat-card>

      <div
        *ngIf="isGrupo"
        class="container-table mat-elevation-z8 mt-1">
          <table *ngIf="dataSourceCode" mat-table [dataSource]="dataSourceCode">

            <!-- checkbox Column -->
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle() : null"
                              [checked]="selectionCode.hasValue() && isAllSelected()"
                              [indeterminate]="selectionCode.hasValue() && !isAllSelected()"
                              [aria-label]="checkboxLabel()"
                              color="primary">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()"
                              (change)="$event ? selectionCode.toggle(row) : null"
                              [checked]="selectionCode.isSelected(row)"
                              [aria-label]="checkboxLabel(row)"
                              color="primary">
                </mat-checkbox>
              </td>
            </ng-container>

            <!-- codigo Column -->
            <ng-container matColumnDef="codigo">
              <th mat-header-cell *matHeaderCellDef> Código </th>
              <td 
                mat-cell
                style="width: 20%;"
                *matCellDef="let element"> 
                {{element.code}} 
              </td>
            </ng-container>
          
            <!-- nombre Column -->
            <ng-container matColumnDef="nombre">
              <th mat-header-cell *matHeaderCellDef> Nombre </th>
              <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <!-- más Column -->
            <ng-container matColumnDef="mas">
              <th mat-header-cell *matHeaderCellDef>
                <button mat-icon-button [matMenuTriggerFor]="menuProyecto" class="text-secondary" matTooltip="Más">
                  <mat-icon>more_horiz</mat-icon>
                </button>
                <mat-menu #menuProyecto="matMenu" xPosition="before">
                  <button 
                    mat-menu-item
                    (click)="createCodigo()"
                    >
                    <mat-icon>add_circle_outline</mat-icon>
                    <span>Crear código</span>
                  </button>
                  <button 
                    mat-menu-item
                    (click)="uploadCodigo()"
                    >
                    <mat-icon>upload</mat-icon>
                    <span>Cargar códigos</span>
                  </button>
                  <button 
                    mat-menu-item
                    [disabled]="selectionCode.selected.length==0"
                    (click)="deleteSelectCodes()"
                    >
                    <mat-icon>delete</mat-icon>
                    <span>Eliminar códigos seleccionados</span>
                  </button>
                </mat-menu>
              </th>
              <td mat-cell *matCellDef="let element" style="width: 10%;"> 
                <button mat-icon-button [matMenuTriggerFor]="menuProyecto" class="text-secondary" matTooltip="Más">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menuProyecto="matMenu" xPosition="before">
                  <button 
                    mat-menu-item
                    (click)="updateCodigo(element.id, element.code, element.name)"
                    >
                    <mat-icon>edit</mat-icon>
                    <span>Editar</span>
                  </button>
                  <button 
                    mat-menu-item
                    (click)="deleteCodigo(element.id, element.code, element.name)"
                    >
                    <mat-icon>delete</mat-icon>
                    <span>Eliminar</span>
                  </button>
                </mat-menu>
              </td>
            </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="codigoColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: codigoColumns;"></tr>
          </table>
      </div>


    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cerrar</button>
</mat-dialog-actions>