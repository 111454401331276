<h2 mat-dialog-title>Filtro de registros</h2>
<mat-dialog-content class="mat-typography">
    <div class="example-container">
        <mat-form-field appearance="fill">
          <mat-label>Seleccionar {{ data.control }}</mat-label>
          <input matInput [(ngModel)]="controlValue">
        </mat-form-field>
        <br>
        <mat-form-field appearance="fill">
          <mat-label>Seleccionar valor de campo</mat-label>
          <mat-select [(ngModel)]="filtroValue">
            <mat-option *ngFor="let valor of data.filtro" [value]="valor">
              {{ valor.texto }}
              </mat-option>
          </mat-select>
        </mat-form-field>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cerrar</button>
    <button mat-button (click)="filtro()">Filtrar</button>
</mat-dialog-actions>