import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class DetalleExcelService {

  constructor() { }

  ///////////////////////Descarga de codificacion
  downloadCodificacion(body: any){
    const title = 'Codificación: ' + body.codificacion;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(body.codificacion);
    // Add new row
    let titleRow = worksheet.addRow([title]);
    worksheet.addRow(['Creado por Procod']);
    worksheet.addRow([]);

    titleRow.font = { name: 'Arial', family: 4, size: 14, bold: true };

    let headerRow = worksheet.addRow(body.columns);
    for (let row of body.data){
      let keys = body.columns;
      let temp=[];
      for(let key of keys){
        temp.push(this.isObject(row[key], body.labels));
      }
      worksheet.addRow(temp);
    }

    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4D79FF' },
        bgColor: { argb: '4D79FF' }
      }
      cell.style.font = {
        bold: true,
        color: { argb: 'FFFFFF' }
      }
    });
    
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, `Codificación - ${body.codificacion}.xlsx`);
    });

  }

  ///////////////////////Descarga de proyecto
  downloadProyecto(proyecto: any, body: any, labels: string){
    let workbook = new Workbook();
    for (let codificacion of body) {
      const title = 'Codificación: ' + codificacion.codificacion;
      let worksheet = workbook.addWorksheet(codificacion.codificacion);
      // Add new row
      let titleRow = worksheet.addRow([title]);
      worksheet.addRow(['Creado por Procod']);
      worksheet.addRow([]);

      titleRow.font = { name: 'Arial', family: 4, size: 14, bold: true };
  
      let headerRow = worksheet.addRow(codificacion.campos);
      for (let row of codificacion.resultado){
        let keys = codificacion.campos;
        let temp=[];
        for(let key of keys){
          temp.push(this.isObject(row[key], labels));
        }
        worksheet.addRow(temp);
      }
  
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '4D79FF' },
          bgColor: { argb: '4D79FF' }
        }
        cell.style.font = {
          bold: true,
          color: { argb: 'FFFFFF' }
        }
      });
    }
    
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, `Proyecto - ${proyecto}.xlsx`);
    });

  }


  ////////////////Revisa si el valor es un objeto
  isObject(value: any, labels: string){
    if (typeof value === 'object' && value !== null) {
      return value[labels];
    } else {
      return value;
    }
  }


}
