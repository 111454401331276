import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from "@env/environment";

@Injectable({
  providedIn: 'root'
})
export class ProyectoService {

  env = environment;
  constructor(
    private http: HttpClient
  ) { }

  ///////////////////////Busca proyecto
  readProyecto(){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.get(`${ this.env.api }/proyecto`, { headers });
  }

  ///////////////////////Crea proyecto
  createProyecto(body: any){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.post(`${ this.env.api }/proyecto`, body, { headers });
  }

}
