import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

import Swal from 'sweetalert2';

import { PantallaService } from './pantalla.service';
import { CodigoService } from '../codigo/codigo.service';

import { ValidaComponent } from './valida/valida.component';
import { FiltroComponent } from './filtro/filtro.component';

export interface DialogData {
  proyecto: number;
  id: number;
  pantalla: boolean;
}

@Component({
  selector: 'app-pantalla',
  templateUrl: './pantalla.component.html',
  styleUrls: ['./pantalla.component.scss']
})
export class PantallaComponent implements OnInit {

  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast: any) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });
  
  step = null;
  textList = [];

  dataCod = [];
  displayedColumns = [];

  grupos = [];
  grupoList = {};
  grupoCol = [];

  valColList = []; /**Validacion de columnas [codigo duplicado, celda vacia] */
  valRowList = []; /**Validacion de renglones [codigo duplicado, celda vacia]*/

  dataFilter = [];

  ispantalla: boolean;

  constructor(
    public dialogRef: MatDialogRef<PantallaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog,
    private pantallaService: PantallaService,
    private codigoService: CodigoService
  ) { }

  ngOnInit(): void {
    this.readPantalla();
    this.readGrupo();
    this.ispantalla = this.data.pantalla;
  }
  
  ////////////////////////////Funciones de acordion
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  ////////////////Agrega caja de texto
  addText(){
    let num = this.textList.length;
    this.textList.push('txt' + (num + 1));
  }

  ///////////////////////////////Edita caja de texto
  editText(index: number){
    Swal.mixin({
      input: 'text',
      confirmButtonText: 'Editar',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      allowEscapeKey: true,
      preConfirm: (values) =>{
        if (!values) {
          Swal.showValidationMessage('Campo requerido');
        }
      }
    }).queue([
      { inputValue: `${ this.textList[index] }` }
    ]).then((result: any) => {
      if (result.value) {
        const txt = result.value[0].replace(' ','_').toLowerCase();
        this.textList[index] = txt;
      }
    })
  }

  ///////////////////Eliminar caja de texto
  deleteText(index: number){
    this.textList = [
      ...this.textList.slice(0, index),
      ...this.textList.slice(index + 1)
    ];
  }

  ////////////////////////////////////////////////Tablas de codigos

  ///////////////////Crea tabla de codigos
  addTable(){
    this.displayedColumns.push(['Nota 1']);
    this.dataCod.push([['Nota 1', 'code11']]);
    this.valColList.push([[false, false]]);
    this.valRowList.push([[false, false]]);
    this.grupoCol.push([null]);
  }

  ///////////////////Elimina tabla de codigos
  deleteTable(table: number){
    this.dataCod = [
      ...this.dataCod.slice(0, table),
      ...this.dataCod.slice(table + 1)
    ];
    this.displayedColumns = [
      ...this.displayedColumns.slice(0, table),
      ...this.displayedColumns.slice(table + 1)
    ];
    this.grupoCol = [
      ...this.grupoCol.slice(0, table),
      ...this.grupoCol.slice(table + 1)
    ];
    this.valColList = [
      ...this.valColList.slice(0, table),
      ...this.valColList.slice(table + 1)
    ];
  }
  
  ////////////////////Agrega columna de codigos
  addColumn(table: number) {
    let next = this.displayedColumns[table].length + 1;
    this.displayedColumns[table].push('Nota ' + next);
    this.grupoCol[table].push(null);
    this.valColList[table].push([false, false]);
    this.dataCod[table].forEach((element, index) => {
      this.dataCod[table][index].push('code' + String(index + 1) + String(next));
    });
  }

  deleteCol(table: number, column: number) {
    if (this.displayedColumns[table].length > 1) {
      this.displayedColumns[table] = [
        ...this.displayedColumns[table].slice(0, column),
        ...this.displayedColumns[table].slice(column + 1)
      ];
      this.grupoCol[table] = [
        ...this.grupoCol[table].slice(0, column),
        ...this.grupoCol[table].slice(column + 1)
      ];
      this.valColList[table] = [
        ...this.valColList[table].slice(0, column),
        ...this.valColList[table].slice(column + 1)
      ];
      this.dataCod[table].forEach((element, index) => {
        this.dataCod[table][index] = [
          ...this.dataCod[table][index].slice(0, (column + 1)),
          ...this.dataCod[table][index].slice((column + 1) + 1)
        ];
      }); 
    }
  }

  addRow(table: number) {
    let next = this.dataCod[table].length + 1;
    let row = ['Nota ' + next];
    this.displayedColumns[table].forEach((element, index) => {
      row.push('code' + String(next) + String(index + 1));
    });
    this.dataCod[table].push(row);
  }

  deleteRow(table: number, row: number){
    if (this.dataCod[table].length > 1) {
      this.dataCod[table] = [
        ...this.dataCod[table].slice(0, row),
        ...this.dataCod[table].slice(row + 1)
      ];
    }
    
  }

  ///////////////////Editar notas de columna
  editCol(table: number, col: number){
    Swal.mixin({
      input: 'text',
      confirmButtonText: 'Editar',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      allowEscapeKey: true,
      preConfirm: (values) =>{
        if (!values) {
          Swal.showValidationMessage('Campo requerido');
        }
      }
    }).queue([
      { inputValue: `${ this.displayedColumns[table][col] }` }
    ]).then((result: any) => {
      if (result.value) {
        const txt = result.value[0];
        this.displayedColumns[table][col] = txt;
      }
    })
  }

  ///////////////////Editar codigos
  editCode(table: number, row: number, col: number){
    Swal.mixin({
      input: 'text',
      confirmButtonText: 'Editar',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      allowEscapeKey: true,
      preConfirm: (values) =>{
        if (!values) {
          Swal.showValidationMessage('Campo requerido');
        }
      }
    }).queue([
      { inputValue: `${ this.dataCod[table][row][col] }` }
    ]).then((result: any) => {
      if (result.value) {
        const txt = col > 0 ? result.value[0].replace(' ','_').toLowerCase() : result.value[0]; 
        this.dataCod[table][row][col] = txt;
      }
    })
  }  

  dropText(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.textList, event.previousIndex, event.currentIndex);
  }


  /////////////////////////////// Busca pantalla
  readPantalla(){
    this.pantallaService.readPantalla(this.data.id)
      .subscribe( (data) => {
        this.textList = data['txt'] ? data['txt'] : [];
        this.displayedColumns = data['colnota'] ? data['colnota'] : [];
        this.dataCod = data['codes'] ? data['codes'] : [];
        this.grupoCol = data['grupo'] ? data['grupo'] : [];
        this.valColList = data['valcol'] ? data['valcol'] : [];
        this.valRowList = data['valrow'] ? data['valrow'] : [];
        this.dataFilter = data['filter'] ? data['filter'] : [];
      },
      (error) => {
        console.log(error);
      }
    );
  }

  /////////////////////////Crear Pantalla
  createPantalla() {
    let pantalla = {
      texto: this.textList,
      colnota: this.displayedColumns,
      codes: this.dataCod,
      codificacion: this.data.id,
      grupo: this.grupoCol,
      valcol : this.valColList,
      valrow: this.valRowList,
      filter: this.dataFilter
    }
    console.log(pantalla)
    this.pantallaService.createPantalla(pantalla)
      .subscribe( (data) => {
        if (data[1]) {
          this.ispantalla = true;
          this.Toast.fire({
            icon: 'success',
            title: `${data[1]}`
          });
        }else if(data[0]){
          Swal.fire(
            `${data[0]}`,
            '',
            'info'
          ); 
        }
      },
      (error) => {
        Swal.fire(
          'No fue posible guardar la pantalla, intente más tarde',
          '',
          'info'
        );
      }
    );
  }

  //////////////////////Busca grupo de codigos
  readGrupo(){
    this.codigoService.readGrupo(this.data.proyecto)
      .subscribe( (data: any[]) => {
        this.grupos = Object.keys(data).map( (row) => {
          return {
            id: data[row][0], 
            name: data[row][1],
          }
        });
        this.grupos = [{id: null, name: 'Ninguno'}, ...this.grupos]
        this.grupoList = this.grupos.reduce((o, key) => ({...o, [key.id]: key.name}), {});
      },
      (error) => {
        console.log(error)
      }
    );
  }

  //////////////////////Selecciona grupos de codigos
  async selectGrupo(table: number, col: number){
    let yourgrupo = this.grupoCol[table][col];
    const { value: grupo } = await Swal.fire({
      title: 'Selecciona un grupo de codigos',
      html: `Grupo actual: <b>${this.grupoList[yourgrupo] ? this.grupoList[yourgrupo] : 'Sin grupo'}</b>`,
      input: 'select',
      inputOptions: this.grupoList,
      inputPlaceholder: 'Selecciona un grupo',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      inputValidator: (value) => {
        if (!value) {
          return 'You need to write something!'
        }
      }
    });
    
    if (grupo) {
      this.grupoCol[table][col] = Number(grupo);
    }
  }

  //////Validacion
  async valide(type: string, table: number, col?: number,){
    let codigo = col!= null && col!= undefined ? this.valColList[table][col][0] : this.valRowList[table][0][0];
    let vacio = col!= null && col!= undefined ? this.valColList[table][col][1] : this.valRowList[table][0][1];
    const dialogRef = this.dialog.open(ValidaComponent, {
      width: '350px',
      disableClose: true,
      data: { type: type, codigo: codigo, vacio: vacio }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (col != null && col!= undefined) {
        this.valColList[table][col][0] = result.codigo;
        this.valColList[table][col][1] = result.vacio; 
      }else{
        this.valRowList[table][0][0] = result.codigo;
        this.valRowList[table][0][1] = result.vacio; 
      }
    });
  }


  ////////////////Filtros
  filter(){
    const dialogRef = this.dialog.open(FiltroComponent, {
      width: '50%',
      disableClose: true,
      data: { textList: this.textList, grupos: this.grupos, dataFilter: this.dataFilter }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.dataFilter = result;
    });
  }

}
