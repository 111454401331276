import { Component, OnInit, Inject } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

import Swal from 'sweetalert2';

import { UsuarioService } from '../usuario.service';

@Component({
  selector: 'app-asigna',
  templateUrl: './asigna.component.html',
  styleUrls: ['./asigna.component.scss']
})
export class AsignaComponent implements OnInit {
  
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast: any) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;

  proyectoList = null;
  codificacionList = null;

  constructor(
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog,
    private usuarioService: UsuarioService
  ) { }

  ngOnInit(): void {
    this.readProyecto();
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
    this.thirdFormGroup = this._formBuilder.group({
      thirdCtrl: ['', Validators.required]
    });
  }

  /////////////////////////////// Busca proyectos
  readProyecto(){
    this.usuarioService.readProyecto()
      .subscribe( (data) => {
        this.proyectoList = Object.keys(data).map( (i) => {
          return {id: i, name: data[i]}
        });
      },
      (error) => {
        console.log(error)
      }
    );
  }

  ///////////////////////Busca pantallas
  readCod(proyecto: number){
    this.usuarioService.readCod(proyecto)
      .subscribe( (data) => {
        this.codificacionList = Object.keys(data['pantallas']).map( (row) => {
          return {
            id: data['pantallas'][row][0], 
            name: data['pantallas'][row][1]
          }
        });
      },
      (error) => {
        console.log(error)
      }
    );
  }

  createAsignacion(){
    let body = {
      usuario: this.firstFormGroup.value.firstCtrl,
      codificacion: this.thirdFormGroup.value.thirdCtrl
    }

    this.usuarioService.createAsignacion(body)
      .subscribe( (data) => {
        this.Toast.fire({
          icon: 'success',
          title: 'Usuarios asignados exitosamente'
        });
      },
      (error) => {
        this.Toast.fire({
          icon: 'error',
          title: 'Error al asignar usuarios'
        });
      }
    );
  }

}
