<h1 mat-dialog-title>Revisión</h1>
<mat-dialog-content class="mat-typography bg-light" style="height: calc(100% - 80px);max-height: unset">
    <div class="table-responsive sticky-top">
        <table class="table table-bordered">
            <thead>
                <tr class="table-secondary">
                    <th scope="col" class="border">{{ identificador }}</th>
                    <th *ngFor="let text of textList" scope="col" class="border">{{ text }}</th>
                </tr>
            </thead>
            <tbody *ngIf="registro">
                <tr class="table-light">
                    <td scope="row" class="border">{{ registro[identificador] }}</td>
                    <td *ngFor="let text of textList" class="border"> {{ registro[text] }} </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="table-responsive">
        <table class="table">
            <tbody>
                <tr>
                    <td scope="row" style="width: 100px;">
                        <mat-form-field appearance="fill">
                            <mat-label>Estado</mat-label>
                            <mat-select [(ngModel)]="selectEstado">
                                <mat-option *ngFor="let estado of estadoList" [value]="estado.value">
                                {{ estado.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                    <td style="width: 100px;">
                        <button mat-raised-button color="basic" (click)="multipleForm.reset(); readRegistro();">Buscar nuevo</button>
                    </td>
                    <td>
                        <button mat-raised-button color="primary" (click)="sendData()">Enviar</button>
                    </td>
                    <td>
                        <button 
                            mat-fab 
                            matTooltip="Filtrar registros"
                            matTooltipPosition="right"
                            color="primary"
                            (click)="openDialogFilterRegistro()">
                            <mat-icon>filter_alt</mat-icon>
                        </button>
                    </td>
                </tr>
                <tr *ngIf="observacion_usuario">
                    <td colspan="4"><b>Observación:</b> {{ observacion_usuario }} </td>
                </tr>
            </tbody>
        </table>
    </div>

    <form *ngIf="multipleForm" [formGroup]="multipleForm">
    <table class="table table-bordered" *ngFor="let table of displayedColumns; let i=index;">
        <thead>
            <tr class="table-secondary">
                <th scope="col"></th>
                <th *ngFor="let columns of displayedColumns[i]" scope="col">{{ columns }}</th>
            </tr>
        </thead>
        <tbody *ngIf="isvista">
            <tr *ngFor="let row of dataCod[i]; let j=index; ">
                <td *ngFor="let value of row; let k=index;" [ngClass]="{'table-secondary': k==0}" > 
                    <b *ngIf="k==0" >{{ value }}</b>
                    <textarea 
                    *ngIf="k>0 && !codigos[grupoCol[i][k]]"
                    matInput
                    class="border border-black"
                    [formControlName]="dataCod[i][j][k]"
                    (change)="updateCaptura(i, j, k - 1, 'input', $event)"
                    placeholder="Agrega texto"></textarea>
                    <ng-select
                        *ngIf="k>0 && codigos[grupoCol[i][k]]"
                        placeholder="Selecciona"
                        notFoundText="Sin resultados"
                        [items]="codigos[grupoCol[i][k]]"
                        bindLabel="description"
                        clearAllText="Clear"
                        [formControlName]="dataCod[i][j][k]"
                        (change)="updateCaptura(i, j, k - 1, 'select', $event)">
                    </ng-select>

                    <br><br>
                    <p 
                        *ngIf="k>0 && 
                        codigos[grupoCol[i][k]] && 
                        multipleForm.value[dataCod[i][j][k]]">
                        Seleccionado: <b>{{ multipleForm.value[dataCod[i][j][k]].description }}</b>
                    </p>
                </td>
            </tr>
        </tbody>
    </table>
    </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cerrar</button>
</mat-dialog-actions>