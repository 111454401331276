import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from "@env/environment";

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  env = environment;
  constructor(
    private http: HttpClient
  ) { }

  ///////////////////////Busca usuarios
  readUsuario(){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.get(`${ this.env.api }/usuario`, { headers });
  }

  ///////////////////////Crea usuario
  createUsuario(body: any){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.post(`${ this.env.api }/usuario`, body, { headers });
  }

  ///////////////////////Actualiza usuario
  updateUsuario(body: any){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.put(`${ this.env.api }/usuario`, body, { headers });
  }

  ///////////////////////Eliminar usuario
  deleteUsuario(usuario){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.delete(`${ this.env.api }/usuario/${usuario}`, { headers });
  }

  ///////////////////////Busca proyecto
  readProyecto(){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.get(`${ this.env.api }/proyecto`, { headers });
  }

  ///////////////////////Busca codificacion
  readCod(proyecto: number){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.get(`${ this.env.api }/codificacion/${proyecto}`, { headers });
  }

  ////////////////////Crea asignacion
  createAsignacion(body: any){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.post(`${ this.env.api }/asignacion`, body, { headers });    
  }

  ////////////////////Busca asignacion
  readAsignacion(usuario: any){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.get(`${ this.env.api }/asignacion/usuario/${usuario}`, { headers });    
  }

  ////////////////////Busca asignacion
  deleteAsignacion(id: any){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.delete(`${ this.env.api }/asignacion/${id}`, { headers });    
  }

}
