import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

import Swal from 'sweetalert2';

import { AvanzadasService } from './avanzadas.service';

@Component({
  selector: 'app-avanzadas',
  templateUrl: './avanzadas.component.html',
  styleUrls: ['./avanzadas.component.scss']
})
export class AvanzadasComponent implements OnInit {

  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast: any) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  estado = null;
  registrosEstado = null;

  control = null;
  registrosControl = null;

  estadoList = [
    {value: 0, viewValue: 'Sin usar'},
    {value: 1, viewValue: 'Finalizado'},
    {value: 2, viewValue: 'Pendiente'},
    {value: 3, viewValue: 'No es posible completar'}
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog,
    private avanzadasService: AvanzadasService
  ) { }

  ngOnInit(): void {
  }

  updateAvanzadas(variable: any){
    let body = null;
    if (variable == 'estado') {
      body = {
        variable: 'procod_' + variable,
        valor: this.estado,
        registros: this.registrosEstado,
        identificador: this.data.identificador,
        proyecto: this.data.proyecto
      };
    } else {
      body = {
        variable: variable,
        valor: this.control,
        registros: this.registrosControl,
        identificador: this.data.identificador,
        proyecto: this.data.proyecto
      };
    }
    Swal.fire({
      title: '¿Estas seguro de continuar con el cambio?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, cambiar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.avanzadasService.updateAvanzadas(body)
          .subscribe( (data) => {
            this.Toast.fire({
              icon: 'success',
              title: 'El cambio se realizó correctamente!'
            });
          },
          (error) => {
            Swal.fire(
              'Error',
              'Ocurrio un error al realizar el cambio, intenta más tarde.',
              'error'
            );
          });
      }
    })
  }

}
